import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import universitybanner from "../../assest/misFurnitureDecorators/18.jpg";
import aboutimg from "../../assest/misFurnitureDecorators/25.jpg";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import PresentProjectsData from "../../data/PresentProjectsData";
import RecentCompletedProjectsData from "../../data/RecentCompletedProjectsData";
import '../../styles/misFurnitureDecoratorsHomeStyle.css'
import MisFurnitureDecoratorData from "../../data/MisFurnitureDecoratorData";
import GalleryPhotos from "../../common/Gallery/Gallery";
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import contactusimg from "../../assest/misFurnitureDecorators/27.jpg";
import mislogoimg from "../../assest/misFurnitureDecorators/0.png";
import img019 from "../../assest/misFurnitureDecorators/11.png";



const MISFurnitureDecoratorsHome = () => {
    return (
        <>

            <MAQHeader />
            <Box className='MISFurniture_topdiv'>
                <img src={universitybanner} alt="img" />
                <div className="MISFurniture_head">
                    <h1>MAQ THE WORLD MIS Furniture & Decorators</h1>
                    <p>INTERIOR DESIGNING & FURNISHING PLANNING & CONSULTANCY TRUNKEY PROJECTS</p>
                    <p>Specialist In :- Bank Branch’s, ATM’s, Offices etc.</p>
                </div>
            </Box>


            <Box className='mainhome_welcomeDiv pt-5'>
                <h3 className=" fw-bold py-5">ABOUT US</h3>
                <Box className="mainhome_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={aboutimg} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <p>
                            MAQ THE WORLD MIS FURNITURE & DECORATORS is a composite design company, which specialises in
                            conceptinteriors and architecture& solar Project of high-quality.An integratedgroup,
                            it is a company which thinks young , contemporary and trendy.
                        </p>
                        <p>
                            Our aim : To provide our clients, a comprehensive range of all services that go into the
                            making of home or and office. We offer complete team of specialised consultants in the
                            fields of structures, services, landscaping, acoustics-planning, space planning, furniture,
                            furniture design and execution to name a few. In other words anything that as project
                            require. Our design company has executed a wide variety of projects ranging from bank
                            interiors, office corporates, retail showrooms, individual homes and farmhouses. Our
                            company also has in house manufacturing facility for making specialised, custom-made
                            furniture.
                        </p>
                        <p>
                            IndianBanking industry has undergone a revolution in the past five years and changed
                            the modus operandi of banking transaction. We have thus developed a specialisation in
                            execution of bank interiors. We have designed and executed more then a 100 bank
                            interiors in the past few years.
                        </p>
                        <p>
                            On the other hand, we also specialize in designing and execution home and office
                            interiors and making customized furniture on order. Continued Innovation and setting
                            new trends in design has been our Endeavour.
                        </p>
                    </div>

                </Box>
            </Box>

            <div className="mis_aboutus">
                <div className="mislogodiv">
                    <img src={mislogoimg} alt="img" />
                </div>
                <p>
                    MAQ THE WORLD MIS FURNITURE & DECORATORS recognizes the importance of clients expectations and
                    requirements in a professional way. It sees that the requirements of our clients
                    successfully meet according to the international quality standards. Also it provides
                    quality at cost-effective rates. Some of our business attributes that lure large
                    clientele are:
                </p>
                <ol>
                    <li> Complete satisfaction with no chance for grievance</li>
                    <li> Regular up gradation of interior decorative accessories</li>
                    <li> Expert and talented professionals</li>
                    <li> Flawless work flow</li>
                    <li> Variety of Interior Decoration services under one roof</li>
                    <li> Superior, impressive and cost effective solution</li>
                    <li> Services provided as per the latest market trends</li>
                    <li>Interior Decorative accessories of top quality manufactured by leading brands.Customer focused approach.</li>
                </ol>

                <div className="img019div">
                    <img src={img019} alt="img" />
                </div>

                <p className=" fw-bold">Quality Assurance:-</p>
                <p>
                    Quality is the yardstick for a client to determine the success or failure of a product. Thus,
                    we ensure to provide our clients with the best possible quality at all times and we employ
                    high grade raw material procured from reliable resources. The manufactured products
                    undergo thorough quality assurance tests to ensure the quality of the product that the
                    client can rely upon. These tests are conducted by our highly qualified team of skilled
                    quality controllers. Our prime focus is on delivering the best products to our clients at
                    reasonable prices thatdeliver them satisfaction.
                </p>
                <p>Being in the industry since 1996,we have enormous clientele, to name a few:-</p>
                <p>
                    <b>Corporate & Govt.Sector</b>– IDBIBank, ING Vysya Bank, Kotak Mahindra Bank
                    ICICI Bank, Axis Bank, Sterling & Willson Garhwal
                    Mandal Vikas Nigam(GMVN),Dena Bank, State Bank of
                    India, CMS, Videocon, Edelweiss Group,
                </p>
                <p>
                    Our Presence in all India. Enthusiast by the response of the satisfied client, we have
                    broaden our focus to create innovative, practical & value for money
                </p>

            </div>

            <div className="mis_ProjectDetails">
                <h1 className="pro_head">Project Details</h1>
                <div className="mis_project_div">
                    <h3 className=" fw-bold">Present Projects</h3>
                    <div className="misproject_slide">
                        {PresentProjectsData?.map((item, index) => (
                            <Link>
                                <div key={item.id} className="mis_ProjectDetails_life_Slider">
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                </div>
                            </Link>

                        ))}
                    </div >
                    {/* <Button className="explorebtn">Explore More</Button> */}
                </div >

                <div className="mis_project_div">
                    <h3 className=" fw-bold">Recent Completed Projects</h3>
                    <div className="misproject_slide">
                        {RecentCompletedProjectsData?.map((item, index) => (
                            <Link>
                                <div key={item.id} className="mis_ProjectDetails_life_Slider">
                                    <img src={item.imgSrc} alt={`img-${item.id}`} />
                                    <h4>{item.heading}</h4>
                                </div>
                            </Link>

                        ))}
                    </div >
                    {/* <Button className="explorebtn">Explore More</Button> */}
                </div >
            </div>

            <GalleryPhotos GalleryData={MisFurnitureDecoratorData} title={'Our Work Gallery'} />

            <Box className='row overflow-hidden align-items-center me-0'>
                <div className=" col-lg-6 col-12 p-5">
                    <img style={{ width: '100%', height: '400px', borderRadius: '6px' }} src={contactusimg} alt="img" />
                </div>
                <div className=" col-lg-6 col-12 ContactUs px-5">
                    <div className="smallscrenpadding">
                        <h1 className="ps-0 fw-bold">Contact <span>Us</span></h1>
                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p>
                                <strong>Address : </strong> 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                            </p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Phone : </strong>+91 8851746286, 8851746286</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdEmail className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Email ID : </strong>  abarissoftech@gmail.com , abaris@softech.com</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="ngo_icondiv">
                            <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                        </div>
                        <div className="ngocontact">
                            <p> <strong>Website : </strong>www.abarissoftech.co</p>
                        </div>

                    </div>
                </div>
            </Box>
            <MAQFooter />

        </>
    )
}

export default MISFurnitureDecoratorsHome