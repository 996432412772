import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import volunteersbg from '../../assest/volunteersbg.webp'

const NgoPrivacyPolicy = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={volunteersbg} alt="img" />
                <h1 className="h1donate text-center">Privacy Policy</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4"> <span> Privacy Policy</span> </h1>
                </div>

                <div className="aboutus_div">
                    <h4 className=" mt-5 mb-3">Personal Information</h4>
                    <p className=" ">MAQ THE WORLD NGO TRUST is the authoritative owner of the brand Pedhlagao.com and the website Pedhlagao.com.</p>
                    <p className=" ">This Privacy Policy provides in brief the manner your data is collected and used by MAQ THE WORLD NGO TRUST on the Site. As a guest to the Site/ Customer you are advised to please read the Privacy Policy.</p>
                    <p className=" ">By accessing the services provided by the Site you agree to the collection and use of your data by MAQ THE WORLD NGO TRUST in the manner provided in this Privacy Policy.</p>

                    <h4 className=" mt-5 mb-3">Information collection</h4>
                    <p className=" ">As part of the registration process on the Site, MAQ THE WORLD NGO TRUST may collect the following personally private information about you: Name including first and last name, email address, mobile phone number and contact details, Postal code, Demographic profile(like your age, gender, occupation, education, address etc.) and information about the pages on the site you visit/access, the links you click on the site, the number of times you access the page and any such browsing information.</p>
                    <p className=" ">While you can browse some sections of our site without being a registered member, certain activities (such as placing an order) do require registration.</p>
                    <p className=" ">When you register with Pedhlagao.com. Your password should be kept strictly confidential to prevent unauthorized use.</p>

                    <h4 className=" mt-5 mb-3">Use Of Information</h4>
                    <p className=" ">MAQ THE WORLD NGO TRUST will use your personal information to provide features to you like providing offers to you through the Site and other sources. We will also share this information to its business acquaintances and partners to get in touch with you when necessary to provide the services when you need. We will use this information to protect transaction history as governed by existing law or policy. We may also use contact information internally to direct its efforts for product perfection, to contact you as a inspection respondent, to notify you about latest offers or if you win any contest; and to send you promotional data from its contest sponsors or advertisers. We will also use this information to give various promotional and advertising data to you via display advertisements through the Google Ad network or facebook or other sources. Information about Customers on an aggregate covering Customer transaction data and Customer demographic and location data may be provided to partners of MAQ THE WORLD NGO TRUST for the purpose of creating additional features on the ecommerce website, creating appropriate merchandising or creating new products and services and taking marketing research and trategic analysis of customer nature and transactions.</p>
                    <p className=" ">MAQ THE WORLD NGO TRUST uses your information to provide the Service and to enhance and improve your shopping skill. MAQ THE WORLD NGO TRUST does not sell personal information about individual customers (such as name, address, e-mail address) to third parties, sell or rent lists of customers or use information about individual customers except to provide the MAQ THE WORLD NGO TRUST Service.</p>
                    <p className=" ">MAQ THE WORLD NGO TRUST may use or disclose personal information when we believes it is appropriate to impose this Agreement, to protect the rights, property or safety of GreeYatra or its users or to comply with the law.</p>

                    <h4 className=" mt-5 mb-3">Information sharing</h4>
                    <p className=" ">GreeYatra will not use your any financial information for any reason other than to complete a transaction process with you. We does not rent, sell or share your personal information and will not disclose any of your personally identifiable information to anyone. In cases where it has your authorization to provide products or services you've requested and such information is necessary to provide these products or services the information may be shared with our business associates and partners. MAQ THE WORLD NGO TRUST may, however, share consumer information on an aggregate with its partners where it seems necessary. In addition MAQ THE WORLD NGO TRUST may use this information for promotional offers, to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person, violations of the Site’s terms of use or to defend against legal claims; special circumstances such as compliance with court orders, requests/order from legal authorities or law enforcement agencies requiring such privacy.</p>
                  
                    <h4 className=" mt-5 mb-3">Changing or removal Of Personal Information</h4>
                    <p className=" ">You may at any time review and change your personal information stored by Us by accessing the My Account page at Pedhlagao.com or by contacting Customer Care Services at support@Pedhlagao.com. You may also request that MAQ THE WORLD NGO TRUST permanently remove all personal information stored by MAQ THE WORLD NGO TRUST at any time by delivering notice requesting such removal from your side or by MAQ THE WORLD NGO TRUST. Upon such request, MAQ THE WORLD NGO TRUST will remove all such information and will not there after use personally identifiable information about you in any way. Such request will automatically terminate your MAQ THE WORLD NGO TRUST Service.</p>
                  
                    <h4 className=" mt-5 mb-3">Privacy with Links to Other Sites</h4>
                    <p className=" ">Our site links to other websites that may collect personally identifiable information about you. We are not responsible for the privacy practices or the content of those linked websites.</p>
                    
                    <h4 className=" mt-5 mb-3">Security Measures</h4>
                    <p className=" ">Our site has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we force to strict security guidelines, protecting it against unauthorized access.</p>
                   
                    <h4 className=" mt-5 mb-3">Advertisements on Pedhlagao.com</h4>
                    <p className=" ">We use third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.</p>
                   
                    <h4 className=" mt-5 mb-3">Safety Of Payment Modes on Pedhlagao.com</h4>
                    <p className=" ">Yes, shopping at Pedhlagao.com is 100% safe. All Credit card and Debit card payments on Pedhlagao.com are processed through secure and trusted payment gateways managed by leading Indian banks. Furthermore, the Banks might ask you to enter an online password (also known as 3D Secure password) which acts as an additional layer of security for your card transaction or One Time Password(OTP). This 3D Secure password is not printed anywhere on the card and is known only to you, giving you the added assurance that only you can use your card to make purchases on the Internet. This makes your online transaction extremely safe and secure. You can be assured that Pedhlagao.com offers you the highest standards of security currently available on the internet so as to ensure that your shopping experience is private, safe and secure.</p>
                    
                    <h4 className=" mt-5 mb-3">Storage Of Credit/Debit card information</h4>
                    <p className=" ">Pedhlagao.com does not store any of Credit/Debit card details provided to you. Pedhlagao.com directly takes you to the bank page where you provide all information. The bank in turn stores the first 6 and last 4 digits of your card number in a secure and encrypted manner. The first 6 digits (also known as the Bank Identification Number) are used to identify the bank name and country where your card was issued. The first 6 and last 4 digits are together used for fraud detection and prevention purposes.</p>

                    <h4 className=" mt-5 mb-3">Collection, Use and Distribution of information</h4>
                    <p className=" ">To protect against the loss, misuse and alteration of the information under its control, MAQ THE WORLD NGO TRUST has in place appropriate physical, electronic and managerial procedures. For example, MAQ THE WORLD NGO TRUST servers are accessible only to authorized personnel and your information is shared with employees and authorised personnel on a need to know basis to complete the transaction and to provide the services requested by you. Although MAQ THE WORLD NGO TRUST will endeavour to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using this site, you agree that MAQ THE WORLD NGO TRUST will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>
                   
                </div>

            </div>
            <NGOTrustFooter />

        </>
    )
}

export default NgoPrivacyPolicy