
import img1 from '../assest/wilmar/alife.webp'
import img2 from '../assest/wilmar/Bandha01.jpg'
import img3 from '../assest/wilmar/PoshanImage.webp'
import img4 from '../assest/wilmar/imgefrfd.jpg'
import img5 from '../assest/wilmar/imgthuki.jpg'
import img6 from '../assest/wilmar/imgffff.webp'
import img7 from '../assest/wilmar/imgesacf.jpg'



const WilmarAboutFoodFMCGData = [
    {
        id: 1,
        heading:'Soap',
        imgSrc: img1,
    },
    {
        id: 2,
        heading:'Handwash & Sanitizer',
        imgSrc: img2,
    },
    {
        id: 3,
        heading:'Poha',
        imgSrc: img3,
    }, 
    {
        id: 4,
        heading:'Besan & Sattu ',
        imgSrc: img4,
    }
    , 
    {
        id: 5,
        heading:'Soya Chunks ',
        imgSrc: img5,
    }
    , 
    {
        id: 6,
        heading:'Pulses ',
        imgSrc: img6,
    }
    , 
    {
        id: 7,
        heading:'Rice ',
        imgSrc: img7,
    }
];
export default WilmarAboutFoodFMCGData