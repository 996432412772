import { Link } from "react-router-dom"
import '../../styles/siteMapStyle.css'
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import MAQFooter from '../../common/MAQ/footer/MAQFooter'


const SiteMap = () => {
    return (
        <>
            <MAQHeader />
            <div className=" bg-black">
                <h3 className="fw-bold text-white pt-4 ps-5">MAQ THE WORLD GROUP</h3>
                <div className="sitemapdivbox">
                    <p><Link to={'/'}>MAQ THE WORLD BUILDER & DEVELOPER</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD NGO TRUST</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD FRANCHISE</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD IMPORT & EXPORT</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD CAPITAL FINANCE</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD ARCHITECTURE</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD FOOD STUFF</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD MIS FURNITURE & DECORATORS</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD MARINE LOGISTICS</Link></p>
                    <p><Link to={'/'}>AL MAQ THE WORLD TOUR & TRAVELS</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD SHIPPING</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD INFRASTRUCTURE</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD INFRA MART</Link></p>
                    <p><Link to={'/'}>MAQ INTERNATIONAL PAYMENT GATEWAY</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD HOSPITALS</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD INTERNATIONAL SCHOOL</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD UNIVERSITY</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD GREEN ENERGY</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD AGRI-LOGISTICS</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD WILMAR</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD SOLAR POWER</Link></p>
                    <p><Link to={'/'}>MAQ THE WORLD MALL</Link></p>
    
                </div>
                {/* <h3 className="fw-bold text-white pt-4 ps-5">Marrnt</h3>
                <div className="sitemapdivbox">
                    <p><Link to={'/marx-entertainment'}></Link></p>
                    <p><Link to={'/ourservice'}> </Link></p>
                    <p><Link to={'/ourservice'}></Link></p>
                    <p><Link to={'/ourservice'}> </Link></p>
                </div> */}
            </div>

            <MAQFooter />
        </>
    )
}

export default SiteMap
