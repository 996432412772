
import services1 from '../assest/maqTheWorldShipping/services1.jpg'
import services2 from '../assest/maqTheWorldShipping/services2.jpg'
import services3 from '../assest/maqTheWorldShipping/services3.jpg'
import services4 from '../assest/maqTheWorldShipping/services4.jpg'
import services5 from '../assest/maqTheWorldShipping/services5.jpg'
import services6 from '../assest/maqTheWorldShipping/services6.jpg'




const MaqShippingServicesData = [
    {
        id: 1,
        heading:'SHIP AGENCY',
        textInfo:'World wide Shipping Lines are the range of international shipping services caters for all kinds of vessels, from Tug and brags, bulk cargo carriers, Ro-Ro vessels and tankers to cruise liners and superyachts. Instead of LPG, tankers and chemical vessels',
        imgSrc: services1,
    },

    {
        id: 2,
        heading:' HUSBANDARY SERVICES',
        textInfo:'A vessel may need much more than simple ship agency services when in port. The need often arises for husbandry services, which can sometimes mean the involvement of a different agent to that which is handling the port call. we are avoid that by providing professional husbandry services at ports and terminals',
        imgSrc: services2,
    },
    {
        id: 3,
        heading:'HUB AGENCY',
        textInfo:'World wide Shipping Lines is a pioneer of the global hub agency concept, developing it in response to calls for a one world, one agent approach to shipping. It is simplified, focusing on saving time and money and utilise our agency capabilities to offer top-down control of cost and service levels.',
        imgSrc: services3,
    } ,

     {
        id: 4,
        heading:'PROTECTING AGENCY',
        textInfo:"World wide Shipping protecting agency services help owners and operators supervise the charterers' agency function and monitor port call operations.At ports around the world, we offer vessel owners and operators a scalable protective service to complement our range of ship agency, husbandry, ship spares delivery and other support options.",
        imgSrc: services4,
    },
     {
        id: 5,
        heading:'HULL CLEANING ',
        textInfo:'The purpose of underwater Tank cleaning is to remove biological roughness or fouling. A build-up of marine fouling can lead to increased drag, resulting in a detrimental impact on a vessel’s hydrodynamic performance and hence the relationship between speed, power performance and fuel consumption.',
        imgSrc: services5,
     }
     ,
     {
        id: 6,
        heading:'CHARTERING & BROKING ',
        textInfo:'Chartering & Broking department provides high quality shipping services for our clients like open tonnage and cargo offers for ship-owners, charterers and brokers. We have the expertise in chartering suitable ships with well qualified staff for nominated cargos worldwide and vice versa.',
        imgSrc: services6,
        
     }
   
];
export default MaqShippingServicesData