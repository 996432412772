import Metro1 from '../assest/inframart/Chennai-Metro.png'
import Metro2 from '../assest/inframart/Delhi-Metro.png'
import Metro3 from '../assest/inframart/Kochi-Metro.png'
import Metro4 from '../assest/inframart/NHAI.png'
import Metro5 from '../assest/inframart/Navi-Mumbai-Airport.png'



const InfraMartKeyProjectData = [
    {
        id: 1,
        imgSrc: Metro1,
        name:'Chennai Metro'
    },
    {
        id: 2,
        imgSrc: Metro2,
        name:'Delhi Metro'
    },
    {
        id: 3,
        imgSrc: Metro3,
        name:'Kochi Metro'

    }, {
        id: 4,
        imgSrc: Metro4,
        name:'NHAI Projects'
    }, {
        id: 5,
        imgSrc: Metro5,
        name:'Navi Mumbai Airport'

    }
];
export default InfraMartKeyProjectData

