import { Link } from "react-router-dom"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Box } from "@mui/material"
import Banner4 from '../../assest/BuildersDeveloper/Banner4.jpg'
import '../../styles/maqBuildersDevelopersHomeStyle.css'
import UAECountryCityData from "../../data/UAECountryCityData"
import MaharashtraCityData from "../../data/MaharashtraCityData"
import RajisthanCityData from "../../data/RajisthanCityData"
import UttarakhandCityData from "../../data/UttarakhandCityData"


const MAQBuildersDevelopersHome = () => {
    return (
        <>
            <MAQHeader />
            <Box className='topdiv_Builders'>
                <img src={Banner4} alt="img" />
                <div className="Buildershead">
                    <h1>MAQ The World Builders & Developers</h1>
                </div>
            </Box>
            
            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">United Arab Emirates (UAE)</h1>
                <div className="city_countrydiv_slide">
                    {UAECountryCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">MAHARASHTRA</h1>
                <div className="city_countrydiv_slide">
                    {MaharashtraCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >
           
            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">RAJASTHAN</h1>
                <div className="city_countrydiv_slide">
                    {RajisthanCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >
            
            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">UTTARAKHAND</h1>
                <div className="city_countrydiv_slide">
                    {UttarakhandCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >
            <MAQFooter />

        </>
    )
}

export default MAQBuildersDevelopersHome