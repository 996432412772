
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import medium from '../../assest/medium.webp'
import Activitiesimg from '../../assest/sustainable-goals.webp'
import '../../styles/CSRActivitiesStyle.css'
import img56517 from '../../assest/56517.webp'
import img28559 from '../../assest/28559.webp'
import img54132 from '../../assest/54132.webp'
import img19197331 from '../../assest/19197331.webp'
import img7949 from '../../assest/7949.webp'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaRegThumbsUp } from "react-icons/fa6";
import { IoEarthSharp } from "react-icons/io5";
import certificate from '../../assest/Elegant-certificate.webp'


const CSRActivities = () => {
    const data = [
        {
            id: 1,
            name: "Mohd Bilal",
            review:
                " We are delighted to be patterned with MAQ THE WORLD NGO TRUST to do tree plantation drives",
            image: img7949
        },
        {
            id: 2,
            name: "Mohd Bilal",
            review:
                "Sold my house by MAQ THE WORLD NGO TRUST in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through J Tech.",
            image: img7949
        },
        {
            id: 3,
            name: "Mohd Bilal",
            review:
                "Purchased a commercial property in Noida through J tech Group. Deal was done in proper manner and transparent way. great experience.",
            image: img7949
        },
        {
            id: 4,
            name: "Mohd Bilal",
            review:
                "Sold my house by MAQ THE WORLD NGO TRUST ltd in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through J Tech.",
            image: img7949
        },
        {
            id: 5,
            name: "Mohd Bilal",
            review:
                "Sold my house by MAQ THE WORLD NGO TRUST ltd in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through J Tech.",
            image: img7949
        },
        {
            id: 6,
            name: "Mohd Bilal",
            review:
                "Purchased a commercial property in Noida through J tech Group. Deal was done in proper manner and transparent way. great experience.",
            image: img7949
        }
    ];
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={medium} alt="img" />
                <h1 className="h1donate text-center">Corporates CSR activities</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Corporates - CSR <span>Activities</span> </h1>
                </div>

                <div className="activitiesDiv">
                    <img src={Activitiesimg} alt="img" />
                    <h4>Helping Corporates To Create A Green And Sustainable World.</h4>
                    <p>CSR activities are the responsibility as well as an opportunity for a corporate house or a company to give back to society, the environment, and humanity for a sustainable earth. We fully understand this need and assist corporates to fulfil their CSR requirements for a better world. We warmly invite corporate houses to join hands with MAQ THE WORLD NGO TRUST. Together we can bring positive & sustainable change in our environment and people's lives.</p>
                    <p>MAQ THE WORLD NGO TRUST is loved and trusted by 100s of corporate houses for our impactful work in the field of environment.</p>
                    <p>Tell us your CSR requirements and we will suggest and help you to take part in an environmentally friendly CSR activity that will fit with your company policy.</p>
                </div>

            </div>

            <div className=" row">
                <div className="col-lg-12 col-12 more_activities">
                    <div>
                        <img src={img56517} alt="img" />

                    </div>
                    <div>
                        <p className="bold_p">Employee Engagement</p>
                        <p>An opportunity to serve society. We do different types of employee engagement activities such as tree plantation, forest walks and maintenance, workshops, donation drives, clean up drives, awareness programs and much more.</p>
                    </div>
                </div>
                <div className="col-lg-12 col-12 more_activities">
                    <div>
                        <img src={img28559} alt="img" />

                    </div>
                    <div>
                        <p className="bold_p">Awareness And Activities</p>
                        <p>We organize workshops on, best out of waste, birdhouse and feeder making, go GREEN ganesha, seed ball making and bombing, water conservation activities, waste management techniques, green lifestyle practices, spot fixing, etc.</p>
                    </div>
                </div>
                <div className="col-lg-12 col-12 more_activities">
                    <div>
                        <img src={img54132} alt="img" />

                    </div>
                    <div>
                        <p className="bold_p">Reduce Environmental Footprint</p>
                        <p>We provide an opportunity to reduce and offset your carbon footprint along with saving money through water audit, waste audit, energy audit and carbon audit. Adopt innovative services to sustain daily operations while reducing your environmental impacts across value chain.</p>
                    </div>
                </div>
                <div className="col-lg-12 col-12 more_activities">
                    <div>
                        <img src={img19197331} alt="img" />

                    </div>
                    <div>
                        <p className="bold_p">Add Value To Product/Service</p>
                        <p>Commit to plant tree for every product sold or service provided. You can also dedicate certain % from your profits to plant trees. Offering a tree on every sale is an affordable and effective way to increase your sales and increase product value while taking care of your planet</p>
                    </div>
                </div>
                <div className="col-lg-12 col-12 more_activities">
                    <div>
                        <img src={img7949} alt="img" />

                    </div>
                    <div>
                        <p className="bold_p">Celebrate With Trees</p>
                        <p>Plant saplings and gift tree certificate on birthdays, anniversaries, company achievements, on the occasion of joining and retirement. Gift guests tree certificates instead of bouquets. Individuals can also gift tree to near and dear ones</p>
                    </div>
                </div>

            </div>

            <section id="ngo_testimonial">
                <h2 className="testi_h2">Testimonials</h2>
                <Swiper
                    centeredSlides={false}
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="container ngo_testimonials__container"
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                        },
                        // when window width is >= 1024px
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {data.map(({ id, name, review, image }) => {
                        return (
                            <SwiperSlide key={id} className="ngo_testimonial">
                                <div className="ngo_testimonial_div">
                                    <div className="img_div_client__avatar">
                                        <iframe
                                            width="560"
                                            height="315"
                                            src="https://www.youtube.com/embed/TBQUOgnupyA?si=GY72JHEmLziahfGy"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                                        >
                                        </iframe>
                                    </div>
                                    <div className="">
                                        <p className="ngo_client__review">
                                            <FaRegThumbsUp className=" mx-1 mt-1" /> {review}
                                        </p>
                                        <h5 className="ngo_client__name"> {name}</h5>
                                        <p className="ngo_client__message"> — <IoEarthSharp className=" ms-2 me-1" /> Managing Director Morningstar</p>
                                    </div>
                                </div>

                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </section>

            <div className="certificatediv row">
                <div className=" col-lg-4 col-12">
                    <img src={certificate} alt="img" />
                </div>
                <div className=" col-lg-4 col-12">
                    <img src={certificate} alt="img" />
                </div>
                <div className=" col-lg-4 col-12">
                    <img src={certificate} alt="img" />
                </div>

            </div>
            <NGOTrustFooter />
        </>
    )
}

export default CSRActivities