import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import aerialviewcargo from "../../assest/maqTheWorldShipping/aerial-view-cargo.webp";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import Slide1 from "../../assest/maqTheWorldShipping/s1.jpg";
import Slide2 from "../../assest/maqTheWorldShipping/s2.jpg";
import Slide3 from "../../assest/maqTheWorldShipping/s4.jpg";
import { Link } from "react-router-dom";
import '../../styles/maqShippingAllWorldHomeStyle.css'
import MaqShippingServicesData from "../../data/MaqShippingServicesData";
import h3comab from "../../assest/maqTheWorldShipping/h3comab.jpg";
import rightarrow from "../../assest/maqTheWorldShipping/right-arrow.png";
import routeimg from "../../assest/maqTheWorldShipping/route.png";
import agreementimg from "../../assest/maqTheWorldShipping/agreement.png";
import buildingimg from "../../assest/maqTheWorldShipping/building.png";
import worldwide1 from "../../assest/maqTheWorldShipping/worldwide-shipping.png";
import worldwide2 from "../../assest/maqTheWorldShipping/worldwide-moving.png";
import worldwide3 from "../../assest/maqTheWorldShipping/freight-forwarding.png";
import herobanner from "../../assest/maqTheWorldShipping/hero-banner-img1.webp";
import Aircargo from "../../assest/maqTheWorldShipping/Air-cargo-850x656.png";
import retailerimg from "../../assest/maqTheWorldShipping/retailer-volume.png";
import enterpriseimg from "../../assest/maqTheWorldShipping/enterprisescontainer.webp";


const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  strong {
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 40px;
    }
    .main p{
      font-size: 24px;
       margin: 20px 20% 0 20%;

    }
    .main .btnn {
      font-size: 20px;
      letter-spacing: 0.5px;
      border-radius: 6px;
      background-color: #fff;
      text-transform: capitalize;
      padding: 8px 20px;
      color: black;
      margin-top: 20px;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
      .main .btnn {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 16rem;
    width: 98%;
    margin-top: -175px;
    div {
      .main {
        font-size: 15px;
      }
      .main p{
      font-size: 14px;
      margin:20px 10px;
    }
      .main .btnn {
        font-size: 14px;
         margin-top: 0px;

      }
    }
  }
`;


const data = [
    {
        id: 1,
        heading: 'strong transport Fast & Safe Transportation',
        paraText: 'The MAQ THE WORLD SHIPPING Lines in business to help your business thrive, even in challenging times. Our promise to provide the best in shipping, logistics and marine services is one we take seriously.',
        image: Slide1
    },
    {
        id: 2,
        heading: 'We Care About Your Time',
        paraText: "MAQ THE WORLD SHIPPING Lines in business to help your business thrive, even in challenging times. Our promise to provide the best in shipping, logistics and marine services is one we take seriously. ",
        image: Slide2

    },
    {
        id: 3,
        heading: 'Promptness & Dedicated Warehousing',
        paraText: "MAQ THE WORLD SHIPPING Lines in business to help your business thrive, even in challenging times. Our promise to provide the best in shipping, logistics and marine services is one we take seriously.",
        image: Slide3
    }

];


const MAQShippingAllWorldHome = () => {
    return (
        <>
            <MAQHeader />
            <Box className='Shipping_All_World_topdiv'>
                <img src={aerialviewcargo} alt="img" />
                <div className="Shipping_AllWorld_head">
                    <h1>MAQ THE WORLD Shipping</h1>
                    <p>MAQ THE WORLD SHIPPING Lines in business to help your business thrive, even in challenging times. Our promise to provide the best in shipping, logistics and marine services is one we take seriously. And we have the reach, resources, expertise and experience to deliver on that promise.</p>
                </div>
            </Box>

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {data.map(({ id, image, paraText, heading }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="Shipping_AllWorld_customimg">
                                    <img src={image} alt="img" />
                                    <MyTitleMessage>
                                        <div className="titleMessage">
                                            <div className="heading">
                                                <div className="main text-center mb-3">
                                                    <span>
                                                        <strong style={{ textTransform: 'uppercase' }}>{heading}</strong>
                                                    </span>
                                                    <p>{paraText}</p>
                                                    <Button className="btnn">Read More</Button>
                                                    <Button className="btnn ms-2">Contact Us</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </MyTitleMessage>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <Box className='shippingservices_weworkIn'>
                <h1 className=" fw-bold py-5 text-center">Our Services</h1>
                <div className="row gap-4 shippingservices_alldetail ">
                    {MaqShippingServicesData?.map((item, index) => (
                        <Link className="col-lg-3 col-12 linktag_shippingservices" to={'/'}>
                            <div className="shippingservices_areadiv">
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <h6>{item.heading}</h6>
                                    <p>{item.textInfo}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <h6>About Company</h6>
                        <p>MAQ THE WORLD SHIPPING Lines in business to help your business thrive, even in challenging times. Our promise to provide the best in shipping, logistics and marine services is one we take seriously. And we have the reach, resources, expertise and experience to deliver on that promise. </p>
                        <p>Ship Supply has been a premier provider of goods, services and seamless port logistics to the maritime industry. We have developed a reputation for reliable, world-class service through our values of Excellence, Responsibility, Leadership, Collaboration, Integrity and Passion. This focus has recently expanded to include development of new divisions, facilities and the acquisition of related organizations, like Alliance Supply Management and RS Stern, with the grand vision of becoming the most comprehensive provider of marine services in the world. </p>
                        <Button className="morebtn">Read More</Button>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={h3comab} alt="img" />
                    </div>
                </Box>
            </Box>

            <div className="row bg-dark-subtle justify-content-center me-0">
                <div className="subtle_Shipmentdiv col-lg-3 col-12">
                    <div className="Shipmentdiv">
                        <div>
                            <img src={routeimg} alt="img" />
                            <h3>Track A Shipment</h3>
                        </div>
                        <img className="rightarrowimg" src={rightarrow} alt="img" />
                    </div>
                    <div className="divtag">View real-time delivery status from anywhere.</div>
                </div>
                <div className="subtle_Shipmentdiv col-lg-3 col-12">
                    <div className="Shipmentdiv">
                        <div>
                            <img src={agreementimg} alt="img" />
                            <h3>Book Shipment</h3>
                        </div>
                        <img className="rightarrowimg" src={rightarrow} alt="img" />
                    </div>
                    <div className="divtag">Save up to 70% on domestic and international shipments.</div>
                </div>

                <div className="subtle_Shipmentdiv col-lg-3 col-12">
                    <div className="Shipmentdiv">
                        <div>
                            <img src={buildingimg} alt="img" />
                            <h3>Smart Shipments</h3>
                        </div>
                        <img className="rightarrowimg" src={rightarrow} alt="img" />
                    </div>
                    <div className="divtag">Sending packages frequently? Connect with us for exclusive bulk discount rates.</div>
                </div>
            </div>

            <Box className='CustomisedSolutions_div'>
                <div className="logistic_topdiv">
                    <h1> What We Offer</h1>
                    <p>Expand your boundaries with MAQ THE WORLD SHIPPINGwide seamless solutions for international and domestic freight.</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-12 fddddddd">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={worldwide1} alt="img" />
                            </div>
                            <div>
                                <h5>Worldwide Shipping</h5>
                                <p>Compare and discover discounted rates from leading couriers, such as FedEx, DHL, UPS and USPS. Simplify your shipping with our tailored solutions and flexible options.</p>
                                <Button className="readbtnbox">Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 fddddddd">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={worldwide2} alt="img" />
                            </div>
                            <div>
                                <h5>Worldwide Moving</h5>
                                <p>We’re a licensed freight forwarder that can provide a complete move for you, your family, your vehicles, and more to over 200+ destinations worldwide.</p>
                                <Button className="readbtnbox">Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-3 col-12 fddddddd">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={worldwide3} alt="img" />
                            </div>
                            <div>
                                <h5>Freight Forwarding</h5>
                                <p>Explore freight shipping options for containers, pallets, and any kind of cargo. Fulfill your delivery regardless of size or shape via air, ocean, rail, or road!</p>
                                <Button className="readbtnbox">Read More</Button>
                            </div>
                        </div>
                    </div>

                </div>

            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <p>For all Movers </p>
                        <h6>Relocation & Auto Transport</h6>
                        <p>From moving your favorite piano to delivering your boat, we ship them all. Work with the best-in-class relocation experts and auto transporters. </p>
                        <Button className="morebtn">RELOCATION SERVICES</Button>
                        <Button className="morebtn ms-4">AUTO TRANSPORT</Button>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={herobanner} alt="img" />
                    </div>
                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={Aircargo} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p>For all SHIPPERS </p>
                        <h6>Document and Parcel Shipping</h6>
                        <p>Our prices are unmatched! Save up to 70% off on your next shipment with any leading carrier, such as FedEx, DHL, UPS and USPS. </p>
                        <Button className="morebtn">PARCEL SHIPPING</Button>
                        <Button className="morebtn ms-4">DOCUMENT SHIPPING</Button>
                    </div>

                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <p>BUSINESS ONLY </p>
                        <h6>Volume and Business Shipping</h6>
                        <p>Do you ship packages regularly? Connect with us to get exclusive discounts and create business accounts that handle any amount of volume for your business. </p>
                        <Button className="morebtn">VOLUME SHIPPING</Button>
                        <Button className="morebtn ms-4">RETAILER SHIPPING</Button>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={retailerimg} alt="img" />
                    </div>
            
                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={enterpriseimg} alt="img" />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <p>FOR ALL FREIGHT </p>
                        <h6>Enterprise Logistics Solution</h6>
                        <p>Propel forward in the world of logistics with end-to-end visibility and a range of services that handle import and export traffic with FMC, NVOCC, and DOT licenses. </p>
                        <Button className="morebtn">ENTERPRISE LOGISTICS SOLUTIONS</Button>
                    
                    </div>

                </Box>
            </Box>

            <MAQFooter />

        </>
    )
}

export default MAQShippingAllWorldHome