const FaqData = [
    {
        id: 1,
        title: 'What does MAQ THE WORLD GROUP do?',
        details: "MAQ THE WORLD GROUP is India's leading construction material solutions company that is transforming the ecosystem by leveraging technology and scaling innovation. Being the largest multi-product building materials brand, we are the only construction solutions company with an ISO-certified world-class R&D centre, and offer a wide range of building materials as well as lifestyle products that enhance interior spaces.",

    },
    {
        id: 2,
        title: "Who is the owner/founder of MAQ THE WORLD GROUP?",
        details: "MAQ THE WORLD GROUP, an end-to-end construction solutions start-up was started by co-founders, Muhammad and Bilal in the year 2016.",
    },
    {
        id: 3,
        title: "Where are MAQ THE WORLD GROUP's headquarters located?",
        details: "The headquarters of MAQ THE WORLD GROUP, a construction solutions company is based out of Thane, Maharashtra.",
    },
    {
        id: 4,
        title: "What products does MAQ THE WORLD GROUP provide?",
        details: "From Concrete to Modular Furniture, we create value and drive growth through innovation and smart use of technology. Catering to clients with unique requirements, we offer Ready-Mix-Concrete (RMC), Aggregates, Construction Chemicals, Steel, AAC (Autoclaved Aerated Concrete) Blocks, Equipment Solutions, Pipes and Fittings, MDF, Plywood, and Laminates. Our private label categories are electrical products, tiles, sanitaryware, bath fittings, modular kitchens and wardrobes, and designer hardware. Additionally, our retail touchpoints our retail outlets span across India and provide best of brands, expert architects, and an extensive array of products all conveniently under one roof. We are now strengthening our manufacturing prowess with in-house manufacturing facilities across India to emerge as a dominant player in each category.",
    },
    {
        id: 5,
        title: "How many shops/stores do MAQ THE WORLD GROUP have?",
        details: "As of now, MAQ THE WORLD GROUP has 30+ stores running in India with many others in the making.",
    },
    {
        id: 6,
        title: "Which companies have MAQ THE WORLD GROUP acquired till now?",
        details: "In 2021, we acquired a Hyderabad-based construction equipment rental player Equiphunt followed by RDC Concrete. We also have invested stakes in Shalimar Paints Ltd. In pursuit of growth that is both, organic and inorganic, we are aiming to emerge as a one-stop destination for construction materials.",
    },
    {
        id: 7,
        title: "How many number of employees are there in MAQ THE WORLD GROUP?",
        details: "As of now, MAQ THE WORLD GROUP has over 3000+ employees throughout India in all its offices.",
    },
    {
        id: 8,
        title: "How many offices do MAQ THE WORLD GROUP have?",
        details: "With MAQ THE WORLD GROUP's swift expansion into various segments of construction materials, the company's office count in India also grew to 22 locations.",
    },
    {
        id: 9,
        title: "How can I contact MAQ THE WORLD GROUP's customer care number?",
        details: "Anyone can contact MAQ THE WORLD GROUP on the customer care number +91-888888888.",
    },
    {
        id: 10,
        title: "What kind of business model does MAQ THE WORLD GROUP follow?",
        details: "MAQ THE WORLD GROUP serves a wide range of sectors including B2B, retail, and consumer categories to establish India's premier construction material conglomerate. With in-house manufacturing and excellence at the core of our operations, we offer a comprehensive range of construction materials products.",
    }
];

export default FaqData