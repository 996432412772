
import people1 from '../assest/misFurnitureDecorators/4.jpg'
import people2 from '../assest/misFurnitureDecorators/2.jpg'





const PresentProjectsData = [
    {
        id: 1,
        heading:"ICICI Bank – IIM road Lucknow",
        imgSrc: people1,
    },
    {
        id: 2,
        heading:"ICICI Bank – Sector 66 Noida",
        imgSrc: people1,
    },
    {
        id: 3,
        heading:"IDBI Bank:-Sitarganj Uttarakhand",
        imgSrc: people2,
    } ,
     {
        id: 4,
        heading:"IDBI Bank:-JKL University Rajasthan",
        imgSrc: people2,
    }
];
export default PresentProjectsData