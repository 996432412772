import { Box, Button} from "@mui/material"
import '../../styles/maqImportExportHomeStyle.css'
import { Link } from "react-router-dom"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import bannerbg from '../../assest/importExport/topbg.jpg'
import LightingData from "../../data/LightingData"
import FurnitureData from "../../data/FurnitureData"
import GiftsCraftsData from "../../data/GiftsCraftsData"
import HomeAutomationData from "../../data/HomeAutomationData"


const MAQImportExportHome = () => {
 
  return (
    <>
      <MAQHeader />

      <Box className='Import_Export_topdiv'>
        <img src={bannerbg} alt="img" />
        <div className="Import_Export_head">
          <h1>MAQ THE WORLD IMPORT & EXPORT</h1>
          <p>We do this by giving suppliers the tools necessary to reach a global audience for their products, and by helping buyers find products and suppliers quickly and efficiently..</p>
        </div>
      </Box>

      <div className="Import_exportproject_div">
        <h3 className=" fw-bold">Lights & Lighting</h3>
        <div className="importexport_slide">
          {LightingData?.map((item, index) => (
            <Link>
              <div key={item.id} className="importexport_life_Slider">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
                <Button className="explorebtn">Read More</Button>
              </div>
            </Link>

          ))}
        </div >
      </div >

      <div className="GiftsCrafts">
        <h1 className=" fw-bold py-4">Gifts & Crafts</h1>
        <div className="GiftsCrafts_slide">
          {GiftsCraftsData?.map((item, index) => (
            <Link>
              <div key={item.id} className="GiftsCrafts_life_Slider">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
              </div>
            </Link>

          ))}
        </div >
        {/* <Button className="explorebtn">Explore More</Button> */}
      </div >

      <div className="Import_exportproject_div">
        <h3 className=" fw-bold">Furniture</h3>
        <div className="importexport_slide">
          {FurnitureData?.map((item, index) => (
            <Link>
              <div key={item.id} className="importexport_life_Slider">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
                <Button className="explorebtn">Read More</Button>
              </div>
            </Link>

          ))}
        </div >
      </div >


      <div className="GiftsCrafts">
        <h1 className=" fw-bold py-4">Home Automation</h1>
        <div className="GiftsCrafts_slide">
          {HomeAutomationData?.map((item, index) => (
            <Link>
              <div key={item.id} className="GiftsCrafts_life_Slider">
                <img src={item.imgSrc} alt={`img-${item.id}`} />
                <h4>{item.heading}</h4>
              </div>
            </Link>

          ))}
        </div >
        {/* <Button className="explorebtn">Explore More</Button> */}
      </div >
      

      <MAQFooter />

    </>
  )
}

export default MAQImportExportHome
