
import marine1 from '../assest/marine logistic/100.jpg'
import marine2 from '../assest/marine logistic/102.webp'
import marine3 from '../assest/marine logistic/104.webp'
import marine4 from '../assest/marine logistic/85.jpg'
import marine5 from '../assest/marine logistic/90.jpg'
import marine6 from '../assest/marine logistic/93.jpg'
import marine7 from '../assest/marine logistic/97.jpg'
import marine8 from '../assest/marine logistic/98.jpg'
import marine9 from '../assest/marine logistic/99.jpg'



const MarineLogisticTopData = [
    {
        id: 1,
        imgSrc: marine1,
    },
    {
        id: 2,
        imgSrc: marine2,
    },
    {
        id: 3,
        imgSrc: marine3,
    }, {
        id: 4,
        imgSrc: marine4,
    }, {
        id: 5,
        imgSrc: marine5,
    }, {
        id: 6,
        imgSrc: marine6,
    }, {
        id: 7,
        imgSrc: marine7,
    }
    , {
        id: 8,
        imgSrc: marine8,
    }
    , {
        id: 9,
        imgSrc: marine9,
    }
];
export default MarineLogisticTopData