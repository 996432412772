

import banne1 from '../assest/inframart/banner-1.jpg'
import banne2 from '../assest/inframart/banner-2.jpg'
import banne3 from '../assest/inframart/banner-3.jpg'
import banne4 from '../assest/inframart/banner-4.jpg'
import banne5 from '../assest/inframart/banner-5.jpg'
import banne6 from '../assest/inframart/banner-6.jpg'




const InfraMartTopData = [
    {
        id: 1,
        imgSrc: banne6,
    },
    {
        id: 2,
        imgSrc: banne2,
    },
    {
        id: 3,
        imgSrc: banne3,
    }, {
        id: 4,
        imgSrc: banne4,
    }, {
        id: 5,
        imgSrc: banne5,
    }, {
        id: 6,
        imgSrc: banne1,
    }
];
export default InfraMartTopData