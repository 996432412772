import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import Home from "./page/MAQGroup/Home";
import RealEstateHome from "./page/RealEstate/RealEstateHome";
import HomeArea from "./page/RealEstate/HomeArea";
import NGOTrustHome from "./page/NGOTrust/NGOTrustHome";
import DonateNow from "./page/NGOTrust/DonateNow";
import ContactUs from "./page/NGOTrust/ContactUs";
import NgoAboutUs from "./page/NGOTrust/NgoAboutUs";
import OurWorkGallery from "./page/NGOTrust/OurWorkGallery";
import NgoBlog from "./page/NGOTrust/NgoBlog";
import CarbonFootprint from "./page/NGOTrust/CarbonFootprint";
import NgoGreenNews from "./page/NGOTrust/NgoGreenNews";
import CSRActivities from "./page/NGOTrust/CSRActivities";
import OurPartners from "./page/NGOTrust/OurPartners";
import PedhLagao from "./page/NGOTrust/PedhLagao";
import MiyawakiForest from "./page/NGOTrust/MiyawakiForest";
import SustainableSolutions from "./page/NGOTrust/SustainableSolutions";
import SustainableVillageAndFarmers from "./page/NGOTrust/SustainableVillageAndFarmers";
import EcosystemRejuvenation from "./page/NGOTrust/EcosystemRejuvenation";
import GreenCelebrations from "./page/NGOTrust/GreenCelebrations";
import GreenSchools from "./page/NGOTrust/GreenSchools";
import Miyawaki from "./page/NGOTrust/Miyawaki";
import WaterBodyRestoration from "./page/NGOTrust/WaterBodyRestoration";
import NatureIntractionCentre from "./page/NGOTrust/NatureIntractionCentre";
import CampusGreenProject from "./page/NGOTrust/CampusGreenProject";
import Volunteer from "./page/NGOTrust/Volunteer";
import NgoFaq from "./page/NGOTrust/NgoFaq";
import NgoPrivacyPolicy from "./page/NGOTrust/NgoPrivacyPolicy";
import NgoTermsConditions from "./page/NGOTrust/NgoTermsConditions";
import NgoRefundPolicy from "./page/NGOTrust/NgoRefundPolicy";
import RestaurantHome from "./page/Restaurant/RestaurantHome";
import BusinessSuppliers from "./page/Restaurant/BusinessSuppliers";
import Operations from "./page/Restaurant/Operations";
import Growth from "./page/Restaurant/Growth";
import Startup from "./page/Restaurant/Startup";
import People from "./page/Restaurant/People";
import DetailPage from "./components/DetailPage";
import FoodService from "./page/Restaurant/FoodService";
import ServicePeople from "./page/Restaurant/ServicePeople";
import ServiceCPG from "./page/Restaurant/ServiceCPG";
import ServiceD2CNewCommerce from "./page/Restaurant/ServiceD2CNewCommerce";
import ServiceFashionAndBeauty from "./page/Restaurant/ServiceFashionAndBeauty";
import ServiceTechnologyECommerce from "./page/Restaurant/ServiceTechnologyECommerce";
import ServiceRetailBusiness from "./page/Restaurant/ServiceRetailBusiness";
import RestaurantAboutUs from "./page/Restaurant/RestaurantAboutUs";
import RestaurantContactUs from "./page/Restaurant/RestaurantContactUs";
import RestaurantDisclaimer from "./page/Restaurant/RestaurantDisclaimer";
import RestaurantTerms from "./page/Restaurant/RestaurantTerms";
import RestaurantFeedback from "./page/Restaurant/RestaurantFeedback";
import RestaurantSubscriptionPlan from "./page/Restaurant/RestaurantSubscriptionPlan";
import OurServices from "./page/MAQGroup/OurServices";
import MAQContactUs from "./page/MAQGroup/MAQContactUs";
import MAQCapitalFinanceHome from "./page/MAQCapitalFinance/MAQCapitalFinanceHome";
import OurClients from "./page/MAQGroup/OurClients";
import Testimonials from "./page/MAQGroup/Testimonials";
import Jobs from "./page/MAQGroup/jobs/Jobs";
import SiteMap from "./page/MAQGroup/SiteMap";
import VideoGallery from "./page/MAQGroup/VideoGallery";
// import MAQInternationalSchoolsHome from "./page/MAQInternationalSchools/MAQInternationalSchoolsHome";
import MAQMarineLogisticsHome from "./page/MAQMarineLogisticsHome/MAQMarineLogisticsHome";
import MAQInfraMartHome from "./page/MAQInfraMart/MAQInfraMartHome";
import MAQFaq from "./page/MAQGroup/MAQFaq";
import Blog from "./page/MAQGroup/Blog/Blog";
import OurTeam from "./page/MAQGroup/OurTeam";
// import MAQHospitalHome from "./page/MAQHospitals/MAQHospitalHome";
import MAQWilmarHome from "./page/MAQWilmar/MAQWilmarHome";
// import MAQDefenceHome from "./page/MAQDefence/MAQDefenceHome";
import MAQGreenEnergyHome from "./page/MAQGreenEnergy/MAQGreenEnergyHome";
import MAQAgriLogisticHome from "./page/MAQAgriLogistic/MAQAgriLogisticHome";
import AlMAQTourAndTravelsHome from "./page/AlMAQTourAndTravels/AlMAQTourAndTravelsHome";
import MAQHomePage from "./page/MAQGroup/MAQHomePage";
import VentureDetail from "./page/MAQGroup/VentureDetail";
// import MAQUniversityHome from "./page/MAQUniversity/MAQUniversityHome";
import MISFurnitureDecoratorsHome from "./page/MISFurnitureDecorators/MISFurnitureDecoratorsHome";
// import MAQInteriorsDecoratorsHome from "./page/MAQInteriorsDecorators/MAQInteriorsDecoratorsHome";
import MAQArchitectureHome from "./page/MAQArchitecture/MAQArchitectureHome";
import MAQSolarPowerHome from "./page/MAQSolarPower/MAQSolarPowerHome";
import MAQShippingAllWorldHome from "./page/MAQShippingAllWorld/MAQShippingAllWorldHome";
import MAQImportExportHome from "./page/MAQImportExport/MAQImportExportHome";
import OurChairman from "./page/MAQGroup/OurChairman";
import OurDirectors from "./page/MAQGroup/OurDirectors";
import MAQInfrastructureHome from "./page/MAQInfrastructure/MAQInfrastructureHome";
import MAQBuildersDevelopersHome from "./page/MAQBuildersDevelopers/MAQBuildersDevelopersHome";

function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>
        <Routes>


          {/* MAQ Group */}
          <Route path='/' element={<MAQHomePage />} />
          <Route path='/home' element={<Home />} />
          <Route path='/our-service' element={<OurServices />} />
          <Route path='/contactus' element={<MAQContactUs />} />
          <Route path='/our-client' element={<OurClients />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/site-map' element={<SiteMap />} />
          <Route path='/video-gallery' element={<VideoGallery />} />
          <Route path='/faq' element={<MAQFaq />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/our_team' element={<OurTeam />} />
          <Route path='/venture_detail' element={<VentureDetail />} />
          <Route path='/Our_Chairman' element={<OurChairman />} />
          <Route path='/Our_Directors' element={<OurDirectors />} />


          {/* 
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/term-condition' element={<TermAndCondition />} />
          <Route path='/edit-profile' element={<EditProfile />} />
          <Route path='/advertise-here' element={<AdvertiseHere />} />
          <Route path='/buy-our-franchise' element={<BuyOurFranchise />} />
          */}


          {/* MAQ The World Builders & Developers  */}

          <Route path='/maq_builder_and_developer' element={<RealEstateHome />} />
          <Route path='/home_area' element={<HomeArea />} />
          <Route path='/maq_the_world_builders_and_developers' element={<MAQBuildersDevelopersHome />} />


          {/* NGO Trust  */}

          <Route path='/NGO_trust' element={<NGOTrustHome />} />
          <Route path='/NGO_trust/donate_now' element={<DonateNow />} />
          <Route path='/NGO_trust/contact_us' element={<ContactUs />} />
          <Route path='/NGO_trust/about_us' element={<NgoAboutUs />} />
          <Route path='/NGO_trust/our_work_gallery' element={<OurWorkGallery />} />
          <Route path='/NGO_trust/blog' element={<NgoBlog />} />
          <Route path='/NGO_trust/blog/carbon_footprint' element={<CarbonFootprint />} />
          <Route path='/NGO_trust/green_news' element={<NgoGreenNews />} />
          <Route path='/NGO_trust/CSR_activities' element={<CSRActivities />} />
          <Route path='/NGO_trust/our_partners' element={<OurPartners />} />
          <Route path='/NGO_trust/pedh_lagao' element={<PedhLagao />} />
          <Route path='/NGO_trust/miyawaki_forest' element={<MiyawakiForest />} />
          <Route path='/NGO_trust/sustainable_solutions' element={<SustainableSolutions />} />
          <Route path='/NGO_trust/sustainable_village_and_farmers' element={<SustainableVillageAndFarmers />} />
          <Route path='/NGO_trust/ecosystem_rejuvenation' element={<EcosystemRejuvenation />} />
          <Route path='/NGO_trust/green_celebrations' element={<GreenCelebrations />} />
          <Route path='/NGO_trust/green_schools' element={<GreenSchools />} />
          <Route path='/NGO_trust/Miyawaki' element={<Miyawaki />} />
          <Route path='/NGO_trust/water_body_restoration' element={<WaterBodyRestoration />} />
          <Route path='/NGO_trust/nature_intraction_centre' element={<NatureIntractionCentre />} />
          <Route path='/NGO_trust/campus_green_project' element={<CampusGreenProject />} />
          <Route path='/NGO_trust/volunteer' element={<Volunteer />} />
          <Route path='/NGO_trust/faq' element={<NgoFaq />} />
          <Route path='/NGO_trust/privacy_policy' element={<NgoPrivacyPolicy />} />
          <Route path='/NGO_trust/terms_and_conditions' element={<NgoTermsConditions />} />
          <Route path='/NGO_trust/refund_policy' element={<NgoRefundPolicy />} />

          {/* restaurant  */}

          <Route path='/restaurant' element={<RestaurantHome />} />
          <Route path='/restaurant/business_suppliers' element={<BusinessSuppliers />} />
          <Route path='/restaurant/operations' element={<Operations />} />
          <Route path='/restaurant/growth' element={<Growth />} />
          <Route path='/restaurant/startup' element={<Startup />} />
          <Route path='/restaurant/people' element={<People />} />
          <Route path='/restaurant/people/detail' element={<DetailPage />} />
          <Route path='/restaurant/service/food_service' element={<FoodService />} />
          <Route path='/restaurant/service/people' element={<ServicePeople />} />
          <Route path='/restaurant/service/CPG' element={<ServiceCPG />} />
          <Route path='/restaurant/service/D2C_new_commerce' element={<ServiceD2CNewCommerce />} />
          <Route path='/restaurant/service/fashion_beauty' element={<ServiceFashionAndBeauty />} />
          <Route path='/restaurant/service/technology_e_commerce' element={<ServiceTechnologyECommerce />} />
          <Route path='/restaurant/service/retail_business' element={<ServiceRetailBusiness />} />
          <Route path='/restaurant/about_us' element={<RestaurantAboutUs />} />
          <Route path='/restaurant/contact_us' element={<RestaurantContactUs />} />
          <Route path='/restaurant/disclaimer' element={<RestaurantDisclaimer />} />
          <Route path='/restaurant/terms' element={<RestaurantTerms />} />
          <Route path='/restaurant/feedback' element={<RestaurantFeedback />} />
          <Route path='/restaurant/subscription_plan' element={<RestaurantSubscriptionPlan />} />


          {/* MAQ Capital Finance  */}

          <Route path='/maq_capital_finance' element={<MAQCapitalFinanceHome />} />

          {/* MAQ International Schools  */}

          {/* <Route path='/maq_international_schools' element={<MAQInternationalSchoolsHome />} /> */}

          {/* MAQ Marine Logistics  */}

          <Route path='/maq_marine_logistics' element={<MAQMarineLogisticsHome />} />

          {/* MAQ Marine Logistics  */}

          <Route path='/maq_infra_mart' element={<MAQInfraMartHome />} />

          {/* MAQ Hospital  */}

          {/* <Route path='/maq_hospital' element={<MAQHospitalHome />} /> */}

          {/* MAQ Wilmar  */}

          <Route path='/maq_wilmar' element={<MAQWilmarHome />} />

          {/* MAQ Defence  */}

          {/* <Route path='/maq_defence' element={<MAQDefenceHome />} /> */}

          {/* MAQ Green Energy  */}

          <Route path='/maq_green_energy' element={<MAQGreenEnergyHome />} />

          {/* MAQ Agri Logistics  */}

          <Route path='/maq_agri_logistic' element={<MAQAgriLogisticHome />} />

          {/* Al MAQ Tour & Travels */}

          <Route path='/al_maq_tour_travel' element={<AlMAQTourAndTravelsHome />} />

          {/* MAQ University */}

          {/* <Route path='/maq_university' element={<MAQUniversityHome />} /> */}

          {/* MIS Furniture & Decorators */}

          <Route path='/MIS_furniture_decorators' element={<MISFurnitureDecoratorsHome />} />

          {/* MAQ Interiors & Decorators */}

          {/* <Route path='/maq_interiors_decorators' element={< MAQInteriorsDecoratorsHome />} /> */}

          {/* MAQ Architecture */}

          <Route path='/maq_architecture' element={<MAQArchitectureHome />} />

          {/* MAQ Solar Power */}

          <Route path='/maq_solar_power' element={<MAQSolarPowerHome />} />

          {/* MAQ Shipping All World */}

          <Route path='/maq_shipping_all_world' element={<MAQShippingAllWorldHome />} />

          {/*  MAQ The World Import & Export */}

          <Route path='/maq_import_export' element={<MAQImportExportHome />} />

          {/*  MAQ The World Infrastructure */}

          <Route path='/maq_infrastructure' element={<MAQInfrastructureHome />} />





        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
