import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import Slide1 from "../../assest/greenEnergy/23cc649fc6.webp";
import Slide2 from "../../assest/greenEnergy/2b3bf484bc.webp";
import Slide3 from "../../assest/greenEnergy/5b6ed6cbb6.jpeg";
import '../../styles/MAQGreenEnergyHomeStyle.css'
import imgtry2 from "../../assest/greenEnergy/try2.png";
import imgwindenergy from "../../assest/greenEnergy/wind-energy.png";
import imgsea from "../../assest/greenEnergy/sea.png";
import img8a922d72f9 from "../../assest/greenEnergy/8a922d72f9.png";
import img8b54c47c59 from "../../assest/greenEnergy/8b54c47c59.png";
import imgf74d470b21 from "../../assest/greenEnergy/f74d470b21.png";
import img564dd7ba4b from "../../assest/greenEnergy/564dd7ba4b.png";

import imgb8c3ad1d9c from "../../assest/greenEnergy/b8c3ad1d9c.png";
import img21cc074e13 from "../../assest/greenEnergy/21cc074e13.png";
import img7b2263dddd from "../../assest/greenEnergy/7b2263dddd.png";
import imga1bafd182b from "../../assest/greenEnergy/a1bafd182b.png";
import img672f5a1a76 from "../../assest/greenEnergy/672f5a1a76.png";
import img3fe8b4227c from "../../assest/greenEnergy/3fe8b4227c.png";
import { Link } from "react-router-dom";
import Bhavesh from "../../assest/Bhavesh.webp";
import corevalues from "../../assest/greenEnergy/core_values.webp";



const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  strong {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 50px;
    }
    .sub .btnn {
      font-size: 20px;
      letter-spacing: 0.5px;
      border-radius: 6px;
      background-color: #fff;
      text-transform: capitalize;
      padding: 8px 20px;
      color: black;
      margin-top: 20px;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
      .sub .btnn {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 16rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
      .sub .btnn {
        font-size: 16px;
         margin-top: 0px;

      }
    }
  }
`;


const data = [
    {
        id: 1,
        image: Slide1
    },
    {
        id: 2,
        image: Slide2
    },
    {
        id: 3,
        image: Slide3
    }
];


const MAQGreenEnergyHome = () => {
    return (
        <>
            <MAQHeader />
            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {data.map(({ id, image }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={image} alt="img" />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <MyTitleMessage>
                <div className="titleMessage">
                    <div className="heading">
                        <div className="main text-center mb-3">
                            <span>
                                <strong style={{ textTransform: 'uppercase' }}>MAQ THE WORLD Green Energy</strong>
                            </span>
                        </div>

                        <div className="sub">
                            <Button className="btnn">Read More</Button>
                        </div>

                    </div>
                </div>
            </MyTitleMessage>

            <Box className='athospital_welcomeDiv'>
                <Box className="athospitalhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={imgtry2} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>MAQ THE WORLD GREEN ENERGY</h6>
                        <p>MAQ THE WORLD GREEN ENERGY is an umbrella company for the green business initiatives of MAQ THE WORLD GREEN ENERGY and undertakes projects through organic and inorganic routes and aims to be the flag-bearer of MAQ THE WORLD GREEN ENERGY’s journey to achieve the ambitious target of 60 GW by FY 32...</p>

                        <div className="">
                            <div className="vissionmission_volunteer">
                                <div>
                                    <img src={imgsea} alt="img" />

                                </div>
                                <div className="text_vision">
                                    <h6>Vision</h6>
                                    <p>To Be World’s leading Green Energy Solutions Company Driving India’s Energy Transition</p>
                                </div>

                            </div>

                            <div className="vissionmission_volunteer">
                                <div>
                                    <img src={imgwindenergy} alt="img" />

                                </div>
                                <div className="text_vision">
                                    <h6>Mission</h6>
                                    <p>To Provide reliable, affordable and Sustainable Green Energy Solutions to Achieve India’s Energy Transition Objectives By Leveraging Innovation and Technology</p>
                                </div>

                            </div>
                        </div>
                    </div>


                </Box>
            </Box>

            <Box className="row LatestUpdateslay">
                <div className="corevalues_imgdiv col-lg-8 col-12">
                    <img src={corevalues} alt="img" />
                </div>

                <div className="col-lg-4 col-12">
                    <h6>Latest Updates</h6>
                    <ul className="LatestUpdatesDiv">
                        <li> Associate Position for Retired executives (Civil/ Electrical) from PSUs / Govt. Organizations/Organizations of repute at various projects. (03/2024-25). Last date of application extended till 22.05.2024.</li>
                        <li> Ettaypuram solar has bagged the Swarn Shakti RE project execution award</li>
                        <li> MAQ THE WORLD GREEN ENERGY Signs MoU with Govt of Maharashtra for Development of Green Hydrogen Projects 29-01-2024</li>
                        <li> MAQ THE WORLD GREEN ENERGY Inks MoUs with GSPC and GPPL during Vibrant Gujarat Global Summit 12-01-2024</li>
                        <li> MAQ THE WORLD GREEN ENERGY Inks MoUs with Port Authorities & SCI during Global Maritime India Summit 19.10.2023</li>
                        <li> MAQ THE WORLD GREEN ENERGY Inks MoU with ONGC 27-09-2023</li>
                        <li> MAQ THE WORLD GREEN ENERGY and Uttar Pradesh Rajya Vidyut Utpadan Nigam Limited (UPRVUNL) Tied Up to Collaborate for The Development of Renewable Energy Power Parks Projects 31-05-2023</li>
                        <li> MAQ THE WORLD GREEN ENERGY Signs MoU with GRIDCO, Odisha for the Development of Large-Scale Solar and PSP Projects 02.12.2022</li>
                    </ul>
                   
                </div>


            </Box>



            <div className="ourprojectgreen">
                <div className="row justify-content-center align-items-center me-0">
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact greenbgimg text-center">
                            <img src={img8a922d72f9} alt="img" />
                            <h2 data-animation-duration data-value="10,000,00+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">13+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Projects Commissioned</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact greenbgimg text-center">
                            <img src={img8b54c47c59} alt="img" />
                            <h2 data-animation-duration data-value="300+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">2+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Projects Under Execution</h6>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact greenbgimg text-center">
                            <img src={imgf74d470b21} alt="img" />
                            <h2 data-animation-duration data-value="4000+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">2.7+ GW</h2>
                            <h6 className="text-white text-uppercase font-weight-600">Present Capacity</h6>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3 mb-md-50">
                        <div className="funfact greenbgimg text-center">
                            <img src={img564dd7ba4b} alt="img" />
                            <h2 data-animation-duration data-value="20,000+" className="animate-number text-white  number-font font-weight-500 my-2 appeared">58+</h2>
                            <h6 className="text-white text-uppercase font-weight-600">RE a Glance</h6>
                        </div>
                    </div>
                </div>

            </div>

            <Box className='CustomisedSolutions_div'>
                <div className="logistic_topdiv">
                    <h1>Green Portfolios</h1>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={imgb8c3ad1d9c} alt="img" />
                            </div>
                            <div>
                                <h5>Solar Power</h5>
                                <p>We prioritize a comprehensive and systematic approach for the solar project design and implementation.</p>
                            </div>
                        </div>



                    </div>
                    <div className="col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={img21cc074e13} alt="img" />
                            </div>
                            <div>
                                <h5>Floating Solar Power</h5>
                                <p>Floating solar power generation offers a sustainable and space-efficient solution by installation of solar photovoltaic.</p>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={img7b2263dddd} alt="img" />
                            </div>
                            <div>
                                <h5>Hybrid Power</h5>
                                <p>The synergistic approach of hybrid energy generation addresses the limitations of adoption of individual renewable.</p>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={imga1bafd182b} alt="img" />
                            </div>
                            <div>
                                <h5>Wind Power</h5>
                                <p>We're actively involved in the development of onshore and offshore wind power projects across diverse regions.</p>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={img672f5a1a76} alt="img" />
                            </div>
                            <div>
                                <h5>Energy Storage</h5>
                                <p>Energy storage plays a pivotal role in the transition to a more sustainable and resilient energy system.</p>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-4 col-12 p-0 bgPortfolio">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={img3fe8b4227c} alt="img" />
                            </div>
                            <div>
                                <h5>Green Hydrogen</h5>
                                <p>Green hydrogen has significant potential to play a crucial role for decarbonizing sectors that are difficult to electrify.</p>
                            </div>
                        </div>
                    </div>

                </div>

            </Box>


            <div className=" row bg-primary-subtle p-5 me-0">

                <div className=" col-lg-6 col-12">
                    <div className="team_green">
                        <div className="team_green_detail">
                            <div className="team_green_imgdiv">
                                <Link to="">
                                    <img src={Bhavesh} alt="img" />
                                </Link>
                            </div>

                            <div className="">
                                <p>Our goal is to play a meaningful role in accelerating the transition to a more sustainable future, one powered by Green Energy sources. By doing so, we aim to contribute significantly to India's energy independence, a crucial step in building a resilient and self-sufficient nation.</p>
                                <h4>Mohd Bilal</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" col-lg-6 col-12">
                    <div className="team_green">
                        <div className="team_green_detail">
                            <div className="team_green_imgdiv">
                                <Link to="">
                                    <img src={Bhavesh} alt="img" />
                                </Link>
                            </div>

                            <div className="">
                                <p>Our goal is to play a meaningful role in accelerating the transition to a more sustainable future, one powered by Green Energy sources. By doing so, we aim to contribute significantly to India's energy independence, a crucial step in building a resilient and self-sufficient nation.</p>
                                <h4>Mohd Bilal</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4316905546707!2d77.25713517374892!3d28.55679698751912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1719229741175!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </div>



            <MAQFooter />
        </>
    )
}

export default MAQGreenEnergyHome