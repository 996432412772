
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react"
import MAQHeader from '../../common/MAQ/header/MAQHeader';
import MAQFooter from '../../common/MAQ/footer/MAQFooter';
import FaqData from '../../data/FaqData';
import { Typography } from '@mui/material';
import '../../styles/MAQFaqStyle.css'


const MAQFaq = () => {

    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    return (
        <>
            <MAQHeader />

            <div className="maq_accordion">
                <h1 className=' fw-bold mt-4 mb-5'>Frequently Asked Questions.</h1>
                {FaqData?.map((accordionItem) => (
                    <Accordion
                        className='maq_accordion-bigdiv'
                        key={accordionItem.id}
                        expanded={expandedAccordion === accordionItem.id}
                        onChange={handleAccordionChange(accordionItem.id)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${accordionItem.id}-content`}
                            id={`panel${accordionItem.id}-header`}
                        >
                            <Typography className="maq_bigdiv-ques">{accordionItem.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="maq_bigdiv-answr"> {accordionItem.details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>

            <MAQFooter />


        </>
    )
}

export default MAQFaq