import { Box, Button } from "@mui/material"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import polygon from '../../assest/maqworld/earth-115.jpg'
import buildingcomp from '../../assest/maqworld/432216-PEJ4XM-494.webp'
import '../../styles/maqHomePageStyle.css'
import { Link } from "react-router-dom"
import sld2img from '../../assest/demo_image.jpg'
import ceoimg from '../../assest/asifimge.jpeg'
import ourbusinessimg1 from '../../assest/hospital/corporate3.webp'
import ourbusinessimg2 from '../../assest/maqworld/fashion.jpg'
import ourbusinessimg3 from '../../assest/maqworld/p6-03.jpg'
import ourbusinessimg4 from '../../assest/maqworld/p6-04.jpg'
import ourbusinessimg5 from '../../assest/inframart/inframartimg.jpg'
import ourbusinessimg6 from '../../assest/maqworld/foodstuff.jpg'
import ourbusinessimg7 from '../../assest/maqworld/hypermarket.png'
import ourbusinessimg8 from '../../assest/maqworld/shopping-with-mobile-app.jpg'
import ourbusinessimg9 from '../../assest/maqworld/hospital-building.jpg'
import ourbusinessimg10 from '../../assest/maqworld/university7605885.jpg'
import ourbusinessimg11 from '../../assest/maqworld/school1051.jpg'
import ourbusinessimg12 from '../../assest/home2.webp'
import ourbusinessimg13 from '../../assest/maqworld/cosmetic.jpg'
import ourbusinessimg14 from '../../assest/importExport/topbg.jpg'
import ourbusinessimg15 from '../../assest/Infrastructure/laguardia-airport-terminal-b.jpg'
import venturesimg1 from '../../assest/maqworld/DWMC-1.jpg'
import venturesimg2 from '../../assest/maqworld/RDF-1.webp'
import venturesimg3 from '../../assest/maqworld/osian.jpg'
import venturesimg4 from '../../assest/maqworld/safiwater.webp'
import venturesimg5 from '../../assest/maqworld/techagro.webp'
import sisterconcern1 from '../../assest/maqworld/logo-c.png'
import sisterconcern2 from '../../assest/maqworld/logo-d.png'
import sisterconcern3 from '../../assest/maqworld/logo-e.png'
import sisterconcern4 from '../../assest/maqworld/logo-r.png'
import FeaturedProjectSlider from "../../common/MAQ/featuredProjectSlider/FeaturedProjectSlider"
import MaqFeaturedProjectData from "../../data/MaqFeaturedProjectData.js"



const MAQHomePage = () => {

    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <MAQHeader />
            <Box className='maqworld_topdiv'>
                <img src={polygon} alt="img" />
                <div className="company_head">
                    <h1>MAQ THE WORLD GROUP</h1>
                </div>
            </Box>

            <Box className='mainhome_welcomeDiv'>
                <h3 className=" fw-bold py-5">ABOUT US</h3>
                <Box className="mainhome_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={buildingcomp} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>MAQ THE WORLD GROUP</h6>
                        <p>MAQ THE WORLD GROUP is a UAE-based diversified group of companies of Ajman with a prominent presence across multiple industries. Since our establishment in 2003, MAQ THE WORLD GROUP has been committed to delivering high-quality solutions to our clients, while also contributing to a sustainable future for the UAE.</p>
                        <p>Conventionally focused on Turnkey Construction & Engineering, Ready-mix Concrete manufacturing, Concrete Blocks, Interlocks & pavement products manufacturing, Piling & Foundations, Steel Fabrication, Aluminium, Glass & Metal Works Manufacturing and Contracting, Wood Joinery & Interior works, T-Living Italian furniture, Real Estate Development, Petroleum Trading & Storage Terminals, Building Material Trading and Logistics, MAQ THE WORLD GROUP has since grown exponentially and expanded its portfolio with major investments in state-of-the-art infrastructure, technologies as well as real assets on the ground.</p>
                        <p>Over the past five years, MAQ THE WORLD GROUP has transformed into an investor and co-developer of projects that provide Environmental and Sustainable solutions. We are actively engaged in developing mega-projects in Dubai, Ajman & Umm Al Quwain into Waste to Energy, Waste to Fuel, and Water Treatment sectors. We have also substantially invested in Agritech & Aquaculture space to support the UAE's mission towards a sustainable future and food security.</p>
                        <p>At MAQ THE WORLD GROUP, we believe that sustainable development and economic growth go hand-in-hand. MAQ THE WORLD GROUP along with its multiple subsidiaries and various joint venture companies is committed to providing innovative and sustainable solutions to our clients, while also contributing to the UAE's growth story.</p>
                    </div>

                </Box>
            </Box>

            <Box className='homemaq_weworkIn'>

                <div className="row gap-4 homemaq_alldetail">

                    <Link className="col-lg-3 col-12 linktag_homemaq" to={'/'}>
                        <div className="homemaq_areadiv">
                            <div>
                                <img src={ceoimg} alt="img" />
                            </div>
                            <div>
                                <h6>MUHAMMAD ASIF QURESHI</h6>
                                <p>CHAIRMAN & CEO</p>
                            </div>

                            {/* <div className="innersocial mt-3">
                                <Link to="">
                                    <img
                                        className=" p-1"
                                        src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                                        alt="facebook"
                                        style={{ width: 40, height: 40, border: 'none', borderRadius: '50%', backgroundColor: '#1f1d1d' }}
                                    />
                                </Link>
                                <Link to="">
                                    <img
                                        className="p-1"
                                        src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                                        alt="linkedin"
                                        style={{ width: 40, height: 40, border: 'none', borderRadius: '50%', backgroundColor: '#1f1d1d' }}
                                    />
                                </Link>
                                <Link to="">
                                    <img
                                        className="p-1"
                                        src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                                        alt="instagram"
                                        style={{ width: 40, height: 40, border: 'none', borderRadius: '50%', backgroundColor: '#1f1d1d' }}
                                    />
                                </Link>
                                <Link to="">
                                    <img
                                        className="p-1"
                                        src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                                        alt="youTube"
                                        style={{ width: 40, height: 40, border: 'none', borderRadius: '50%', backgroundColor: '#1f1d1d' }}
                                    />
                                </Link>

                            </div> */}

                        </div>
                    </Link>

                    {/* <Link className="col-lg-5 col-12 linktag_homemaq" to={'/'}>
                        <div className="homemaq_areadiv">
                            <div>
                                <img src={sld2img} alt="img" />
                            </div>
                            <div>
                                <h6>MUHAMMAD ASIF QURESHI</h6>
                                <p>MAQ THE WORLD GROUP CEO</p>
                            </div>

                            <div className="innersocial mt-5">
                                <Link className="" to="">
                                    <img
                                        className=""
                                        src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                                        alt="facebook"
                                        style={{ width: 32, height: 32, border: 'none' }}
                                    />
                                </Link>
                                <Link className="" to="">
                                    <img
                                        className=""
                                        src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                                        alt="linkedin"
                                        style={{ width: 32, height: 32, border: 'none' }}
                                    />
                                </Link>
                                <Link className="" to="">
                                    <img
                                        className=""
                                        src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                                        alt="instagram"
                                        style={{ width: 32, height: 32, border: 'none' }}
                                    />
                                </Link>
                                <Link className="" to="">
                                    <img
                                        className=""
                                        src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                                        alt="youTube"
                                        style={{ width: 32, height: 32, border: 'none' }}
                                    />
                                </Link>

                            </div>

                        </div>
                    </Link> */}

                </div>

            </Box>

            <div className="ourbusiness_allproject_div">
                <h3 className=" fw-bold">OUR BUSINESSES</h3>
                <div className="div_slide_ourbusiness_allproject">

                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg3} alt={`img`} />
                        <h4>MAQ THE WORLD CAPITAL FINANCE</h4>
                        <Button onClick={() => openNewTab('/maq_capital_finance')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg14} alt={`img`} />
                        <h4>MAQ THE WORLD IMPORT & EXPORT</h4>
                        <Button onClick={() => openNewTab('/maq_import_export')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg15} alt={`img`} />
                        <h4>MAQ The World Infrastructure</h4>
                        <Button onClick={() => openNewTab('/maq_infrastructure')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg12} alt={`img`} />
                        <h4>MAQ The World Builders & Developers</h4>
                        <Button onClick={() => openNewTab('/maq_builder_and_developer')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg5} alt={`img`} />
                        <h4>MAQ THE WORLD INFRA MART</h4>
                        <Button onClick={() => openNewTab('/maq_infra_mart')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg6} alt={`img`} />
                        <h4>MAQ The World Food Stuff</h4>
                        <Button onClick={() => openNewTab('https://maqfoodstuff.com/')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg7} alt={`img`} />
                        <h4>MAQ The World Hyper Market</h4>
                        <Button onClick={() => openNewTab('https://www.onetouchautomation.co/')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg8} alt={`img`} />
                        <h4>MAQ The World Mobile Mart</h4>
                        <Button onClick={() => openNewTab('https://www.onetouchautomation.co/')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg9} alt={`img`} />
                        <h4>MAQ The World Hospitals</h4>
                        <Button onClick={() => openNewTab('/maq_hospital')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg10} alt={`img`} />
                        <h4>MAQ The World University</h4>
                        <Button onClick={() => openNewTab('/maq_university')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg11} alt={`img`} />
                        <h4>MAQ The World International Schools</h4>
                        <Button onClick={() => openNewTab('/maq_international_schools')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg1} alt={`img`} />
                        <h4>MAQ The World Healthcare</h4>
                        <Button onClick={() => openNewTab('/maq_hospital')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg13} alt={`img`} />
                        <h4>MAQ The World Cosmetics</h4>
                        <Button onClick={() => openNewTab('https://cosmetics.maqtheworldgroup.com/')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg2} alt={`img`} />
                        <h4>MAQ The World Fashions</h4>
                        <Button onClick={() => openNewTab('https://fashions.maqtheworldgroup.com')} className="btnread">Read More</Button>
                    </div>
                    <div className="div_business_alldetail">
                        <img src={ourbusinessimg4} alt={`img`} />
                        <h4>MAQ The World Shipping</h4>
                        <Button onClick={() => openNewTab('/maq_shipping_all_world')} className="btnread">Read More</Button>
                    </div>
                    

                </div >
            </div >

            {/* <Box className='sectors_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">OUR BUSINESSES</h1>
                </div>

                <div className="row gap-4 sector_alldetail ">

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg3} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ THE WORLD CAPITAL FINANCE</h1>
                                <Button onClick={() => openNewTab('/maq_capital_finance')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg14} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ THE WORLD IMPORT & EXPORT</h1>
                                <Button onClick={() => openNewTab('/maq_import_export')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg15} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Infrastructure</h1>
                                <Button onClick={() => openNewTab('/maq_architecture')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg12} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Builders & Developers</h1>
                                <Button onClick={() => openNewTab('/maq_builder_and_developer')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg5} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ THE WORLD INFRA MART</h1>
                                <Button onClick={() => openNewTab('/maq_infra_mart')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg6} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Food Stuff</h1>
                                <Button onClick={() => openNewTab('https://maqfoodstuff.com/')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg7} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Hyper Market</h1>
                                <Button onClick={() => openNewTab('https://www.onetouchautomation.co/')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg8} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Mobile Mart</h1>
                                <Button onClick={() => openNewTab('https://www.onetouchautomation.co/')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg9} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Hospitals</h1>
                                <Button onClick={() => openNewTab('/maq_hospital')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg10} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World University</h1>
                                <Button onClick={() => openNewTab('/maq_university')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg11} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World International Schools</h1>
                                <Button onClick={() => openNewTab('/maq_international_schools')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg1} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Healthcare</h1>
                                <Button onClick={() => openNewTab('/maq_hospital')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg13} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Cosmetics</h1>
                                <Button onClick={() => openNewTab('https://cosmetics.maqtheworldgroup.com/')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg2} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ The World Fashions</h1>
                                <Button onClick={() => openNewTab('https://fashions.maqtheworldgroup.com')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={ourbusinessimg4} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>MAQ THE WORLD MARINE LOGISTICS</h1>
                                <Button onClick={() => openNewTab('/maq_marine_logistics')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                </div>


            </Box> */}



            <Box className='sectors_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">JOINT VENTURES</h1>
                </div>

                <div className="row gap-4 sector_alldetail ">

                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={venturesimg1} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>OCEAN HARVEST</h1>
                                <p>Ocean Harvest Is A Technology-enabled Agribusiness Based In The Uaefocused On Year-round Local Production Of Delicious And Affordable Salmon.</p>
                                <Button onClick={() => openNewTab('/venture_detail')}>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={venturesimg2} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>OCEAN HARVEST</h1>
                                <p>Ocean Harvest Is A Technology-enabled Agribusiness Based In The Uaefocused On Year-round Local Production Of Delicious And Affordable Salmon.</p>
                                <Button onClick={() => openNewTab('/venture_detail')}>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={venturesimg3} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>OCEAN HARVEST</h1>
                                <p>Ocean Harvest Is A Technology-enabled Agribusiness Based In The Uaefocused On Year-round Local Production Of Delicious And Affordable Salmon.</p>
                                <Button onClick={() => openNewTab('/venture_detail')}>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={venturesimg4} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>OCEAN HARVEST</h1>
                                <p>Ocean Harvest Is A Technology-enabled Agribusiness Based In The Uaefocused On Year-round Local Production Of Delicious And Affordable Salmon.</p>
                                <Button onClick={() => openNewTab('/venture_detail')}>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className="flip-card">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={venturesimg5} alt="Avatar" />
                            </div>
                            <div className="flip-card-back">
                                <h1>OCEAN HARVEST</h1>
                                <p>Ocean Harvest Is A Technology-enabled Agribusiness Based In The Uaefocused On Year-round Local Production Of Delicious And Affordable Salmon.</p>
                                <Button onClick={() => openNewTab('/venture_detail')}>Read More</Button>
                            </div>
                        </div>
                    </div>

                </div>


            </Box>

            <Box className='sectors_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">SISTER CONCERN</h1>
                </div>

                <div className="row gap-4 sector_alldetail ">

                    <div className="concern-card">
                        <div className="concern-card-inner">
                            <div className="concern-card-front">
                                <img src={sisterconcern1} alt="Avatar" />
                            </div>
                            <div className="concern-card-back">
                                <ul>
                                    <li>The Black Square</li>
                                    <li>CityLife Al Jurf</li>
                                    <li>CityLife Hamidiya</li>
                                    <li>CityLife Zorah</li>
                                    <li>CityLife Al Khor</li>
                                    <li>CityLife Al Tallah</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="concern-card">
                        <div className="concern-card-inner">
                            <div className="concern-card-front">
                                <img src={sisterconcern2} alt="Avatar" />
                            </div>
                            <div className="concern-card-back">
                                <ul>
                                    <li>The Black Square</li>
                                    <li>CityLife Al Jurf</li>
                                    <li>CityLife Hamidiya</li>
                                    <li>CityLife Zorah</li>
                                    <li>CityLife Al Khor</li>
                                    <li>CityLife Al Tallah</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="concern-card">
                        <div className="concern-card-inner">
                            <div className="concern-card-front">
                                <img src={sisterconcern3} alt="Avatar" />
                            </div>
                            <div className="concern-card-back">
                                <ul>
                                    <li>The Black Square</li>
                                    <li>CityLife Al Jurf</li>
                                    <li>CityLife Hamidiya</li>
                                    <li>CityLife Zorah</li>
                                    <li>CityLife Al Khor</li>
                                    <li>CityLife Al Tallah</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="concern-card">
                        <div className="concern-card-inner">
                            <div className="concern-card-front">
                                <img src={sisterconcern4} alt="Avatar" />
                            </div>
                            <div className="concern-card-back">
                                <ul>
                                    <li>The Black Square</li>
                                    <li>CityLife Al Jurf</li>
                                    <li>CityLife Hamidiya</li>
                                    <li>CityLife Zorah</li>
                                    <li>CityLife Al Khor</li>
                                    <li>CityLife Al Tallah</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>


            </Box>

            <Box className='aboutcomp_Excellence'>
                <div className="row align-items-center justify-content-center overflow-hidden">
                    <div className=" col-lg-3 col-12 maqhome_detailinfo">
                        <div>
                            <h4>2,500+</h4>
                            <p>EMPLOYEES </p>

                        </div>
                    </div>
                    <div className=" col-lg-3 col-12 maqhome_detailinfo">
                        <div>
                            <h4>10+</h4>
                            <p>BILLION AED PROJECTS DELIVERED</p>

                        </div>
                    </div>
                    <div className=" col-lg-3 col-12 maqhome_detailinfo">
                        <div>
                            <h4>200+</h4>
                            <p>PROJECTS DELIVERED </p>
                        </div>
                    </div>
                    <div className=" col-lg-3 col-12 maqhome_detailinfo">
                        <div>
                            <h4>60+</h4>
                            <p>ON GOING PROJECTS </p>
                        </div>
                    </div>


                </div>

            </Box>

            <Box className='featured_projectweworkIn'>
                <div className="logistic_topdiv">
                    <h1 className=" fw-bold">FEATURED PROJECTS</h1>
                </div>

                <FeaturedProjectSlider detailsData={MaqFeaturedProjectData} />

            </Box>

            <MAQFooter />

        </>
    )
}

export default MAQHomePage