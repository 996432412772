import people1 from '../assest/importExport/gifts/Novelty Gifts.jpg'
import people2 from '../assest/importExport/gifts/Plastic Key Chains.jpg'
import people3 from '../assest/importExport/gifts/Resin Crafts.jpg'
import people4 from '../assest/importExport/gifts/Metal Crafts.jpg'
import people5 from '../assest/importExport/gifts/Gift Sets.jpg'
import people6 from '../assest/importExport/gifts/Crystal Crafts.jpg'
import people7 from '../assest/importExport/gifts/Metal Key Chains.jpg'
import people8 from '../assest/importExport/gifts/Paper Crafts.jpg'
import people9 from '../assest/importExport/gifts/Decorative Stickers.jpg'
import people10 from '../assest/importExport/gifts/Gift Cards.jpg'
import people11 from '../assest/importExport/gifts/Wood Crafts.jpg'
import people12 from '../assest/importExport/gifts/Promotional Keychains.jpg'
import people13 from '../assest/importExport/gifts/Rubber Key Chains.jpg'





const GiftsCraftsData = [
    {
        id: 1,
        heading: 'Novelty Gifts',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Plastic Key Chains',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Resin Crafts',
        imgSrc: people3,
    },
    {
        id: 1,
        heading: 'Metal Crafts',
        imgSrc: people4,
    },
    {
        id: 2,
        heading: 'Gift Sets',
        imgSrc: people5,
    },
    {
        id: 3,
        heading: 'Crystal Crafts',
        imgSrc: people6,
    }, {
        id: 1,
        heading: 'Metal Key Chains',
        imgSrc: people7,
    },
    {
        id: 2,
        heading: 'Paper Crafts',
        imgSrc: people8,
    },
    {
        id: 3,
        heading: 'Decorative Stickers',
        imgSrc: people9,
    }, {
        id: 1,
        heading: 'Gift Cards',
        imgSrc: people10,
    },
    {
        id: 2,
        heading: 'Wood Crafts',
        imgSrc: people11,
    },
    {
        id: 2,
        heading: 'Promotional Key chains',
        imgSrc: people12,
    },
    {
        id: 2,
        heading: 'Rubber Key Chains',
        imgSrc: people13,
    },
    



];
export default GiftsCraftsData