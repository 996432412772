import people1 from '../assest/BuildersDeveloper/Raipur.jpg'
import people2 from '../assest/BuildersDeveloper/Bilaspur.jpg'
import people3 from '../assest/BuildersDeveloper/Raigarh.jpg'
import people4 from '../assest/BuildersDeveloper/Korba.jpg'


const ChattisgarhCityData = [
    {
        id: 1,
        heading: 'Raipur',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Korba',
        imgSrc: people4,
    },
    {
        id: 3,
        heading: 'Raigarh',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Bilaspur',
        imgSrc: people2,
    },
    

];
export default ChattisgarhCityData