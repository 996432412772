import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import bgbanner from "../../assest/architecture/home-builder-sina.jpg";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import TravelByCatogryData from "../../data/TravelByCatogryData";
import '../../styles/MAQArchitectureHomeStyle.css'
import unsplashscaled1 from "../../assest/architecture/unsplashscaled1.jpg";
import unsplashscaled2 from "../../assest/architecture/unsplashscaled2.jpg";
import unsplashscaled3 from "../../assest/architecture/unsplashscaled3.jpg";
import { FaRegFolder } from "react-icons/fa";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import bgcontact from "../../assest/architecture/bayview-park03.jpg";
import resourcesc from "../../assest/architecture/resourcesc.png";
import resource1 from "../../assest/architecture/resource1.jpg";
import resource2 from "../../assest/architecture/resource2.jpg";
import resource3 from "../../assest/architecture/resource3.jpg";
import resource4 from "../../assest/architecture/resource4.jpg";
import resource5 from "../../assest/architecture/resource5.jpg";
import resource6 from "../../assest/architecture/resource6.jpg";


const MAQArchitectureHome = () => {

    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    return (
        <>
            <MAQHeader />
            <Box className='Architecture_topmaindiv'>
                <img src={bgbanner} alt="img" />
                <div className="Architecture_head">
                    <h1 className=" text-center">MAQ The World Architecture </h1>
                    <p>Luxury Custom Home Builder & Residential Developer. Making dream homes a reality & building award-winning communities that last</p>
                </div>
            </Box>

            <div className="TravelOptions">
                <div className="TravelOptions_slide">
                    {TravelByCatogryData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="TravelOptions_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
            </div >

            <div className="Internationally">
                <h1>Internationally Renowned Architectural Design</h1>
                <p> Sina Architectural Design is the culmination of renowned architect Sina Sadeddin’s decades of experience creating exceptional residential homes. With decades of experience in the architecture and design industry, Sina Sadeddin has honed his craft to perfection, and his expertise is evident in every project that his firm undertakes. The firm has earned a global reputation for excellence, with a portfolio that includes an impressive range of public and private commissions that are characterized by their innovation, elegance, and attention to detail.</p>
                <p> As a globally recognized and innovative firm, we specialize in building luxury custom homes tailored to each client’s unique vision and lifestyle, with a commitment to sustainability and the environment. Our architectural design firm represents the epitome of luxury and sophistication in custom home building and design. We are the go-to architect for those who demand the very best in luxury bespoke home design, with a reputation for excellence that spans the globe.</p>
                <p> From innovative concepts to environmentally conscious practices, we have set the standard for custom home design and continue to push the boundaries of what is possible in luxury architecture.</p>
                <Button className="Learnbtn">Learn More</Button>
            </div>


            <div class="ImageBackgroundWrap Background-fixed">
                <div class="ImageInnerWrap">
                    <div class="TextBackground row">
                        <div className=" col-lg-6 col-12 left_Architectural">
                            <h1>Residential & Commercial Development</h1>
                            <p>  Our residential and mixed-use commercial development projects throughout North America bring together communities with luxury townhomes and condominium developments. We are committed to sustainable practices, eco-friendly design, and the highest level of building quality and craftsmanship. We are dedicated to building thriving and flourishing communities, and give back by partnering with local agencies to provide resources to those in need.</p>
                            <Button className="btnb">Real Estate Developments</Button>


                        </div>
                        <div className=" col-lg-6 col-12 left_Architectural">
                            <h1>Luxury Custom Home Builder</h1>
                            <p> Our team of custom home builders combines architectural design with decades of experience in home building to craft stunning luxury homes that are meticulously designed for you down to each and every unique detail. As a full-service design firm we handle every aspect of building your custom home, from initial planning and development to interior design. We’re committed to working with only a select number of clients per year to ensure we are able to dedicate the time-intensive resources to truly bring their vision to life.</p>
                            <Button className="btnb">Luxury Custom Home Builder</Button>

                        </div>
                    </div>
                </div>
            </div>

            <Box className='LatestCustom_welcomeDiv'>
                <h1 className=" fw-bold py-5">Latest Custom Home Building Tips</h1>
                <Box className="LatestCustomhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={unsplashscaled1} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <p>July 14, 2023</p>
                        <h6>Differences Between Condos and Apartments in Canada</h6>
                        <p>In Canada’s real estate landscape, two terms frequently surface when discussing residential properties: condos and apartments. While they share some similarities, understanding the fundamental differences between these two housing options is crucial for prospective buyers or tenants. Instinctively, most people in Canada, especially in Toronto, know the difference between condos and apartments, but it can...</p>
                        <Button className="morebtn">Read More</Button>
                        <div>
                            <Link><FaRegFolder className=" me-2" /> <span>Residential Development</span></Link>
                        </div>
                    </div>
                </Box>
            </Box>


            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <p>June 30, 2023</p>
                        <h6>Do Toronto Condos Need Balconies?</h6>
                        <p>With the proliferation of condominiums throughout the Greater Toronto Area, a noticeable trend has emerged: the inclusion of balconies as a prominent design feature. While this architectural addition may enhance the exterior aesthetics, prospective buyers often question the practicality of these small and narrow balconies, prompting them to ask whether they are truly essential. As...</p>
                        <Button className="morebtn">Read More</Button>
                        <div>
                            <Link><FaRegFolder className=" me-2" /> <span>Residential Development</span></Link>
                        </div>

                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={unsplashscaled2} alt="img" />
                    </div>

                </Box>
            </Box>


            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={unsplashscaled3} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <p>June 16, 2023</p>
                        <h6>Differences Between Residential and Commercial Developers</h6>
                        <p>Real estate development is a diverse industry that encompasses both residential and commercial sectors. While the end goal of both types of developers is to create profitable properties, there are distinct differences in their approaches, strategies, and considerations. As an architectural design firm that does both, here are five of the main differences between residential...</p>
                        <Button className="morebtn">Read More</Button>
                        <div>
                            <Link><FaRegFolder className=" me-2" /> <span>Commercial Developer, Toronto Residential Builder</span></Link>
                        </div>

                    </div>

                </Box>
            </Box>

            <Box className='Custom_topdiv'>
                <img src={resourcesc} alt="img" />
                <div className="custom_head">
                    <h1 className=" text-center">Resources for Custom Home Building </h1>
                    <p>Tips & tricks from our renowned architectural design team for building your dream home</p>
                </div>
            </Box>

            <Box className='sectors_weworkIn'>

                <div className="row gap-4 sector_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={resource1} alt="img" />
                            </div>
                            <div>
                                <h6>The 6 Types of Commercial Real Estate Property</h6>
                                <p>Most people know the difference between residential and commercial development, but did you know there are different commercial real estate types? From offices to retail spaces, our commercial developers are breaking down the six different types of commercial real estate properties.   Offices Office buildings are one of the most common types of commercial properties.....</p>
                            </div>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={resource2} alt="img" />
                            </div>
                            <div>
                                <h6>Should Toronto Turn Its Empty Office Buildings into Condos?</h6>
                                <p>Toronto, a bustling metropolis known for its vibrant urban landscape and thriving business sector, is currently facing a challenge of a different kind. As remote work gains momentum and companies explore hybrid work models, many office buildings in the city are left vacant. This situation begs the question: Should Toronto consider repurposing these empty office...</p>
                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={resource3} alt="img" />
                            </div>
                            <div>
                                <h6>5 Signs of a Good Commercial Developer</h6>
                                <p>Choosing the right commercial developer is crucial for the success of any commercial real estate project. A skilled and reputable developer can make a significant difference in terms of quality, efficiency, and profitability. Here are a few signs of a good commercial developer to look for before you sign any agreements.   Experience and Track...</p>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={resource4} alt="img" />
                            </div>
                            <div>
                                <h6>3 Colors to Avoid Using in a Custom Home Kitchen</h6>
                                <p>The kitchen is the heart of the home, and it’s where people naturally gather. When it comes to designing a kitchen, choosing the right paint color is crucial. You want the paint to match the other elements in the kitchen, such as countertops and cabinets. It’s also important for the color to match the overall...</p>
                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={resource5} alt="img" />
                            </div>
                            <div>
                                <h6>5 Paint Colors to Avoid in the Bedroom</h6>
                                <p>The bedroom plays such a vital role in our life. It’s where we rest and recharge after a long day. Your bedroom should be a safe space, an oasis from all the hustle and bustle of your daily life. When designing your custom home bedroom, you’ll have to think about what sort of vibe you...</p>
                            </div>

                        </div>

                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={resource6} alt="img" />
                            </div>
                            <div>
                                <h6>The Do’s and Don’ts to Feng Shui Your Bathroom</h6>
                                <p>Bathrooms play a very important, practical role in any house. But just because function is at the forefront of bathroom design doesn’t mean it can’t be optimized for beauty and feng shui as well! According to feng shui, the bathroom is considered to have the lowest flow of energy of all rooms in the home....</p>
                            </div>

                        </div>

                    </Link>

                </div>
                <div className="alldivbtn">
                    <Button>View All Resources</Button>
                </div>

            </Box>


            <Box className='contact_Architectural'>
                <img src={bgcontact} alt="img" />
                <div className="contact_architechead">
                    <h2>Contact MAQ The World Architectural </h2>
                    <p>Use the form below to get in touch with our office. We will do our best to get back within 48 hours.</p>
                </div>
            </Box>

            <div className=" bg-dark-subtle contact_Architectural_form">
                <div className="row justify-content-center">

                    <div className="col-lg-6 col-12 card-body row justify-content-center">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row maqformdiv">
                                    <div className="row justify-content-center">
                                        <div className="col-12">

                                            <Field type='text' name='FullName' placeholder='Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>

                                        <div className=" mt-3">

                                            <Field type='text' name='EmailID' placeholder='Email'
                                                className={
                                                    `form-control
                                                      ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>



                                        <div className="col-12 mt-3">

                                            <Field type='text' name='Phone' placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>




                                        <div className="col-12 mt-3 contactform">
                                            <label className="form-label" htmlFor="">What is Your Inquiry in Regards to? *</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >General Inquiry</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>

                                        <div className=" mt-3">

                                            <Field as={'textarea'} rows={6} name='YourMessage' placeholder='Your Message'
                                                className={
                                                    `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-3">
                                            <button type="submit" className="btnbtnbtn">
                                                Submit
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                    <div className=" col-lg-5 col-12 mainmaq_TouchContact ">
                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong> 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Email ID : </strong> abaris@softech.com</p>
                            </div>

                        </div>

                        {/* <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div> */}
                    </div>

                </div>
            </div>


            <MAQFooter />
        </>
    )
}

export default MAQArchitectureHome