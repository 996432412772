import Wilmarbanne1 from '../assest/wilmar/189879atest.webp'
import Wilmarbanne2 from '../assest/wilmar/FortuneFood.png'
import Wilmarbanne3 from '../assest/wilmar/PoshanImage.webp'
import Wilmarbanne4 from '../assest/wilmar/Screenshot_1.webp'






const WilmareTopSliderData = [
    {
        id: 1,
        imgSrc: Wilmarbanne1,
    },
    {
        id: 2,
        imgSrc: Wilmarbanne2,
    },
    {
        id: 3,
        imgSrc: Wilmarbanne3,
    }, {
        id: 4,
        imgSrc: Wilmarbanne4,
    }
];
export default WilmareTopSliderData