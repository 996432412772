
import people1 from '../assest/BuildersDeveloper/Dehradun.jpg'
import people2 from '../assest/BuildersDeveloper/Roorkee.jpg'
import people3 from '../assest/BuildersDeveloper/Haridwar.jpg'
import people4 from '../assest/BuildersDeveloper/Rishikesh.jpg'


const UttarakhandCityData = [
    {
        id: 1,
        heading: 'Dehradun',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Roorkee',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Haridwar',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Rishikesh',
        imgSrc: people4,
    },
    

];
export default UttarakhandCityData