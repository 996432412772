import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import sld2img from '../../assest/demo_image.jpg'
import { Box } from "@mui/material"
import '../../styles/OurDirectorStyle.css'


const OurDirectors = () => {
    return (
        <>
            <MAQHeader />
            <div className="">
                <Box className='OurDirectors_weworkIn'>
                    <div className="logistic_topdiv">
                        <h1 className=" fw-bold">Our Directors</h1>
                    </div>

                    <div className="row gap-4 OurDirectors_alldetail ">

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>WALEE QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>NOOR QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ASHRAF QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ASLAM QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>HAROON QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>IMRAN QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                       
                    
                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={sld2img} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ABDULLAH QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>



                </Box>
            </div>

            <MAQFooter />
        </>
    )
}

export default OurDirectors