import people1 from '../assest/BuildersDeveloper/Chandigarh.jpg'
import people2 from '../assest/BuildersDeveloper/amritsar.jpg'
import people3 from '../assest/BuildersDeveloper/Ludhiana.jpg'
import people4 from '../assest/BuildersDeveloper/Patiala.jpg'


const PunjabCityData = [
    {
        id: 1,
        heading: 'Chandigarh',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Amritsar',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Ludhiana',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Patiala',
        imgSrc: people4,
    },
    

];
export default PunjabCityData