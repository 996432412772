
import React, { useState } from 'react'
import Logo from "../../../assest/MAQlogo.jpeg";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import SideBar from '../../realEstate/sidebar/SideBar';
import { IoSearchSharp } from "react-icons/io5";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import './RestaurantHeaderStyle.css'


const RestaurantHeader = () => {
    const [navActive, setNavActive] = useState("#");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };


    return (
        <>
            <Box className="Restaurant_topHeader">
                <div className='heading-div-mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <a href="/restaurant"
                    className={navActive === "/restaurant" ? "active" : ""}
                    onClick={() => setNavActive("/restaurant")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='ngo_compnyName'>MAQ THE WORLD Franchise</span>
                </a>

                <Box className='rightUL'>
                    <ul className="Restaurant_liauto">

                        <li>
                            <div className="input-group">
                                <input type="text" className="form-control" aria-label="" placeholder=' Search Here...' />
                                <span className="input-group-text"><IoSearchSharp /> </span>
                            </div>

                        </li>

                        <li>
                            <Link className="" to="">
                                <FaFacebookSquare className=' fs-3 text-secondary' />
                            </Link>
                        </li>

                        <li>
                            <Link className="" to="">
                                <FaInstagramSquare className=' fs-3 text-secondary' />
                            </Link>
                        </li>

                        <li>
                            <Link className="" to="">
                                <FaLinkedin className=' fs-3 text-secondary' />
                            </Link>
                        </li>

                        <li>
                            <Link className="" to="">
                                <FaTwitterSquare className=' fs-3 text-secondary' />
                            </Link>
                        </li>


                    </ul>
                </Box>
            </Box>

            <Box className="Restaurant_topHeader">
                <Box className='rightUL'>
                    <ul className="Restaurant_header_li">
                        <li>
                            <a
                                onClick={() => setNavActive("/restaurant/food_service")}
                                className={navActive === "/restaurant/food_service" ? "active" : ""}
                                href="/restaurant/service/food_service"
                            >
                                Food Service
                            </a>
                        </li>

                        <li>
                            <Link
                                to="/restaurant/people"
                                onClick={() => setNavActive("/restaurant/people")}
                                className={navActive === "/restaurant/people" ? "active" : ""}
                            >
                                People
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/startup")}
                                className={navActive === "/restaurant/startup" ? "active" : ""}
                                to="/restaurant/startup"
                            >
                                New Launch
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/growth")}
                                className={navActive === "/restaurant/growth" ? "active" : ""}
                                to="/restaurant/growth"
                            >
                                Growth
                            </Link>
                        </li>

                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/operations")}
                                className={navActive === "/restaurant/operations" ? "active" : ""}
                                to="/restaurant/operations"
                            >
                                Operations
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => setNavActive("/restaurant/business_suppliers")}
                                className={navActive === "/restaurant/business_suppliers" ? "active" : ""}
                                to="/restaurant/business_suppliers"
                            >
                                Business Suppliers
                            </Link>
                        </li>


                    </ul>
                </Box>
            </Box>


        </>
    )
}

export default RestaurantHeader