
import img10 from '../assest/misFurnitureDecorators/10.jpg'
import img11 from '../assest/misFurnitureDecorators/11.png'
import img12 from '../assest/misFurnitureDecorators/12.jpg'
import img13 from '../assest/misFurnitureDecorators/13.jpg'
import img14 from '../assest/misFurnitureDecorators/14.jpg'
import img15 from '../assest/misFurnitureDecorators/15.jpg'
import img16 from '../assest/misFurnitureDecorators/16.jpg'
import img17 from '../assest/misFurnitureDecorators/17.jpg'
import img18 from '../assest/misFurnitureDecorators/18.jpg'
import img19 from '../assest/misFurnitureDecorators/19.jpg'
import img20 from '../assest/misFurnitureDecorators/20.jpg'
import img21 from '../assest/misFurnitureDecorators/21.jpg'
import img22 from '../assest/misFurnitureDecorators/22.jpg'
import img23 from '../assest/misFurnitureDecorators/23.jpg'
import img24 from '../assest/misFurnitureDecorators/24.jpg'
import img25 from '../assest/misFurnitureDecorators/25.jpg'
import img26 from '../assest/misFurnitureDecorators/26.jpg'
import img27 from '../assest/misFurnitureDecorators/27.jpg'
import img28 from '../assest/misFurnitureDecorators/28.jpg'



const MisFurnitureDecoratorData = [
    {
        id: 1,
        src: img10,
    },
    {
        id: 2,
        src: img11,
    },
    {
        id: 3,
        src: img12,
    }, {
        id: 4,
        src: img13,
    }, {
        id: 5,
        src: img14,
    }, {
        id: 6,
        src: img15,
    }, {
        id: 7,
        src: img16,
    }, {
        id: 8,
        src: img17,
    }, {
        id: 9,
        src: img18,
    }, {
        id: 10,
        src: img19,
    }
    , {
        id: 20,
        src: img20,
    }
    , {
        id: 21,
        src: img21,
    }
    , {
        id: 22,
        src: img22,
    }
    , {
        id: 23,
        src: img23,
    }
    , {
        id: 24,
        src: img24,
    }
    , {
        id: 25,
        src: img25,
    }
    , {
        id: 26,
        src: img26,
    }
    , {
        id: 27,
        src: img27,
    }
    , {
        id: 28,
        src: img28,
    }
];
export default MisFurnitureDecoratorData