
import people2 from '../assest/BuildersDeveloper/dubai.jpg'
import people3 from '../assest/BuildersDeveloper/sarjah.jpg'
import people1 from '../assest/BuildersDeveloper/abu_dhabi.jpg'
import people4 from '../assest/BuildersDeveloper/ajman.jpg'


const UAECountryCityData = [
    {
        id: 1,
        heading: 'Abu Dhabi',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Dubai',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'sharjah',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Ajman',
        imgSrc: people4,
    },
    

];
export default UAECountryCityData