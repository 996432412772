import people1 from '../assest/misFurnitureDecorators/5.jpg'
import people2 from '../assest/misFurnitureDecorators/4.jpg'
import people3 from '../assest/misFurnitureDecorators/9.jpg'
import people4 from '../assest/misFurnitureDecorators/maxresdefault.jpg'
import people5 from '../assest/misFurnitureDecorators/Orris-Market.webp'
import people6 from '../assest/misFurnitureDecorators/apollo-medics.webp'




const RecentCompletedProjectsData = [
    {
        id: 1,
        heading:"Axis Bank–Sriganganagr Rajasthan",
        imgSrc: people1,
    },
    {
        id: 2,
        heading:"ICICI Bank–Aliganj Lucknow",
        imgSrc: people2,
    },
    {
        id: 3,
        heading:"ICICI Bank – Kanpur, Uttar Pradesh",
        imgSrc: people2,
    } ,
     {
        id: 4,
        heading:"ICICI Bank – In Rajlok Meerut",
        imgSrc: people2,
    }
    ,
    {
       id: 5,
       heading:"Seva Chicken:-Lulu Mall Lucknow",
       imgSrc: people4,
   }
   ,
   {
      id: 6,
      heading:"Orris Sales Group:- Manesar Gurugram",
      imgSrc: people5,
  }
  ,
  {
     id: 7,
     heading:"Ather Training Center :- Okhla New Delhi",
     imgSrc: people3,
 }
 ,
 {
    id: 8,
    heading:"Apollo Medics Super Spciality Hospitals",
    imgSrc: people6,
}
];
export default RecentCompletedProjectsData