import { Box } from "@mui/material"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Link } from "react-router-dom"
import sld2img from '../../assest/asifimge.jpeg'
import facebook from '../../assest/Infrastructure/facebook.png'
import twitter from '../../assest/Infrastructure/twitter.png'
import youtube from '../../assest/Infrastructure/youtube.png'
import linkedin from '../../assest/Infrastructure/linkedin.png'
import instagram from '../../assest/Infrastructure/instagram.png'
import '../../styles/ourChairmanStyle.css'
import demo_image from '../../assest/demo_image.jpg'


const OurChairman = () => {
    return (
        <>
            <MAQHeader />
            <Box className='bgblackdiv_OurChairman'>
                <div className=" row align-items-center justify-content-center">
                    <div className=" col-lg-6 col-12 OurChairman_Imgdiv">
                        <div className="imgdiv">
                            <img src={sld2img} alt="img" />
                        </div>
                        <div className="">
                            <h1>MUHAMMAD ASIF QURESHI</h1>
                            <h6>CHAIRMAN & CEO</h6>
                        </div>
                        {/* <div className="OurChairman_iconsocial py-5">
                            <Link className="" to="">
                                <img
                                    className="icoimgsoc"
                                    src={facebook}
                                    alt="facebook"
                                />
                            </Link>
                            <Link className="" to="">
                                <img
                                    className="icoimgsoc"
                                    src={instagram}
                                    alt="instagram"
                                />
                            </Link>
                            <Link className="" to="">
                                <img
                                    className="icoimgsoc"
                                    src={linkedin}
                                    alt="linkedin"
                                />
                            </Link>
                            
                            <Link className="" to="">
                                <img
                                    className="icoimgsoc"
                                    src={youtube}
                                    alt="youTube"
                                />
                            </Link>

                            <Link className="" to="">
                                <img
                                    className="icoimgsoc"
                                    src={twitter}
                                    alt="twitterX"
                                />
                            </Link>

                        </div> */}
                    </div>
                    {/* <div className=" col-lg-6 col-12 OurChairman_Detaildiv">
                        <h1>MUHAMMAD ASIF QURESHI</h1>
                        <h6>CHAIRMAN & CEO.</h6>
                        <p></p>
                        <p></p>
                        <p>30 yrs of CEO experience managing diversified Groups. Founded/Co-founded several businesses in the fields of Construction & Engineering, MAQ Agri, and Environmental Solutions. Serves on the board of a diverse set of organizations.</p>
                        <p>CPA with strong background in Assurance & Advisory and M&A practices at Big 4 firms in the Dubai.</p>
                        <p>He also holds several advisory positions. He is a graduate from the University of Houston and holds a CPA License.</p>
                    </div> */}

                </div>
            </Box>

            <div className="">
                <Box className='OurDirectors_weworkIn'>
                    <div className="logistic_topdiv">
                        <h1 className=" fw-bold">Board Of Directors</h1>
                    </div>

                    <div className="row gap-4 OurDirectors_alldetail ">

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>WALEE QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>NOOR QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ASHRAF QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ASLAM QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>HAROON QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>IMRAN QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div className="col-lg-4 col-12 linktag_OurDirectors">
                            <div className="OurDirectors_areadiv">
                                <div>
                                    <img src={demo_image} alt="img" />
                                </div>
                                <div className="teaminfo">
                                    <div>
                                        <h6>ABDULLAH QURESHI</h6>
                                        <h6 className="position_director">DIRECTOR</h6>
                                        {/* <p>Director of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p> */}
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>



                </Box>
            </div>
            <MAQFooter />

        </>
    )
}

export default OurChairman