import people1 from '../assest/BuildersDeveloper/mumbai.jpeg'
import people2 from '../assest/BuildersDeveloper/Nashik.jpg'
import people3 from '../assest/BuildersDeveloper/Pune.jpg'
import people4 from '../assest/BuildersDeveloper/lonawla.jpg'


const MaharashtraCityData = [
    {
        id: 1,
        heading: 'Mumbai',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Nasik',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Pune',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Lonawala',
        imgSrc: people4,
    },
    

];
export default MaharashtraCityData