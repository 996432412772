
import { Box, Button, Divider, List, ListItemText } from "@mui/material"
import imgeLogo from '../../../assest/MAQlogo.jpeg'
import { FaSquareTwitter, FaLinkedin, FaSquareInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { GoBell } from 'react-icons/go';
import { IoLogoWechat } from 'react-icons/io5';
import './RealEstateFooterStyle.css'

const RealEstateFooter = () => {
    return (
        <>
            <section id="contact">
                <div className="RealEstateFooter">
                    <Box className='boxmain'>
                        <Box>
                            <List className="listBox_first">
                                <ListItemText className='ListItemText'>
                                    <img className='imglogo' src={imgeLogo} alt="logo-img" />
                                </ListItemText>
                                <ListItemText className='ListItemText_heading'><span>MAQ THE WORLD BUILDER & DEVELOPER</span></ListItemText>
                                <ListItemText className='ListItemText'>
                                    <Button>SELL YOUR PROPERTY</Button>
                                </ListItemText>
                                <ListItemText className='ListItemText'>
                                    <Button>BUY A PROPERTY</Button>
                                </ListItemText>

                            </List>
                        </Box>
                        <Box>
                            <List className="listBox">
                                <ListItemText className='ListItemText'><b style={{ fontSize: '36px' }}>  Contact</b></ListItemText>
                                <ListItemText className='ListItemText'>
                                    <div className="address">
                                        <h5>Main Office</h5>
                                        <p>98B, 2nd Floor, Namberdar Estate, New Friends Colony, New Delhi</p>
                                    </div>
                                </ListItemText>
                                <ListItemText className='ListItemText'><span>Tel: 8851746286, 8851746286</span></ListItemText>
                                <ListItemText className='ListItemText'><span>Email: abcdefrg@gmail.com</span></ListItemText>
                                <ListItemText className='ListItemText'><span>Social: @abarisoftech</span></ListItemText>
                            </List>
                        </Box>


                        <Box>
                            <List className="listBox">
                                <Box className='pt-2'>
                                    <FaSquareInstagram className="iconsocial" />
                                    <FaFacebookSquare className="iconsocial" />
                                    <FaLinkedin className="iconsocial" />
                                    <FaSquareTwitter className="iconsocial" />
                                </Box>
                                <ListItemText className='ListItemText'>
                                    <div className="address">
                                        <h5>Branch Office</h5>
                                        <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                                    </div>
                                </ListItemText>

                            </List>
                        </Box>

                    </Box>
                    <Divider sx={{ border: '1px solid white' }} />
                    <Box sx={{ display: 'flex', p: '50px 0', color: 'white', justifyContent: 'center' }}>
                        <div>
                            <p style={{ fontSize: '14px', textAlign: 'center' }}>Copyright © 2024 Abaris Real Estate. All Rights Reserved</p>
                        </div>
                    </Box>
                </div>
            </section>

        </>
    )
}

export default RealEstateFooter

