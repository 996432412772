import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import contactus from '../../assest/contactus.webp'
import '../../styles/ngoContactUsStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";


const ContactUs = () => {

    const defalutValue = {
        FullName: '',
        EmailID: '',
        EnterSubject: '',
        ContactNumber: '',
        EnterMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Full Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        EnterSubject: yup.string().required('Enter Subject is Requird!'),
        ContactNumber: yup.string().required('Contact Number is Requird!'),
        EnterMessage: yup.string().required('Enter Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    return (
        <>
            <NGOTrustHeader />

            <Box className="contact_topDiv">
                <img src={contactus} alt="img" />
            </Box>

            <div className=" d-flex justify-content-center my-3">
                <div className="col-12 col-lg-6">
                    <div className="card mb-4">
                        <div className="welcomeDiv">
                            <h1 className="ps-2">Get in <span>Touch</span></h1>
                        </div>

                        <div className="card-body row">
                            <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                {({ errors, touched, resetForm }) => {
                                    return <Form className="row formdiv">

                                        <div className="col-lg-6 col-12">

                                            <Field type='text' name='FullName' placeholder='Full Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>

                                        <div className="col-lg-6 col-12">

                                            <Field type='text' name='EmailID' placeholder='Email ID'
                                                className={
                                                    `form-control
                                                    ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">

                                            <Field type='text' name='EnterSubject' placeholder='Enter Subject'
                                                className={
                                                    `form-control
                                                    ${errors.EnterSubject && touched.EnterSubject ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterSubject" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">

                                            <Field type='text' name='ContactNumber' placeholder='Contact Number'
                                                className={
                                                    `form-control
                                                    ${errors.ContactNumber && touched.ContactNumber ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="ContactNumber" /></p>
                                        </div>

                                        <div className="">

                                            <Field as={'textarea'} rows={6} type='text' name='EnterMessage' placeholder='Enter Message'
                                                className={
                                                    `form-control
                                                    ${errors.EnterMessage && touched.EnterMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterMessage" /></p>
                                        </div>





                                        <div className="d-flex">
                                            <button type="submit" className="btn btn-success" style={{ margin: "20px 0" }}>
                                                Send Message
                                            </button>
                                            <button type="button" className="btn btn-success" onClick={resetForm} style={{ margin: "20px 10px" }}>
                                                Reset
                                            </button>
                                        </div>

                                    </Form>
                                }}
                            </Formik>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row justify-content-center m-4">
                <div className=" col-lg-6 col-12">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4316905546707!2d77.25713517374892!3d28.55679698751912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1719229741175!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </div>
                <div className=" col-lg-6 col-12">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4316905546707!2d77.25713517374892!3d28.55679698751912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1719229741175!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </div>
            </div>

            <div className="">
                <div className="welcomeDiv smallscrenpadding">
                    <h1 className="ps-2">Office <span>Address</span></h1>
                </div>
                <div className="row gap-4 justify-content-center">

                    <div className="col-lg-3 col-12 office_location">
                        <h4>Our Head Office</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-12 office_location">
                        <h4>Ahmedabad Office</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-12 office_location">
                        <h4>Ahmedabad Office</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>
                    </div>


                    <div className="col-lg-3 col-12 office_location">
                        <h4>Our Head Office</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-3 col-12 office_location">
                        <h4>Our Head Office</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 col-12 office_location">
                        <h4>Write Us On</h4>
                        <div className="contactdiv_location">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    info@MAQNGOTrust.org
                                </p>
                            </div>

                        </div>


                    </div>
                </div>

            </div>

            <div className=" text-center fw-bold m-5 fs-5 py-3">
                We Work PAN India with our team presence in Hyderabad, Indore, Bhopal, Jaipur, Kolkata, Chennai, Lucknow, Guwahati, Patna, Aurangabad, Gurgaon, Noida, Ghaziabad and many more cities. For details please write or call us in our head office.
            </div>

            <NGOTrustFooter />
        </>
    )
}

export default ContactUs