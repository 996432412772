
import featured1 from '../assest/maqworld/featured1.jpg'
import featured2 from '../assest/maqworld/featured2.jpg'
import featured3 from '../assest/maqworld/featured3.jpg'
import featured4 from '../assest/maqworld/featured4.jpg'
import featured5 from '../assest/maqworld/featured5.jpg'
import featured6 from '../assest/maqworld/featured6.jpg'



const MaqFeaturedProjectData = [
    {
        id: 1,
        companyName:'MAQ THE WORLD GROUP Projects',
        projectName:'Dunya Tower at Down Town Dubai',
        imgSrc: featured1,
    },
    {
        id: 2,
        companyName:'MAQ THE WORLD GROUP Projects',
        projectName:'Ajman Marriot Hotel',
        imgSrc: featured2,
    },
    {
        id: 3,
        companyName:'MAQ THE WORLD GROUP Projects',
        projectName:'Ajman Academy School',
        imgSrc: featured3,
    }, {
        id: 4,
        companyName:'MAQ THE WORLD GROUP Projects ',
        projectName:'Sharjah Waterfront Development',
        imgSrc: featured4,
    }, {
        id: 5,
        companyName:'MAQ THE WORLD GROUP Projects ',
        projectName:'Akoya Villas',
        imgSrc: featured5,
    }, {
        id: 6,
        companyName:'MAQ THE WORLD GROUP Projects ',
        projectName:'Ibis Hotel Project',
        imgSrc: featured6,
    }
];
export default MaqFeaturedProjectData