import img1 from '../assest/wilmar/caster.webp'
import img2 from '../assest/wilmar/oleochemical.webp'
import img3 from '../assest/wilmar/lecithin.webp'




const AboutIndustrialEssentialData = [
    {
        id: 1,
        heading:'Castor',
        imgSrc: img1,
    },
    {
        id: 2,
        heading:'Oleo Chemical',
        imgSrc: img2,
    },
    {
        id: 3,
        heading:'Lecithin',
        imgSrc: img3,
    }
    
];
export default AboutIndustrialEssentialData