
import people1 from '../assest/BuildersDeveloper/Indore.jpg'
import people2 from '../assest/BuildersDeveloper/Bhopal.jpg'
import people3 from '../assest/BuildersDeveloper/Gwalior.jpg'
import people4 from '../assest/BuildersDeveloper/Mhow.jpg'


const MadhyaPradeshCityData = [
    {
        id: 1,
        heading: 'Indore',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Bhopal',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Gwalior',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Mhow',
        imgSrc: people4,
    },
    

];
export default MadhyaPradeshCityData