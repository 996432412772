import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import OurServiceData from "../../data/OurServiceData"
import '../../styles/ourServicesStyle.css'
import { Box } from "@mui/material"
const OurServices = () => {
    return (
        <>

            <MAQHeader />

            <Box className='mainbox-service'>
                <h1>Our Services</h1>
                <div className="all-service">
                    {OurServiceData.map((item) => {
                        return (
                            <div className="the-services">
                                <img src={item.imgSrc} alt="img" />
                                <h6 className=" fw-bold fs-3 my-4">{item.department}</h6>
                                <p>{item.description}</p>
                            </div>
                        )
                    })}
                </div>
            </Box>
            <MAQFooter />
        </>
    )
}

export default OurServices
