
import people1 from '../assest/importExport/Surveillance System.jpg'
import people2 from '../assest/importExport/multimedia.jpg'
import people3 from '../assest/importExport/Interactive Home.jpg'
import people4 from '../assest/importExport/homesecurity-inteligent.jpg'
import people5 from '../assest/importExport/Energy Efficiency.jpg'
import people6 from '../assest/importExport/Protection.jpg'
import people7 from '../assest/importExport/Climate Control.jpg'
import people8 from '../assest/importExport/smart-litinging.jpg'






const HomeAutomationData = [
    {
        id: 1,
        heading: 'Surveillance System ',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Multimedia ',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Interactive Home ',
        imgSrc: people3,
    },

    {
        id: 4,
        heading: 'Intelligent Home Security ',
        imgSrc: people4,
    },
    {
        id: 5,
        heading: 'Energy Efficiency',
        imgSrc: people5,
    }, {
        id: 6,
        heading: 'Protection ',
        imgSrc: people6,
    }, {
        id: 7,
        heading: 'Climate Control',
        imgSrc: people7,
    }, {
        id: 8,
        heading: 'Smart Lighting',
        imgSrc: people8,
    }
];
export default HomeAutomationData