import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import plantingTrees from '../../assest/planting-trees.webp'
import img56517 from '../../assest/56517.webp'
import aokigaharaforest from '../../assest/aokigahara-forest.webp'
import urbanplantations from '../../assest/urbanplantations.webp'
import img8086160 from '../../assest/8086160.webp'
import img6432892 from '../../assest/6432892.webp'
import '../../styles/PedhLagaoStyle.css'

const PedhLagao = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={plantingTrees} alt="img" />
                <h1 className="h1donate text-center">Pedh Lagao पेड़ लगाओ</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Pedh Lagao <span>पेड़ लगाओ</span> </h1>
                </div>

                <div className="aboutus_div text-center">
                    <p className=" fs-4">Pedh Lagao पेड़ लगाओ is dedicated to planting #10CroreTREESby2025 across India in and around metropolitan cities, for a green, pollution-free and healthy environment. We have saved, protected and planted millions of trees with over 90% survival rate through our various types of plantations - Urban, Rural Livelihood And ‘MASS TREE PLANTATION’.</p>
                    <h4>We regularly maintain and help our planted saplings to become mature trees.</h4>
                </div>

            </div>

            <div className="pedhlagao_div">
                <h3>URBAN <span>PLANTATIONS</span></h3>
                <div className="">
                    <h5>Miyawaki Forest</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={aokigaharaforest} alt="img" />

                            </div>
                            <div>
                                <p className="">With India's rapidly growing population and shrinking cities converting into concrete jungles, the need for clean air is urgent. We need a solution which can bring exceptional results in less time and space. Miyawaki urban forests are the answer.</p>
                                <p>Forests grown under the Miyawaki method grow 10 times faster, 30 times denser and 100 times more biodiverse compared to a monoculture plantation By producing forests that are also 30 times better at carbon dioxide absorption and better noise, dust reduction and surface area, we get a 100 native, 100 organic and 100 year old forest in just 10 years</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <h5>Urban Conventional Plantations</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={urbanplantations} alt="img" />

                            </div>
                            <div>
                                <p>In the effort to make cities green we try to take up any opportunity presented to plant trees on protected landscapes such as housing societies, schools and college campuses, public/government gardens, and grounds. Thereby increase greenery, fresh air and habitats for fauna to benefit local communities.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pedhlagao_div">
                <h3>RURAL LIVELIHOOD PLANTATION <span>EMPOWERING FARMERS</span></h3>
                <div className="">
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={img8086160} alt="img" />

                            </div>
                            <div>
                                <p>70% of rural households depend primarily on farming for their livelihoods. However our farmers are struggling and are having to move to urban areas to find a source of income. Through our initiative we are planting crores of fruit trees in the outskirts of Metro cities with a motto to stop farmers’ migration. We support thousands of farmers, their families and tribal communities to generate sustainable livelihoods. The Initiative helps them by providing the best quality of fruit tree (Cocount Jackfruits, Chikoo, Mango, etc.) saplings and commercial plantation (bamboo, lemon, pomegranate), soil, soil testing, training for innovative plantation methods and maintenance tips for fast and healthy growth of saplings.</p>
                                <p>Under this project we identify villages near metro cities and plant native fruits trees in the farmers’ available land to create a sustainable livelihood for them. It is not just a tree plantation but an opportunity to create A Happy, Green Village with Sustainable Livelihoods to hundreds of underprivileged families. It will have a long lasting impact on many generations of needy families along with saving the environment.</p>
                                <h6>We would like to see people more aware of where their food comes from. We would like to see small farmers empowered.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pedhlagao_div">
                <h3>MASS TREE <span>PLANTATION</span></h3>
                <div className="">
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={img6432892} alt="img" />

                            </div>
                            <div>
                                <p>This projects sole objective is to rejuvenate degraded land, create lush greenbelts and forests to neutralize the impact of global warming and climate change. Thereby creating livelihood to local tribal communities and shelter for birds and wild animals. To achieve this goal, we conduct innovative, technical, well-structured and responsible plantations to heal Mother Nature and help local communities who earn their livelihood from the forest.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">PLANTATION <span>FAQS</span> </h1>
                </div>

                <div className=" row justify-content-center aboutus_div">
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>What trees do you plant?</h5>
                        <p>We plant different kinds of fruit and avenue, evergreen trees as per the requirements depending on the site and purpose of plantation.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>What services do you provide?</h5>
                        <p>We start by finding the right location as per your requirement. We arrange all required resources including saplings, pits digging, tools required, water arrangement, labour first aid kit, providing consultation etc. All you need to do is bring your volunteers and do the plantation.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>Where will the trees be planted?</h5>
                        <p>MAQ NGO MAQ THE WORLD NGO TRUST plants saplings in a location only if we feel that the sapling will be safe and will grow to become trees. Proper care is taken to identify potential areas for planting saplings, Local and government agencies are consulted. Proper approvals are sought concerned agencies before any plantation.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>How will the plantations be maintained?</h5>
                        <p>We ensure the survival and maintenance of the planted saplings until they are self sustainable. We also keep watch until they become mature trees.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>When do you conduct plantations?</h5>
                        <p>We conduct plantations throughout the year.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>Where do you conduct plantations?</h5>
                        <p>We work PAN India as per the project requirements. Our major operations are Mumbai, Pune, Delhi Gurgaon, Noida Ghaziabad, Bangalore, Ahmedabad, Jaipur, Lucknow, Indore, Bhopal, Hyderabad, Kolkata, Chennai etc.</p>
                    </div>
                    <div className="faq_pedh col-lg-5 col-12">
                        <h5>How many trees do you plant?</h5>
                        <p>We plant a minimum of 50 trees depending on the city and location, maximum is up to you!</p>
                    </div>
                </div>

            </div>

            <NGOTrustFooter />

        </>
    )
}

export default PedhLagao