
import people1 from '../assest/BuildersDeveloper/Patna.jpg'
import people2 from '../assest/BuildersDeveloper/Bhagalpur.jpg'
import people3 from '../assest/BuildersDeveloper/Darbhanga.jpg'
import people4 from '../assest/BuildersDeveloper/Gaya.jpg'


const BiharCityData = [
    {
        id: 1,
        heading: 'Patna',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Bhagalpur',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Darbhanga',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Gaya',
        imgSrc: people4,
    },
    

];
export default BiharCityData