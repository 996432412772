import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import polygon from '../../assest/bgimg.webp'
import { Box, Button } from "@mui/material"
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx"
import InternationalSchoolSlider from "../../common/InternationalSchoolSlider/InternationalSchoolSlider"
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import WilmareTopSliderData from "../../data/WilmareTopSliderData"
import numberone from '../../assest/wilmar/number-one.png'
import supplychain from '../../assest/wilmar/supply-chain-management.png'
import store from '../../assest/wilmar/store.png'
import addconnection from '../../assest/wilmar/add-connection.png'
import factoryimg from '../../assest/inframart/factory.png'
import '../../styles/MAQWilmarHomeStyle.css'
import WilmarAboutOilData from "../../data/WilmarAboutOilData"
import WilmarAboutFoodFMCGData from "../../data/WilmarAboutFoodFMCGData"
import AboutIndustrialEssentialData from "../../data/AboutIndustrialEssentialData"

const MAQWilmarHome = () => {
    const data = [
        {
            id: 1,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        },
        {
            id: 2,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        },
        {
            id: 3,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        },
        {
            id: 4,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        },
        {
            id: 5,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        },
        {
            id: 6,
            name: "Alife, Khoobsurati ka jaadu",
            review: " Alife, Khoobsurati ka jaadu",
        }
    ];
    return (
        <>
            <MAQHeader />
            <Box className='Wilmarmain_topdiv'>
                <img src={polygon} alt="img" />
                <div className="Wilmarmain_head">
                    <h1>MAQ THE WORLD Wilmar</h1>
                    <p>We offer an extensive array of edible oil products, including soyabean oil, palm oil, sunflower oil, rice bran oil, mustard oil, groundnut oil, cottonseed oil, blended oil, vanaspati and specialty fats.</p>
                </div>
            </Box>

            <TopSliderAllMax images={WilmareTopSliderData} />

            <Box className='wilmar_div'>
                <div className="wilmar_topdiv">
                    <h1>Milestones at a glance</h1>
                </div>
                <div className="OurStrength">
                    <div className="wilmar_rowdetail">
                        <div className="wilmar_col12 p-0">
                            <div className="our_wilmardiv">
                                <div className="iconimgdiv">
                                    <img src={numberone} alt="img" />
                                </div>
                                <div>
                                    <h4>#1</h4>
                                    <h5>Market leader in edible oil</h5>
                                </div>
                            </div>
                        </div>


                        <div className="wilmar_col12 p-0">
                            <div className="our_wilmardiv">
                                <div className="iconimgdiv">
                                    <img src={supplychain} alt="img" />
                                </div>
                                <div>
                                    <h4>Top 10</h4>
                                    <h5>Consumer FMCG companies in India</h5>
                                </div>
                            </div>
                        </div>

                        <div className="wilmar_col12 p-0">
                            <div className="our_wilmardiv">
                                <div className="iconimgdiv">
                                    <img src={factoryimg} alt="img" />
                                </div>
                                <div>
                                    <h4>23</h4>
                                    <h5>Manufacturing units</h5>
                                </div>
                            </div>

                        </div>

                        <div className="wilmar_col12 p-0">
                            <div className="our_wilmardiv">
                                <div className="iconimgdiv">
                                    <img src={store} alt="img" />
                                </div>
                                <div>
                                    <h4>118 mn+</h4>
                                    <h5>Households reach</h5>
                                </div>
                            </div>

                        </div>

                        <div className="wilmar_col12 p-0">
                            <div className="our_wilmardiv">
                                <div className="iconimgdiv">
                                    <img src={addconnection} alt="img" />
                                </div>
                                <div>
                                    <h4>10700+</h4>
                                    <h5>Distributors network</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <Box className='wilmar_culmdiv'>
                <div className="wilmare_cocurricular">
                    <h2>About Edible Oil</h2>
                    <p>We offer an extensive array of edible oil products, including soyabean oil, palm oil, sunflower oil, rice bran oil, mustard oil, groundnut oil, cottonseed oil, blended oil, vanaspati and specialty fats.</p>
                    <Button className="">Read More</Button>
                </div>

                <InternationalSchoolSlider detailsData={WilmarAboutOilData} />

            </Box>

            <Box className='wilmar_culmdiv bgcolorofthis'>
                <div className="wilmare_cocurricular">
                    <h2>About Food and FMCG</h2>
                    <p>In the fiscal year 2013, we forayed into food products with a focus on staple foods. We offer a variety of packaged staple foods, including wheat flour, rice, besan and pulses. Many of the staple foods we offer include different variants.</p>
                    <Button className="">Read More</Button>
                </div>

                <InternationalSchoolSlider detailsData={WilmarAboutFoodFMCGData} />

            </Box>

            <Box className='wilmar_culmdiv'>
                <div className="wilmare_cocurricular">
                    <h2>About Industrial Essential</h2>
                    <p>Oleochemicals, including stearic acids, soap noodles, palmitic acid, oleic acid and glycerin, which are primary ingredients for home and personal care products, including soaps, detergents, cosmetics, polymer, pharmaceuticals and industrial rubber</p>
                    <Button className="">Read More</Button>
                </div>

                <InternationalSchoolSlider detailsData={AboutIndustrialEssentialData} />

            </Box>

            <section id="wilmar_testimonial">
                <h2 className="wilmar_h2">Newsroom</h2>
                <Swiper
                    centeredSlides={false}
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="container wilmar_testimonial_container"
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                        },
                        // when window width is >= 1024px
                        1024: {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {data.map(({ id, name, review, image }) => {
                        return (
                            <SwiperSlide key={id} className="wilmar_testimonial_slide">
                                <div className="testimonial_div_wilmar">
                                    <div className="img_div_avatar">
                                        <iframe
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/i1HjJda3FvI"
                                            title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                                        >
                                        </iframe>
                                    </div>
                                    <div className="">
                                        <h5 className="wilmar_name"> {name}</h5>
                                        <p className="wilmar_message"> {review}</p>
                                        <Button className="">Watch Now</Button>

                                    </div>
                                </div>

                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </section>
            <MAQFooter />

        </>
    )
}

export default MAQWilmarHome