import React, { useState } from 'react'
import Logo from "../../../assest/MAQlogo.jpeg";
import "./MAQHeaderStyle.css";
import { Box, Button, Drawer } from '@mui/material';
import { MdOutlineMenu } from "react-icons/md";
import { Link } from 'react-router-dom';
import SideBar from '../../realEstate/sidebar/SideBar';
import marxEventLogo from '../../../assest/MAQlogo.jpeg'


const MAQHeader = () => {
    const [navActive, setNavActive] = useState("/");

    const [openSide, setOpenSide] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpenSide(newOpen);
    };

    const openNewTab = (url) => {
        window.open(url, '_blank');
    };


    return (
        <>
            <Box className="MAQHeader">
                <div className='MAQHeader_mobile'>
                    <Button onClick={toggleDrawer(true)}><MdOutlineMenu className=' fs-1textwhite' /></Button>
                    <Drawer open={openSide} onClose={toggleDrawer(false)}>
                        <SideBar setOpenSide={setOpenSide} />
                    </Drawer>
                </div>
                <Link to="/"
                    className={navActive === "/" ? "active" : ""}
                    onClick={() => setNavActive("/")}
                >
                    <img className="logo" src={Logo} alt='Logo' />
                    <span className='compnyName'>MAQ THE WORLD GROUP</span>
                </Link>

                <Box className='MAQHeader_rightUL'>
                    <ul className="MAQHeader_liauto">
                        <li>
                            <Link
                                onClick={() => setNavActive("/")}
                                className={navActive === "/" ? "active" : ""}
                                to="/"
                            >
                                Home
                            </Link>
                        </li>
                        <li className='nav_list'>
                            <Link>Businesses</Link>
                            <Box className='hideSolution'>
                                <div className='emptydiv'></div>
                                <ul className='ul_div'>
                                <li>
                                        <p
                                            onClick={() => openNewTab('/maq_capital_finance')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Capital Finance
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_import_export')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Import & Export
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_infrastructure')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Infrastructure
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_builder_and_developer')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Builders & Developers
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_infra_mart')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World InfraMart
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://maqfoodstuff.com/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Food Stuff
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://www.onetouchautomation.co/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Hyper Market
                                        </p>
                                    </li>
                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('https://www.onetouchautomation.co/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Mobile Mart
                                        </p>
                                    </li> */}

                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('/maq_hospital')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Hospitals
                                        </p>
                                    </li> */}

                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('/maq_university')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World University
                                        </p>
                                    </li> */}

                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('/maq_international_schools')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World International Schools
                                        </p>
                                    </li> */}

                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://crockery.maqtheworldgroup.com/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Crockery
                                        </p>
                                    </li>

                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_hospital')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Healthcare
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://cosmetics.maqtheworldgroup.com/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Cosmetics
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://fashions.maqtheworldgroup.com')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Fashions
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_marine_logistics')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Marine Logistics
                                        </p>
                                    </li>

                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_agri_logistic')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Agri Logistics
                                        </p>
                                    </li>
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_shipping_all_world')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Shipping
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_solar_power')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Solar Power
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_architecture')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Architecture
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/MIS_furniture_decorators')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                             MAQ The World MIS Furniture & Decorators
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/MIS_furniture_decorators')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Interiors & Decorators
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/al_maq_tour_travel')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            Al MAQ The World Tour & Travels
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World International Payment Gateway
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/NGO_trust')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World NGO Trust
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/restaurant')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Franchise
                                        </p>
                                    </li>
                                   
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://ayurvedic.maqtheworldgroup.com')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Ayurveda
                                        </p>
                                    </li>
                                  
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://projects.maqtheworldgroup.com/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Projects
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://b2cfintech.maqtheworldgroup.com/')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Fintech
                                        </p>
                                    </li>
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_wilmar')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Wilmar
                                        </p>
                                    </li>
                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('/maq_defence')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Defence
                                        </p>
                                    </li> */}
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq_green_energy')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Green Energy
                                        </p>
                                    </li>

                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://fertilizers.maqtheworldgroup.com')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World FERTILIZERS
                                        </p>
                                    </li>

                                    <li>
                                        <p
                                            onClick={() => openNewTab('https://electronics.maqtheworldgroup.com')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World ELECTRONICS
                                        </p>
                                    </li>


                                    
                                   
                                   
                                    {/* <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Marriage
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Martial Arts
                                        </p>
                                    </li>
                                    
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Realty
                                        </p>
                                    </li>
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Data Centre
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Power
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Hotels
                                        </p>
                                    </li>
                                   
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Port
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Logistics
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Total Gas
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Transmission
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Cement
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Airport
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Corporate
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Solar
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Agri Fresh
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Natural Resources
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Ganga Expressway
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ Bhagalpur West Power
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ SuperMarket
                                        </p>
                                    </li>
                                    <li>
                                        <p
                                            onClick={() => openNewTab('/maq')}
                                        >
                                            <img
                                                className="me-2"
                                                src={marxEventLogo}
                                                alt="logo"
                                                style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                            />
                                            MAQ The World Mall
                                        </p>
                                    </li> */}
                                   
                                    

                                </ul>
                            </Box>
                        </li>

                        <li className='nav_list'>
                            <Link>Our Group</Link>
                            <Box className='hidegroup'>
                                <ul className='OurGroup_ul'>
                                    <li> <Link> Who We Are</Link></li>
                                    <li> <Link to={'/Our_Chairman'}> Our Chairman</Link></li>
                                    <li> <Link to={'/Our_Directors'}> Our Directors</Link></li>
                                </ul>
                            </Box>
                        </li>
                        {/* <li>
                            <Link
                                to="/about"
                                onClick={() => setNavActive("/about")}
                                className={navActive === "/about" ? "active" : ""}
                            >
                                About
                            </Link>
                        </li> */}


                        <li>
                            <Link
                                to="/testimonials"
                                onClick={() => setNavActive("/testimonials")}
                                className={navActive === "/testimonials" ? "active" : ""}
                            >
                                Testimonials
                            </Link>
                        </li>
                        <li>
                            <Link
                                onClick={() => setNavActive("/contactus")}
                                className={navActive === "/contactus" ? "active" : ""}
                                to="/contactus"
                            >
                                Contact
                            </Link>
                        </li>
                    </ul>
                </Box>
            </Box>
        </>
    )
}

export default MAQHeader