import people1 from '../assest/BuildersDeveloper/Visakhapatnam.jpg'
import people2 from '../assest/BuildersDeveloper/Vijayawada.jpg'
import people3 from '../assest/BuildersDeveloper/Tirupati.jpg'
import people4 from '../assest/BuildersDeveloper/Kurnool.jpg'


const AndhraPradeshCityData = [
    {
        id: 1,
        heading: 'Visakhapatnam',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Vijayawada',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Tirupati',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Kurnool',
        imgSrc: people4,
    },
    

];
export default AndhraPradeshCityData