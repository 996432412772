import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import Slide1 from "../../assest/agriLogistics/bg-1.0d5f0602.jpg";
import Slide2 from "../../assest/agriLogistics/bg-2.e3fc870c.jpg";
import Slide3 from "../../assest/agriLogistics/bg-5.14b76606.jpg";
import '../../styles/MAQAgriLogisticHomeStyle.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AgriLogisticOurJourneyData from "../../data/AgriLogisticOurJourneyData";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import download1 from "../../assest/agriLogistics/download1.png";
import download2 from "../../assest/agriLogistics/download2.png";
import download3 from "../../assest/agriLogistics/download3.png";
import number1 from "../../assest/agriLogistics/number-1.png";
import number2 from "../../assest/agriLogistics/number-2.png";
import number3 from "../../assest/agriLogistics/number-3.png";
import number4 from "../../assest/agriLogistics/number-4.png";
import ourservice1 from "../../assest/agriLogistics/ourservice1.png";
import ourservice2 from "../../assest/agriLogistics/ourservice2.png";
import ourservice3 from "../../assest/agriLogistics/ourservice3.png";




const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  strong {
    font-size: 1.25em;
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 50px;
    }
    .main p{
      font-size: 24px;
       margin: 20px 20% 0 20%;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 12rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
      .main p{
      font-size: 14px;
       margin: 20px;

    }
    }
  }
`;


const data = [
    {
        id: 1,
        heading: 'MAQ THE WORLD AGRI-LOGISTICS',
        paraText: 'Facilitating access to reliable & affordable agri-commodity logistics solutions.',
        image: Slide1
    },
    {
        id: 2,
        heading: 'REDEFINE',
        paraText: "A Collaborative value chain approach to facilitate accessibility to markets, inputs & value-added services",
        image: Slide2

    },
    {
        id: 3,
        heading: 'RELAY',
        paraText: 'A lean Trucking Model designed to enhance productivity and strengthen livelihoods of our community of Trucking-Pilots.',
        image: Slide3
    }

];



const MAQAgriLogisticHome = () => {

    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 4,
                },
            }
        ],
    };
    return (
        <>
            <MAQHeader />

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {data.map(({ id, image, paraText, heading }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom-img">
                                    <img src={image} alt="img" />
                                    <MyTitleMessage>
                                        <div className="titleMessage">
                                            <div className="heading">
                                                <div className="main text-center mb-3">
                                                    <span>
                                                        <strong style={{ textTransform: 'uppercase' }}>{heading}</strong>
                                                    </span>
                                                    <p>{paraText}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </MyTitleMessage>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <Box className='agriLogistic_div bg-body-secondary'>
                <div className="agriLogistic_topdiv">
                    <h1>Who Are We?</h1>
                    <p>MAQ THE WORLD AGRI LOGISTICS is a Tech-Enabled Agri-Commodity Aggregator, Professionalizing Agri-Commodity Logistics to facilitate Accessibility to reliable & affordable Agri-Commodity logistics solutions for geographically specialized farmers & post-harvest value chain players. As such, MAQ THE WORLD AGRI LOGISTICS is providing Agricultural Commodity Courier, Trucking & Warehousing Services.</p>
                </div>
                <div className="OurStrength">
                    <h4>About</h4>
                    <div className="about_rowdetail">
                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={download1} alt="img" />
                                </div>
                                <div>
                                    <h5>REDEFINE</h5>
                                    <p>A Collaborative value chain approach to facilitate accessibility to markets, inputs & value-added services.</p>
                                </div>


                            </div>
                        </div>


                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={download2} alt="img" />
                                </div>
                                <div>
                                    <h5>RELAY</h5>
                                    <p>A lean Trucking Model designed to enhance productivity and strengthen livelihoods of our community of Trucking-Pilots.</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={download3} alt="img" />
                                </div>
                                <div>
                                    <h5>REIMAGINE</h5>
                                    <p>Digitalizing the food supply chain to transform Uganda's Agri-commodity logistics industry.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <Box className='agriLogistic_div bg-success-subtle'>
                <div className="agriLogistic_topdiv">
                    <h1>Our Process</h1>
                    <p>MAQ THE WORLD AGRI LOGISTICS takes keen interest in continuous process improvement to maintain quality service standards through constant operations monitoring and routine audits of service offerings. Hence once an order is placed, MAQ THE WORLD AGRI LOGISTICS will ensure that the following are done:</p>
                </div>
                <div className="OurStrength">
                    <div className="about_rowdetail">
                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={number1} alt="img" />
                                </div>
                                <div>
                                    <h5>Initiate</h5>
                                    <p>Call our customer support contact.</p>
                                </div>


                            </div>
                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={number2} alt="img" />
                                </div>
                                <div>
                                    <h5>Confirm</h5>
                                    <p>Recieve shipment delivery itinerary for respective agri-commodity shipment.</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={number3} alt="img" />
                                </div>
                                <div>
                                    <h5>Track</h5>
                                    <p>Realtime notifications via email/text message/whatsApp and a customer support specialist assigned for status resporting & queries clarification​</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={number4} alt="img" />
                                </div>
                                <div>
                                    <h5>Deliver</h5>
                                    <p>Delivery notifications via email/text message/whatsApp & e-POD signed.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <Box className='agriLogistic_div bg-primary-subtle'>
                <div className="agriLogistic_topdiv">
                    <h1>Our Services</h1>
                </div>
                <div className="OurStrength">
                    <div className="about_rowdetail">
                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ourservice1} alt="img" />
                                </div>
                                <div>
                                    <h5>AGRI-COURIER</h5>
                                    <p>Last mile connectivity and distribution from suppliers such as input dealers to remote smallholder farmers.</p>
                                </div>


                            </div>
                        </div>


                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ourservice2} alt="img" />
                                </div>
                                <div>
                                    <h5>AGRI-TRUCKING</h5>
                                    <p>Low cost & standardized trucking solutions that permit on-time pickup and quality delivery of Agri-commodity shipments.​</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ourservice3} alt="img" />
                                </div>
                                <div>
                                    <h5>AGRI-WAREHOUSING</h5>
                                    <p>Eco-friendly and flexible agri-commodity storage services for quality assurance & effective order management from receipt to delivery.</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <Box className='logistic_clients '>
                <h1>Our Journey</h1>
                <div className="our_partner">
                    {/* <h1 className='fw-bold ms-3 text-white'>{title}</h1> */}
                    <Slider {...settings}>
                        {AgriLogisticOurJourneyData?.map((item, index) => (
                            <div key={item.id} className="Projects_topimg">
                                <img style={{ width: '150px', height: '100px', objectFit: 'contain' }} src={item.imgSrc} alt={`img-${item.id}`} />
                                <p>{item.valuetext}</p>
                                <p className=" fw-normal">{item.name}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Box>

            <div className="bg-primary-subtle Contactsubtlediv">
                <div className="agriLogistic_topdiv">
                    <h6>Contact</h6>
                    <h1 className=" fw-bold">Get In Touch</h1>
                    <p>For any inquiries, questions or recommendations, please write to us.</p>
                </div>

                <div className="row justify-content-between">

                    <div className=" col-lg-5 col-12 TouchContact ">
                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong> 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Email ID : </strong> abarisoftech@gmail.com</p>
                            </div>

                        </div>

                        <div className="contactdiv">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 col-12 card-body row justify-content-center">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row rowpaddd">
                                    <div className="row justify-content-center padingdiv">
                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='FullName' placeholder='Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='Phone' placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>


                                        <div className=" mt-2">

                                            <Field type='text' name='EmailID' placeholder='Email'
                                                className={
                                                    `form-control
                                                ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>

                                        <div className=" mt-2">

                                            <Field as={'textarea'} rows={6} name='YourMessage' placeholder='Your Message'
                                                className={
                                                    `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-2">
                                            <button type="submit" className="messagebtn">
                                                Send Message
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>
                </div>
            </div>



            <MAQFooter />

        </>
    )
}

export default MAQAgriLogisticHome
