import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import polygon from '../../assest/Infrastructure/laguardia-terminalb-hero.jpg'
import { Box, Button } from "@mui/material"
import '../../styles/maqInfrastructureHomeStyle.css'
import { FaPrint, FaSquareFacebook, FaSquareTwitter, FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom"
import { MdEmail } from "react-icons/md";
import network_1 from '../../assest/Infrastructure/network_1_0_0.png'
import network_2 from '../../assest/Infrastructure/icon-airplane-teal.png'
import network_3 from '../../assest/Infrastructure/icon-wind-turbine-teal.png'
import laguardiaimg from '../../assest/Infrastructure/laguardia-airport-terminal-b.jpg'
import moynihanimg from '../../assest/Infrastructure/moynihan-train-hall.jpg'
import offshoreimg from '../../assest/Infrastructure/offshore-wind-rendering-sm.jpg'
import FiberHeroimg from '../../assest/Infrastructure/FiberHero.jpg'
import { FaAngleRight } from "react-icons/fa6";
import OpportunitiesGrowBusinessData from "../../data/OpportunitiesGrowBusinessData"
import { GoHorizontalRule } from "react-icons/go";
import CellPhoneimg from '../../assest/Infrastructure/Cell Phone_NYC.jpg'
import linkedinimg from '../../assest/Infrastructure/linkedin.png'
import twitterimg from '../../assest/Infrastructure/twitter.png'
import facebookimg from '../../assest/Infrastructure/facebook.png'
import youtubeimg from '../../assest/Infrastructure/youtube.png'


const MAQInfrastructureHome = () => {
    return (
        <>
            <MAQHeader />

            <Box className='maqInfrastructure_topdiv'>
                <img src={polygon} alt="img" />
                <div className="maqInfrastructure_head">
                    <h1>MAQ The World Infrastructure</h1>
                </div>
            </Box>

            <div className="PrintSharediv">
                <ul>
                    <li><Link>  <FaPrint className="iconsocial" /> </Link></li>
                    <li><Link>  <MdEmail className="iconsocial" /> </Link></li>
                    <li><Link>  <FaSquareFacebook className="iconsocial" /> </Link></li>
                    <li><Link>  <FaSquareTwitter className="iconsocial" /> </Link></li>
                    <li><Link>  <FaLinkedin className="iconsocial" /> </Link></li>
                </ul>
            </div>


            <Box className='futurebusinessdiv'>
                <div className="">
                    <h3 className=" fw-bold">New York State is building the future of business with infrastructure that supports success</h3>
                    <p className="mt-4" style={{ letterSpacing: '1px' }}>The future of business in New York State is buoyed by a talented workforce, world-class innovations and major investments in the essential building blocks of progress: infrastructure and transportation. By making infrastructure and transportation projects a key priority, the State of New York is demonstrating a strong commitment to remaining competitive in an evolving global economy.</p>
                    <p className="mt-4" style={{ letterSpacing: '1px' }}>Find out more about all the ways New York is building the state of the future.</p>
                </div>

                <div className="">
                    <h1 className=" fw-bold py-5 text-center">MAQ THE WORLD INFRASTRUCTURE BY THE NUMBERS</h1>
                    <div className="InfrastructureInvestment">
                        <div className="Infrastructure_col12 p-0">
                            <div className="ourimgdiv_Infrastructure">
                                <div className="imgdiv">
                                    <img src={network_1} alt="img" />
                                </div>
                                <div>
                                    <h5>$1B</h5>
                                    <p>ConnectALL Initiative, the largest-ever NYS digital MAQ THE WORLD INFRASTRUCTURE</p>
                                </div>
                            </div>
                        </div>

                        <div className="Infrastructure_col12 p-0">
                            <div className="ourimgdiv_Infrastructure">
                                <div className="imgdiv">
                                    <img src={network_2} alt="img" />
                                </div>
                                <div>
                                    <h5>$8B</h5>
                                    <p>LaGuardia Airport expansion completed in 2022</p>
                                </div>
                            </div>

                        </div>

                        <div className="Infrastructure_col12 p-0">
                            <div className="ourimgdiv_Infrastructure">
                                <div className="imgdiv">
                                    <img src={network_3} alt="img" />
                                </div>
                                <div>
                                    <h5>70%</h5>
                                    <p>NYS Renewable energy goal by 2030</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <div className="infrastructureprogram">
                <div>
                    <img src={laguardiaimg} alt="img" />
                </div>
                <div className="programtextdiv">
                    <p>The nation's largest infrastructure program builds on major investments that have been made in airports, roads and bridges around New York State.</p>
                </div>
            </div>


            <div className="infrastructureprogram_left">

                <div className="programtextdiv_left">
                    <p>Funding is rehabilitating bridges around the state, and newer construction has redefined the New York landscape. Our airports are being transformed—from LaGuardia and JFK to Plattsburgh, Albany, Syracuse and Rochester—along with new train stations in Rochester and Schenectady. Across from New York City’s Pennsylvania Station, we've transformed the historic James A. Farley Post Office into the 255,000-square-foot Moynihan Train Hall, a 21st century world-class transportation hub.</p>
                </div>
                <div>
                    <img src={moynihanimg} alt="img" />
                </div>
            </div>

            <div className="infrastructureprogram">
                <div>
                    <img src={FiberHeroimg} alt="img" />
                </div>
                <div className="programtextdiv">
                    <p>The ConnectALL Office is working to transform the state’s digital infrastructure, to deliver affordable broadband to  New Yorkers and give them access to the innovation economy.</p>
                </div>
            </div>

            <div className="infrastructureprogram_left">

                <div className="programtextdiv_left">
                    <p>New York's vision for growing its green economy includes additional offshore wind, renewable energy, energy storage and workforce training initiatives. Governor Hochul has announced plans for a $500 million, nation-leading investment in offshore wind; New York is on its way to 9,000 megawatts of offshore wind energy by 2035.</p>
                </div>
                <div>
                    <img src={offshoreimg} alt="img" />
                </div>
            </div>

            <Box className='Explore_morediv'>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-12 Explore_dddd">
                        <div className="Explore_morediv_customize">
                            <div>
                                <h5>Explore our regions</h5>
                                <p>New York’s rich and diverse regions offer a multitude of advantages for business, from world-class cities to transportation hubs to innovation hot spots and regions ideal for manufacturing, tech, R&D, farming and more.</p>
                                <Button className="readbtnbox">Browse by region</Button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 Explore_dddd">
                        <div className="Explore_morediv_customize">
                            <div>
                                <h5>Explore by industry</h5>
                                <p>New York is home to innovative companies from a diverse mix of industries. See how businesses like yours are thriving here.</p>
                                <Button className="readbtnbox">View Industries</Button>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-4 col-12 Explore_dddd">
                        <div className="Explore_morediv_customize">
                            <div>
                                <h5>Why New York State?</h5>
                                <p>In New York State, the future is already here – fueled by the talent and the resources that are leading companies and smart, dynamic, innovative new businesses to start here, move here and thrive here.</p>
                                <Button className="readbtnbox">Learn More</Button>
                            </div>
                        </div>
                    </div>

                </div>

            </Box>

            <Box className='Opportunities_togrow'>
                <h1 className=" fw-bold py-5 text-center">Opportunities to grow your business in New York State</h1>
                <div className="row gap-4 Opportunities_togrow_alldetail ">
                    {OpportunitiesGrowBusinessData?.map((item, index) => (
                        <Link className="col-lg-2 col-12 linktag_Opportunities_togrow" to={'/'}>
                            <div className="Opportunities_togrow_areadiv">
                                <div>
                                    <img src={item.imgSrc} alt="img" />
                                </div>
                                <div>
                                    <h6>{item.heading}</h6>
                                    <p className="linep"><GoHorizontalRule /></p>
                                    <p>{item.textInfo}</p>
                                    <Button className="opportunity_btn" fullWidth> <FaAngleRight className=" me-2" /> Learn More</Button>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>

            </Box>

            <div className="ContactUsToday">
                <div>
                    <img src={CellPhoneimg} alt="img" />
                </div>
                <div className="contacttextdiv">
                    <p>Contact Us Today</p>
                    <p>Connect with our industry experts to find out more about how New York State can help your business.</p>
                    <div className=" input-group">
                        <input type="text" placeholder="Enter your email" />
                        <Button>Submit</Button>
                    </div>
                </div>
            </div>

            <div className="connect_social">
                <h1>Connect</h1>
                <div className=" row justify-content-center">
                    <div className="col-lg-3 col-12 iconimge_social">
                        <Link>
                            <img src={linkedinimg} alt="img" />
                            <h6 className="Linkedin">Linkedin</h6>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-12 iconimge_social">
                        <Link>
                            <img src={twitterimg} alt="img" />
                            <h6 className="Twitter">Twitter</h6>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-12 iconimge_social">
                        <Link>
                            <img src={facebookimg} alt="img" />
                            <h6 className="Facebook">Facebook</h6>
                        </Link>

                    </div>
                    <div className="col-lg-3 col-12 iconimge_social">
                        <Link>
                            <img src={youtubeimg} alt="img" />
                            <h6 className="YouTube">YouTube</h6>
                        </Link>
                    </div>

                </div>
            </div>

            <MAQFooter />

        </>
    )
}

export default MAQInfrastructureHome