import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx"
import polygon from '../../assest/bgimg.webp'
import homemission from '../../assest/inframart/home-mission.jpg'
import homevision from '../../assest/inframart/home-vision.jpg'
import ourvalues from '../../assest/inframart/our-values.jpg'

import { Box, Button, Typography } from "@mui/material"
import '../../styles/MAQInfraMartHomeStyle.css'

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from "react-router-dom"
import { FaLinkedin } from "react-icons/fa";
import InfraMartTopData from "../../data/InfraMartTopData"

import excavatorimg from '../../assest/inframart/excavator.png'
import strategyimg from '../../assest/inframart/strategy.png'
import mapimg from '../../assest/inframart/map.png'
import deliveryimg from '../../assest/inframart/fast-delivery.png'
import factoryimg from '../../assest/inframart/factory.png'
import invesmentimg from '../../assest/inframart/invesment.png'

import b2bimg from '../../assest/inframart/b2b.png'
import retailappimg from '../../assest/inframart/second-hand.png'
import vrglassimg from '../../assest/inframart/vr-glasses.png'
import socialimg from '../../assest/inframart/live-stream.png'
import InfraMartKeyProjectData from "../../data/InfraMartKeyProjectData"
import Shalimarimg from '../../assest/inframart/Shalimar.png'
import rdcimg from '../../assest/inframart/rdc.png'
import equipimg from '../../assest/inframart/equip-collaterals.png'

import member1 from '../../assest/restaurantImg/odiyana.jpg'
import member2 from '../../assest/restaurantImg/people3.jpg'
import forbesimg from '../../assest/inframart/forbes-logo.png'
import constructionimg from '../../assest/inframart/construction-world.png'
import BNIimg from '../../assest/inframart/BNI-logo.png'
import fastergrowingimg from '../../assest/inframart/fastergrowing.png'
import FaqData from "../../data/FaqData"

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react"

const MAQInfraMartHome = () => {

    const [expandedAccordion, setExpandedAccordion] = useState(null);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : null);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 4,
                },
            }
        ],
    };
    return (
        <>
            <MAQHeader />

            <Box className='InfraMart_maintopdiv'>
                <img src={polygon} alt="img" />
                <div className="InfraMartmain_head">
                    <h1>MAQ THE WORLD Infra Mart</h1>
                    <p>MAQ THE WORLD INFRA MART is a one-stop solution for all your construction material requirements. With world class manufacturing units and Innovation Center, we revolutionize construction products from foundation to finish.</p>
                </div>
            </Box>

            <div className="sliderinfratop">
                <TopSliderAllMax images={InfraMartTopData} />
            </div>


            <Box className='InfraMart_div'>
                <div className="InfraMart_topdiv">
                    <h1>Who Are We?</h1>
                    <p>MAQ THE WORLD INFRA MART is a one-stop solution for all your construction material requirements. With world class manufacturing units and Innovation Center, we revolutionize construction products from foundation to finish. We provide a variety of building materials and lifestyle products that elevate interiors through our robust B2B, retail and B2C network . We are one of Asia's fastest-growing construction solution companies transforming the ecosystem through technology.</p>
                </div>
                <div className="OurStrength">
                    <h4>Our Strengths</h4>
                    <div className="rowdetail">
                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={excavatorimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Tech-enabled Manufacturing, Logistics & Retail</h5>
                                </div>
                            </div>
                        </div>


                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={strategyimg} alt="img" />
                                </div>
                                <div>
                                    <h5>India's only Multi-category Product Brand</h5>
                                </div>
                            </div>
                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={mapimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Pan-India Retail Presence</h5>
                                </div>
                            </div>

                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={deliveryimg} alt="img" />
                                </div>
                                <div>
                                    <h5>2500+ daily deliveries</h5>
                                </div>
                            </div>

                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={factoryimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Over 100+ manufacturing units</h5>
                                </div>
                            </div>

                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={invesmentimg} alt="img" />
                                </div>
                                <div>
                                    <h5>60% Revenue from MAQ THE WORLD INFRA MART brands</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <Box className='InfraMart_div'>
                <div className="OurStrength">
                    <h4>We are revolutionising the construction ecosystem and scaling rapidly!</h4>
                    <div className="ecosystem_rowdetail">
                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>$2.5B</h5>
                                <p>Valuation</p>
                            </div>
                        </div>


                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>6000+</h5>
                                <p>SKUs</p>
                            </div>
                        </div>

                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>4000+</h5>
                                <p>Retail stores </p>
                            </div>

                        </div>



                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>25+</h5>
                                <p>Exclusive Brand Outlets</p>
                            </div>

                        </div>


                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>22</h5>
                                <p>States</p>
                            </div>

                        </div>

                        <div className="ecosystemdiv p-0">
                            <div>
                                <h5>100+</h5>
                                <p>Dedicated Manufacturing Units</p>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>


            <Box className='CustomisedSolutions_div'>
                <div className="logistic_topdiv">
                    <h1>Powering Construction Through Technology</h1>
                </div>
                <div className="row">
                    <div className="col-lg-2 col-12 p-0 bgggggg">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={b2bimg} alt="img" />
                            </div>
                            <div>
                                <h5>B2B App</h5>
                                <p>Helps Enterprise clients visualize the construction materials value chain from purchase to delivery</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-12 p-0 bgggggg">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={retailappimg} alt="img" />
                            </div>
                            <div>
                                <h5>Retailer App</h5>
                                <p>Enables easy management of business including purchasing, financing, inventory management, delivery and more, all under one roof</p>
                            </div>
                        </div>
                    </div>


                    <div className=" col-lg-2 col-12 p-0 bgggggg">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={vrglassimg} alt="img" />
                            </div>
                            <div>
                                <h5>In-Store VR Tech</h5>
                                <p>Re-Imagine Your Home Interiors At Our Experience Stores Using Our Virtual And Augmented Reality Technology</p>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-2 col-12 p-0 bgggggg">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={socialimg} alt="img" />
                            </div>
                            <div>
                                <h5>Social Commerce</h5>
                                <p>Provides an integrated platform for freelancers and influencers to earn extra income through MAQ THE WORLD INFRA MART</p>
                            </div>
                        </div>
                    </div>

                </div>

            </Box>

            <div className="">
                <div className="logistic_topdiv">
                    <h1>Vision & Mission</h1>
                </div>
                <div className="row missionvision">
                    <div className=" col-lg-6 col-12">
                        <img src={homevision} alt="imgg" />
                    </div>
                    <div className=" col-lg-6 col-12">
                        <img src={homemission} alt="imgg" />
                    </div>
                </div>

            </div>

            <div className="Valuesdiv">
                <div className="logistic_topdiv">
                    <h1>Values</h1>
                </div>
                <img src={ourvalues} alt="imgg" />
            </div>

            <Box className='logistic_clients'>
                <h1>Key Partners & Project</h1>
                <h4>Key Projects</h4>
                <p>We have delivered to multiple landmark projects across the nation</p>

                <div className="our_partner">
                    {/* <h1 className='fw-bold ms-3 text-white'>{title}</h1> */}
                    <Slider {...settings}>
                        {InfraMartKeyProjectData?.map((item, index) => (
                            <div key={item.id} className="Projects_topimg">
                                <img style={{ width: '150px', height: '100px', objectFit: 'contain' }} src={item.imgSrc} alt={`img-${item.id}`} />
                                <p>{item.name}</p>
                            </div>
                        ))}
                    </Slider>
                </div>

                <h4 className=" mt-5">Key Partners</h4>
                <p>Digitally connecting key customers and suppliers - On-Time, Every Time!</p>

                <div className="our_partner">
                    {/* <h1 className='fw-bold ms-3 text-white'>{title}</h1> */}
                    <Slider {...settings}>
                        {InfraMartKeyProjectData?.map((item, index) => (
                            <div key={item.id} className="Partners_topimg">
                                <img style={{ width: '180px', height: '120px', objectFit: 'contain' }} src={item.imgSrc} alt={`img-${item.id}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Box>

            <Box className='CustomisedSolutions_div'>
                <div className="logistic_topdiv">
                    <h1>Strategic Investments</h1>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-12 p-0 StrategicInvestments">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={Shalimarimg} alt="img" />
                            </div>
                            <div>
                                <h5>Shalimar Paints</h5>
                                <p>HelpsEntePartner with a vintage brand in a coveted categoryrprisedelivery</p>
                                <Button className="btun">Read More</Button>
                            </div>
                        </div>


                    </div>
                    <div className="col-lg-3 col-12 p-0 StrategicInvestments">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={rdcimg} alt="img" />
                            </div>
                            <div>
                                <h5>RDC</h5>
                                <p>Leaders in the Concrete industry through RDC acquisition</p>
                                <Button className="btun">Read More</Button>
                            </div>
                        </div>

                    </div>


                    <div className=" col-lg-3 col-12 p-0 StrategicInvestments">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={equipimg} alt="img" />
                            </div>
                            <div>
                                <h5>Equiphunt</h5>
                                <p>Transforming equipment rentals with Equiphunt Solutions</p>
                                <Button className="btun">Read More</Button>
                            </div>
                        </div>
                    </div>

                </div>

            </Box>

            <Box className='infraTeam_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">Leadership Team</h1>
                </div>

                <div className="row gap-3 infraTeam_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad </h6>
                                    <p>Co-founder</p>
                                </div>
                                <div>
                                    <p><Link><FaLinkedin className=" fs-2 text-primary" /></Link></p>
                                </div>

                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad </h6>
                                    <p>Co-founder</p>
                                </div>
                                <div>
                                    <p><Link><FaLinkedin className=" fs-2 text-primary" /></Link></p>
                                </div>

                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad </h6>
                                    <p>Chief Technology Officer</p>
                                </div>
                                <div>
                                    <p><Link><FaLinkedin className=" fs-2 text-primary" /></Link></p>
                                </div>

                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>

                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad </h6>
                                    <p>Chief Technology Officer</p>
                                </div>
                                <div>
                                    <p><Link><FaLinkedin className=" fs-2 text-primary" /></Link></p>
                                </div>

                            </div>

                        </div>
                    </Link>

                </div>

                <div className="ViewServices_btn">
                    <Button>View More</Button>
                </div>

            </Box>

            <Box className='InfraMart_div'>
                <div className="InfraMart_topdiv">
                    <h1>Awards</h1>
                    <p>We have been recognized by leading media groups time and again for our many accomplishments.</p>
                </div>
                <div className="OurStrength">
                    <div className="rowdetail">
                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={forbesimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Accredited with Forbes Tycoon of the Year - 2021</h5>
                                </div>
                            </div>
                        </div>


                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={constructionimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Emerging Company of the Year by Construction World - 2021</h5>
                                </div>
                            </div>
                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={BNIimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Business Excellence Award by BNI CEO Connect - 2019</h5>
                                </div>
                            </div>

                        </div>

                        <div className="infra_col12 p-0">
                            <div className="our_infratechdiv">
                                <div className="iconimgdiv">
                                    <img src={fastergrowingimg} alt="img" />
                                </div>
                                <div>
                                    <h5>Fastest Growing Small Business by Small Business Awards - 2018</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </Box>

            <div className="accordioncontainer">
                {FaqData?.map((accordionItem) => (
                    <Accordion
                        className='accordion-bigdiv'
                        key={accordionItem.id}
                        expanded={expandedAccordion === accordionItem.id}
                        onChange={handleAccordionChange(accordionItem.id)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${accordionItem.id}-content`}
                            id={`panel${accordionItem.id}-header`}
                        >
                            <Typography className="bigdiv-ques">{accordionItem.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="bigdiv-answr"> {accordionItem.details}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>


            <MAQFooter />

        </>
    )
}

export default MAQInfraMartHome