import { Box, Button } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import volunteersbg from '../../assest/volunteersbg.webp'
import '../../styles/NgoAboutUsStyle.css'
import media1 from '../../assest/media1.webp'
import media2 from '../../assest/media2.webp'
import media3 from '../../assest/media3.webp'
import media4 from '../../assest/media4.webp'
import { Link } from "react-router-dom"
import award1 from '../../assest/award1.webp'
import award2 from '../../assest/award2.webp'
import sld2 from '../../assest/sld2.webp'
import sld3 from '../../assest/sld3.webp'
import Bhavesh from '../../assest/Bhavesh.webp'
import slid1 from '../../assest/slid1.webp'
import { FaLinkedin } from "react-icons/fa";



const NgoAboutUs = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={volunteersbg} alt="img" />
                <h1 className="h1donate">About Us</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Our <span>Journey</span> </h1>
                </div>

                <div className="aboutus_div">
                    <p>MAQ THE WORLD NGO TRUST began in early 2008 with the Go Green Ganesha campaign, in response to witnessing the usage of excessive amounts of PoP plastic to make Ganesha statues during the Ganpathi festival. Pradeep and Durgesh (Founders of MAQ THE WORLD NGO TRUST) believed that this is not the ideal way to celebrate because it is harmful to the environment and our health, and as ecofriendly alternatives already exist. And so, they initiated the Go Green Ganesha project within their locality, targeting schools, colleges and housing communities. Following the huge success and positive response towards this program, they began Go Green Kids. It was an innovative education initiative to sensitize and educate children towards environmental protection and improvement-with the motive that kids will teach their parents to go green in their everyday lives and be responsible towards nature. In 2010, MAQ THE WORLD NGO TRUST registered as an NGO and expanded to thousands of schools, colleges and housing societies, after which Zaroorat, Green Youth Green India and many more environmental projects began.</p>
                    <p>The passion to reduce pollution and support farmers through planting trees continued to drive MAQ THE WORLD NGO TRUST towards mega projects in rural areas and mass tree plantations. With the realization that innovation is needed in the plantation field to respond to the need for nature in our urbanizing cities, the Miyawaki Dense Forestation method was adopted. MAQ THE WORLD NGO TRUST started with few trees and today continues to plant lakhs of trees every year. Our work aims to connect people with nature in urban spaces through tree plantations and rejuvenating the ecosystem. Our focus is to fight the climate crisis and therefore we are working to provide sustainable solutions for a greener world through our projects Ecosystem Rejuvenation (Air, Water, Soil) and Sustainable Solutions. We continue to involve communities, raise awareness, and encourage implementation on the ground, ultimately working together to create a healthy and pollution-free India.</p>
                </div>

            </div>

            <div className="row bgclor_VisionMission">
                <div className="col-lg-5 col-12">
                    <h2>Our <span>Vision</span></h2>
                    <p>A well-balanced ecosystem, efficient natural resource use and green pollution-free earth for our present and future generations and all creatures living in it.</p>
                </div>
                <div className="col-lg-5 col-12">
                    <h2>Our <span>Mission</span></h2>
                    <p>To sensitize people about the value and importance of the environment, environmental issues and its adverse impact on our lives and empower them to contribute to a sustainable and GREEN world.</p>
                </div>

            </div>

            <section id="media_coverage">
                <Box className='ngo_ourwork'>
                    <div className="welcomeDiv">
                        <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4">Media <span>Coverage</span> </h1>
                    </div>
                    <div className="row ngo_ourwork_detail ps-3">
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/home_area'}>
                            <div className="ngo_ourwork_area">
                                <div>
                                    <img src={media1} alt="img" />
                                </div>
                                <div>
                                    <h5>Tree Plantation</h5>
                                </div>


                            </div>
                        </Link>
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>
                            <div className="ngo_ourwork_area">
                                <div>
                                    <img src={media2} alt="img" />
                                </div>
                                <div>
                                    <h5>Miyawaki Urban Forest</h5>
                                </div>


                            </div>
                        </Link>

                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_ourwork_area">
                                <div>
                                    <img src={media3} alt="img" />
                                </div>
                                <div>
                                    <h5>TV Coverage</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_ourwork_area">
                                <div>
                                    <img src={media4} alt="img" />
                                </div>
                                <div>
                                    <h5>Other Categories</h5>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>
            </section>

            <section id="awards">
                <Box className='ngo_ourwork'>
                    <div className="welcomeDiv">
                        <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4"><span>Awards</span> </h1>
                    </div>
                    <div className="row ngo_ourwork_detail ps-3">
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/home_area'}>
                            <div className="ngo_Awards">
                                <div>
                                    <img src={award1} alt="img" />
                                </div>
                                <div>
                                    <h5>40 under 40 Awards</h5>
                                </div>


                            </div>
                        </Link>
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>
                            <div className="ngo_Awards">
                                <div>
                                    <img src={media2} alt="img" />
                                </div>
                                <div>
                                    <h5>Dna Eco Ganesha Awards</h5>
                                </div>


                            </div>
                        </Link>

                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_Awards">
                                <div>
                                    <img src={award1} alt="img" />
                                </div>
                                <div>
                                    <h5>Most Pro-active NGO Awards</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_Awards">
                                <div>
                                    <img src={award2} alt="img" />
                                </div>
                                <div>
                                    <h5>Mumbai Police Appreciation</h5>
                                </div>

                            </div>

                        </Link>

                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_Awards">
                                <div>
                                    <img src={award1} alt="img" />
                                </div>
                                <div>
                                    <h5>Mumbai Police Appreciation</h5>
                                </div>

                            </div>

                        </Link>

                        <Link className=" col-lg-3 col-12 linktag_ngo" to={'/'}>

                            <div className="ngo_Awards">
                                <div>
                                    <img src={award2} alt="img" />
                                </div>
                                <div>
                                    <h5>Mumbai Police Appreciation</h5>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>
            </section>


            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Get To Know <span>Us</span> </h1>
                </div>

                <div className="aboutus_div">
                    <p>MAQ THE WORLD NGO TRUST team consists of qualified and passionate professional with Graduation, Masters and PhD in Social work, Environment , Agroforestry, Taxonomy, Botany, Horticulture, Water Conservation, Rural Development, Natural resource management, Psychology and Doctors, CA, CFA, MBA, LLM etc. from reputed institutes like, New York University, IIM, TISS, KVK, MU, DU etc.</p>
                    <p>Our team is expertise's in project planning, implementation, execution and management-giving the best possible experience to our corporate partners and volunteers and harnessing innovative ideas to sustain planet earth.</p>
                </div>

            </div>

            <section id="team">
                <Box className='ngo_ourwork'>
                    <div className="welcomeDiv">
                        <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Our Core <span>Team</span> </h1>
                    </div>
                    <h5 className=" fw-bold mx-4" style={{ letterSpacing: '1px' }}>Directors</h5>
                    <div className=" row">
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>
                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld2} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Anjana Kaura</h5>
                                    <p className=" text-center my-1">Director</p>
                                    <p className=" text-center my-1">Corporate Relations and Strategy</p>
                                    <p>We believe bringing positive change in a large scale is not a single handed process. It must be supported by the 'Power Of People’ And the Power of people must be led by 'Power of Unity’ with a good deed.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld2} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Anjana Kaura</h5>
                                    <p className=" text-center my-1">Director</p>
                                    <p className=" text-center my-1">Corporate Relations and Strategy</p>
                                    <p>We believe bringing positive change in a large scale is not a single handed process. It must be supported by the 'Power Of People’ And the Power of people must be led by 'Power of Unity’ with a good deed.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld2} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Anjana Kaura</h5>
                                    <p className=" text-center my-1">Director</p>
                                    <p className=" text-center my-1">Corporate Relations and Strategy</p>
                                    <p>We believe bringing positive change in a large scale is not a single handed process. It must be supported by the 'Power Of People’ And the Power of people must be led by 'Power of Unity’ with a good deed.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>


                <Box className='ngo_ourwork my-5'>
                    <h5 className=" fw-bold mx-4" style={{ letterSpacing: '1px' }}>Middle Management</h5>
                    <div className=" row">
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>
                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld3} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Madhavi Bhalekar</h5>
                                    <p className=" text-center my-1">General Manager</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld3} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Madhavi Bhalekar</h5>
                                    <p className=" text-center my-1">Financial and legal consultant</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={sld3} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Madhavi Bhalekar</h5>
                                    <p className=" text-center my-1">Sr. Program Manager</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>

                <Box className='ngo_ourwork my-5'>
                    <h5 className=" fw-bold mx-4" style={{ letterSpacing: '1px' }}>Field Team</h5>
                    <div className=" row">
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>
                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={Bhavesh} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Bhavesh Jogadiyar</h5>
                                    <p className=" text-center my-1">Program Manager</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={Bhavesh} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Bhavesh Jogadiyar</h5>
                                    <p className=" text-center my-1">Program Manager</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={Bhavesh} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Bhavesh Jogadiyar</h5>
                                    <p className=" text-center my-1">Program Manager</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>

                <Box className='ngo_ourwork my-5'>
                    <h5 className=" fw-bold mx-4" style={{ letterSpacing: '1px' }}>Office Team</h5>
                    <div className=" row">
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>
                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={slid1} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Kayden Anthony</h5>
                                    <p className=" text-center my-1">Senior Program Manager (Social Media)</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className="col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={slid1} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Kayden Anthony</h5>
                                    <p className=" text-center my-1">Senior Program Manager (Social Media)</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>
                        </Link>
                        <Link className=" col-lg-4 col-12 p-0 linktag_ngo" to={'/'}>

                            <div className="workshopsdiv">
                                <div className="img_workdiv">
                                    <img src={slid1} alt="img" />
                                </div>
                                <div>
                                    <h5 className=" text-center my-1">Kayden Anthony</h5>
                                    <p className=" text-center my-1">Senior Program Manager (Social Media)</p>
                                    <p className=" text-center my-1">Agriculturist | Botanist | Ecologist</p>
                                    <p>She is a dedicated environmentalist with over 9 years of experience in mangrove conservation, coupled with expertise in CSR and sustainability project management, committed to safeguarding our planet's vital ecosystems.</p>
                                </div>
                                <div className="linkdin_div">
                                    <Link to={''}><FaLinkedin className=" fs-1" /></Link>
                                </div>

                            </div>

                        </Link>


                    </div>
                </Box>
            </section>


            <Box className='ngo_ourwork my-5'>
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 pb-4 mx-4">Honorary Advisors and <span>Experts</span> </h1>
                </div>
                <div className=" row">
                    <Link className="col-lg-6 col-12 p-0 linktag_ngo" to={'/'}>
                        <div className="workshopsdiv">
                            <div>
                                <h5 className="my-1" style={{ color: '#3aa348' }}>Bharat Kushare</h5>
                                <p className="my-1">General Manager</p>
                                <p className="my-1">Agriculturist | Botanist | Ecologist</p>
                                <p>Since inception of MAQ THE WORLD NGO TRUST he has provided his expertise. He is a tax advisor by profession, a Chartered Accountant by qualification. He has advised various Indian CSR, social development and sports related projects to realize its full potential to benefit from robust compliance with tax and regulatory framework. He is currently working with Ernst & Young in the United Kingdom.</p>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-6 col-12 p-0 linktag_ngo" to={'/'}>

                        <div className="workshopsdiv">
                            <div>
                                <h5 className="my-1" style={{ color: '#3aa348' }}>Bharat Kushare</h5>
                                <p className="my-1">Financial and legal consultant</p>
                                <p>Since inception of MAQ THE WORLD NGO TRUST he has provided his expertise. He is a tax advisor by profession, a Chartered Accountant by qualification. He has advised various Indian CSR, social development and sports related projects to realize its full potential to benefit from robust compliance with tax and regulatory framework. He is currently working with Ernst & Young in the United Kingdom.</p>
                            </div>

                        </div>
                    </Link>
                    <Link className=" col-lg-6 col-12 p-0 linktag_ngo" to={'/'}>

                        <div className="workshopsdiv">
                            <div>
                                <h5 className="my-1" style={{ color: '#3aa348' }}>Bharat Kushare</h5>
                                <p className="my-1">Financial and legal consultant</p>
                                <p>Since inception of MAQ THE WORLD NGO TRUST he has provided his expertise. He is a tax advisor by profession, a Chartered Accountant by qualification. He has advised various Indian CSR, social development and sports related projects to realize its full potential to benefit from robust compliance with tax and regulatory framework. He is currently working with Ernst & Young in the United Kingdom.</p>
                            </div>

                        </div>

                    </Link>

                    <Link className=" col-lg-6 col-12 p-0 linktag_ngo" to={'/'}>

                        <div className="workshopsdiv">
                            <div>
                                <h5 className="my-1" style={{ color: '#3aa348' }}>Bharat Kushare</h5>
                                <p className="my-1">Financial and legal consultant</p>
                                <p>Since inception of MAQ THE WORLD NGO TRUST he has provided his expertise. He is a tax advisor by profession, a Chartered Accountant by qualification. He has advised various Indian CSR, social development and sports related projects to realize its full potential to benefit from robust compliance with tax and regulatory framework. He is currently working with Ernst & Young in the United Kingdom.</p>
                            </div>

                        </div>

                    </Link>


                </div>
            </Box>


            <div className=" text-center fw-bold m-5 fs-5 py-3">
                Beyond our Core Team, our MAQ THE WORLD NGO TRUST family extends to many more Field Coordinators and Support Staff
            </div>


            <NGOTrustFooter />

        </>
    )
}

export default NgoAboutUs
