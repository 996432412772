import people1 from '../assest/BuildersDeveloper/Hyderabad.jpg'
import people2 from '../assest/BuildersDeveloper/Warangal.jpg'
import people3 from '../assest/BuildersDeveloper/Nizamabad.jpg'
import people4 from '../assest/BuildersDeveloper/Khammam.jpg'


const TelanganaCityData = [
    {
        id: 1,
        heading: 'Hyderabad',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Warangal',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Nizamabad',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Khammam',
        imgSrc: people4,
    },
    

];
export default TelanganaCityData