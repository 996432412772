import people1 from '../assest/BuildersDeveloper/Kolkata.jpg'
import people2 from '../assest/BuildersDeveloper/Howrah.jpg'
import people3 from '../assest/BuildersDeveloper/Siliguri.jpg'
import people4 from '../assest/BuildersDeveloper/Darjeeling.jpg'


const WestBengalCityData = [
    {
        id: 1,
        heading: 'Kolkata',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Howrah',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Siliguri',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Darjeeling',
        imgSrc: people4,
    },
    

];
export default WestBengalCityData