
import Metro1 from '../assest/hajjUmrahTour/tawaf.webp'




const ClientsSpeakData = [
    {
        id: 1,
        imgSrc: Metro1,
    },
    {
        id: 2,
        imgSrc: Metro1,
    },
    {
        id: 3,
        imgSrc: Metro1,

    }, {
        id: 4,
        imgSrc: Metro1,
    }, {
        id: 5,
        imgSrc: Metro1,

    },{
        id: 6,
        imgSrc: Metro1,
    },
    {
        id: 7,
        imgSrc: Metro1,
    },
    {
        id: 8,
        imgSrc: Metro1,

    }, {
        id: 9,
        imgSrc: Metro1,
    }, {
        id: 10,
        imgSrc: Metro1,

    }
];
export default ClientsSpeakData

