
import ourjourney1 from '../assest/agriLogistics/ourjourney1.png'
import ourjourney2 from '../assest/agriLogistics/ourjourney2.png'
import ourjourney3 from '../assest/agriLogistics/ourjourney3.png'
import ourjourney4 from '../assest/agriLogistics/ourjourney4.png'
import ourjourney5 from '../assest/agriLogistics/ourjourney5.png'
import ourjourney6 from '../assest/agriLogistics/ourjourney6.png'
import ourjourney7 from '../assest/agriLogistics/ourjourney7.png'
import ourjourney8 from '../assest/agriLogistics/ourjourney8.png'






const AgriLogisticOurJourneyData = [
    {
        id: 1,
        imgSrc: ourjourney1,
        valuetext:'580000+',
        name:'Kilometers Driven.'
    },
    {
        id: 2,
        imgSrc: ourjourney2,
        valuetext:'11%',
        name:'Increased Income.'
    },
    {
        id: 3,
        imgSrc: ourjourney3,
        valuetext:'140+',
        name:'Farmers Organizations in Partnership.'

    }, {
        id: 4,
        imgSrc: ourjourney4,
        valuetext:'68%',
        name:'Jobs Created For Youth and Women.'
    }, {
        id: 5,
        imgSrc: ourjourney5,
        valuetext:'560+',
        name:'Trips Completed.'

    }
    , {
        id: 6,
        imgSrc: ourjourney6,
        valuetext:'1500+',
        name:'High-Tech Truck Fleet.'

    }
    , {
        id: 7,
        imgSrc: ourjourney7,
        valuetext:'121+',
        name:'District Coverage.'

    }
    , {
        id: 8,
        imgSrc: ourjourney8,
        valuetext:'180700+',
        name:'Farmers "ACCESSING" Markets.'

    }
   
    
];
export default AgriLogisticOurJourneyData