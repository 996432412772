
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import ourGallery from '../../assest/our-gallery.webp'
import volunteer from '../../assest/Volunteer.webp'
import RenewableEnergySources from '../../assest/RenewableEnergySources.webp'
import volunteer6432892 from '../../assest/6432892.webp'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import '../../styles/VolunteerStyle.css'
import { FaLocationDot } from "react-icons/fa6";

const Volunteer = () => {


    const defalutValue = {
        yourName: '',
        Email: '',
        Mobile: '',
        Age: '',
        Gender: '',
        ResidentialPostalAddress: '',
        Qualification: '',
        Company: '',
        Expected: '',
        Previous: '',
        Availability: '',
        Location: '',
        EnterMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        yourName: yup.string().required('Name is Requird!'),
        Email: yup.string().required('Email is Requird!'),
        Mobile: yup.string().required('Contact Number is Requird!'),
        Age: yup.string().required('Age is Requird!'),
        Gender: yup.string().required('Gender is Requird!'),
        ResidentialPostalAddress: yup.string().required('Residential is Requird!'),
        Qualification: yup.string().required('Qualification is Requird!'),
        Company: yup.string().required('Company is Requird!'),
        Expected: yup.string().required('Expected is Requird!'),
        Previous: yup.string().required('Previous is Requird!'),
        Availability: yup.string().required('Availability is Requird!'),
        Location: yup.string().required('Location is Requird!'),
        EnterMessage: yup.string().required('Message is Requird!'),


    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={ourGallery} alt="img" />
                <h1 className="h1donate text-center">Volunteer</h1>
            </Box>

            <div className="DonateNow">
                <div className="welcomeDiv pb-0">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">VOLUNTEER <span> WITH US</span> </h1>
                </div>

            </div>

            <div className="pedhlagao_div">
                <div className="">
                    <h5>JOIN MAQ THE WORLD NGO TRUST, VOLUNTEER</h5>
                    <div className=" row">
                        <div className="col-lg-12 col-12 more_activities">
                            <div>
                                <img src={volunteer} alt="img" />

                            </div>
                            <div>
                                <p>We believe bringing positive change in large scale is not a single handed process. Bringing constructive change in society must be supported by the 'Power of People'.</p>
                                <p>Thank you for expressing your interest with us for a greener India. We will respond to you as per the requirements and availability of volunteering opportunities.</p>
                                <p>Working professionals and homemakers who have needed skills can also help us through online communication and support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-4 pb-sm-20">
                    <h4 className="pb-2 mx-4">Upcoming <span style={{color:'#3aa348'}}> Events</span> </h4>
                    <div className="upcomingDiv_volunteer">
                        <div>
                            <img src={RenewableEnergySources} alt="img" />

                        </div>
                        <div>
                            <h6>volunteer</h6>
                            <p className="loc"><FaLocationDot /> Mumbai</p>
                            <p>Mass timber has been hailed as the solution to arc</p>
                            <p>Read more</p>
                        </div>

                    </div>

                    <div className="upcomingDiv_volunteer">
                        <div>
                            <img src={volunteer6432892} alt="img" />

                        </div>
                        <div>
                            <h6>Plantation</h6>
                            <p className="loc"><FaLocationDot /> Malad</p>
                            <p>Volunteer for tree plantation activity - pit diggi</p>
                            <p>Read more</p>
                        </div>

                    </div>
                </div>

                <div className="col-md-7 p-4" style={{ boxShadow: ' 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1)' }}>
                    <div className="card-body row">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row">

                                    <div className="col-12 col-lg-6">

                                        <Field type='text' name='yourName' placeholder='Name'
                                            className={
                                                `form-control
                                                     ${errors.yourName && touched.yourName ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="yourName" /></p>
                                    </div>
                                   

                                    <div className="col-12 col-lg-6">

                                        <Field type='text' name='Email' placeholder='E-mail Address'
                                            className={
                                                `form-control
                                                     ${errors.Email && touched.Email ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Email" /></p>
                                    </div>
                                    
                                    <div className="col-12 col-lg-6">

                                        <Field type='text' name='Mobile' placeholder='Contact Number'
                                            className={
                                                `form-control
                                                     ${errors.Mobile && touched.Mobile ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Mobile" /></p>
                                    </div>

                                    <div className="col-12 col-lg-3">


                                        <Field type='text' name='Age' placeholder='Age'
                                            className={
                                                `form-control
                                                     ${errors.Age && touched.Age ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Age" /></p>
                                    </div>

                                    <div className="col-12 col-lg-3">
                                        <Field component='select' name='Gender' className={
                                            `form-control
                                                          ${errors.Gender && touched.Gender ? ' is-invalid' : ''}`
                                        }>
                                            <option >Gender</option>
                                            <option value={71}>Yes</option>
                                            <option value={82}>No</option>
                                        </Field>

                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Gender" /></p>
                                    </div>


                                    <div className="">

                                        <Field type='text' name='ResidentialPostalAddress' placeholder='Residential/Postal Address'
                                            className={
                                                `form-control
                                                        ${errors.ResidentialPostalAddress && touched.ResidentialPostalAddress ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="ResidentialPostalAddress" /></p>
                                    </div>

                                    <div className="">

                                        <Field type='text' name='StreetAddressLine2' placeholder='Name Of College/University'
                                            className={
                                                `form-control
                                                        ${errors.StreetAddressLine2 && touched.StreetAddressLine2 ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="StreetAddressLine2" /></p>
                                    </div>

                                    <div className="">

                                        <Field type='text' name='Qualification' placeholder='Academic Qualification'
                                            className={
                                                `form-control
                                                        ${errors.Qualification && touched.Qualification ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Qualification" /></p>
                                    </div>
                                    <div className="">

                                        <Field type='text' name='Company' placeholder='Company & Department you are working at present'
                                            className={
                                                `form-control
                                                        ${errors.Company && touched.Company ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Company" /></p>
                                    </div>
                                    <div className="">

                                        <Field type='text' name='Expected' placeholder='Expected Period of Volunteership'
                                            className={
                                                `form-control
                                                        ${errors.Expected && touched.Expected ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Expected" /></p>
                                    </div>
                                    <div className="">

                                        <Field type='text' name='Previous' placeholder='Previous Volunteership Experience'
                                            className={
                                                `form-control
                                                        ${errors.Previous && touched.Previous ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Previous" /></p>
                                    </div>

                                    <div className="">

                                        <Field type='text' name='Availability' placeholder='Availability'
                                            className={
                                                `form-control
                                                        ${errors.Availability && touched.Availability ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Availability" /></p>
                                    </div>

                                    <div className="">
                                        <Field type='text' name='Location' placeholder='Location'
                                            className={
                                                `form-control
                                                        ${errors.Location && touched.Location ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="Location" /></p>
                                    </div>
                                    
                                    
                                    <div className="">

                                        <Field as={'textarea'} rows={4} type='text' name='EnterMessage' placeholder='Why you want to volunteer'
                                            className={
                                                `form-control
                                                     ${errors.EnterMessage && touched.EnterMessage ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="EnterMessage" /></p>
                                    </div>





                                    <div className="">
                                        <button type="submit" className="btn btn-success fs-6" style={{ padding: "10px 20px" }}>
                                            Send Messagre
                                        </button>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>
                </div>
            </div>


            <NGOTrustFooter />

        </>
    )
}

export default Volunteer