import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box, Button } from "@mui/material";
import styled from "styled-components";
import Slide1 from "../../assest/solarpower/black-min.png";
import Slide2 from "../../assest/solarpower/C&I Banner double curtain-min.png";
import Slide3 from "../../assest/solarpower/ResponsiblePurchasingBanner-homepage-1920x800-min.jpg";
import Slide4 from "../../assest/solarpower/Solar city double curtain-min.png";
import Slide5 from "../../assest/solarpower/Untitled-7_0.png";
import Slide6 from "../../assest/solarpower/Antelope Valley Aerial double curtain-min_0.png";
import Slide7 from "../../assest/solarpower/double curtain-min.png";
import '../../styles/maqSolarPowerHomeStyle.css'
import { Link } from "react-router-dom";
import aliquot_banners1 from "../../assest/solarpower/aliquot_banners_Commercial.jpg";
import aliquot_banners2 from "../../assest/solarpower/banners_Utility_Scalemin.jpg";
import aliquot_banners3 from "../../assest/solarpower/Home_Banner_Utility_Right_Rooftop.jpg";
import Project23 from "../../assest/solarpower/Utility Project Large curtain-min.png";
import Camilaimg from "../../assest/solarpower/Camila CS-min.png";
import Oshmanimg from "../../assest/solarpower/Oshman_0.jpg";
import Villasimg from "../../assest/solarpower/Project Carousel_Park Villas.jpg";
import Shoolimg from "../../assest/solarpower/Carousel_Clark Country Shool.jpg";
import Home_banner_Leading from "../../assest/solarpower/Home_banner_Leading.png";
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import ProjectDevelopment1 from "../../assest/solarpower/ProjectDevelopment2022_ico01.jpg";
import ProjectDevelopment2 from "../../assest/solarpower/ProjectDevelopment2022_ico02.jpg";
import ProjectDevelopment3 from "../../assest/solarpower/ProjectDevelopment2022_ico03.jpg";
import ProjectDevelopment4 from "../../assest/solarpower/ProjectDevelopment2022_ico05.jpg";
import ProductsResized from "../../assest/solarpower/ProductsResized.jpg";




const MyTitleMessage = styled.h1`
  position: absolute;
  width: 99.5%;
  top: 20rem;
  z-index: 1;
  margin-top: -125px;
  text-align: center;
  strong {
    font-weight: bold;
    letter-spacing: 2px;

  }
  div {
    color: white;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4),0px 2px 5px rgba(0, 0, 0, 0.4);
    font-weight: 100;
    .main {
      font-size: 40px;
    }
    .main p{
      font-size: 24px;
       margin: 20px 20% 0 20%;

    }
    .main .btnn {
      font-size: 20px;
      letter-spacing: 0.5px;
      border-radius: 6px;
      background-color: #fff;
      text-transform: capitalize;
      padding: 8px 20px;
      color: black;
      margin-top: 20px;

    }
  }
  @media (min-width: 768px) and (max-width: 992px) {
    top: 18rem;
    div {
      .main {
        font-size: 40px;
      }
      .main .btnn {
        font-size: 20px;
      }
    }
  }

  @media (max-width: 767px) {
    top: 13rem;
    width: 98%;
    div {
      .main {
        font-size: 18px;
      }
      .main p{
      font-size: 16px;
       margin: 20px;

    }
      .main .btnn {
        font-size: 16px;
         margin-top: 0px;

      }
    }
  }
`;


const data = [
    {
        id: 1,
        heading: 'MAQ THE WORLD SOLAR POWER',
        paraText: 'The sleek, affordable solar panel for home installations.',
        image: Slide1
    },
    {
        id: 2,
        heading: 'C&I Solutions for Greater Project Value',
        paraText: "Partnering with EPCs and developers for seamless procurement. ",
        image: Slide2

    },
    {
        id: 3,
        heading: 'A Responsible Corporate Customer',
        paraText: "Committed to ensuring an ethical and sustainable supply chain.",
        image: Slide3
    },
    {
        id: 4,
        heading: 'A Global Leader in Solar',
        paraText: "Industry-leading products and customer service worldwide, with a demonstrated commitment to sustainability.",
        image: Slide4
    },
    {
        id: 5,
        heading: 'Track smarter with SuperTrack. ',
        paraText: "MAQ THE WORLD SOLAR POWER new smart monitoring system boosts power by 3-8%.",
        image: Slide5
    },
    {
        id: 6,
        heading: 'A Trusted Project Partner',
        paraText: "Constistent 100% bankability ratings, stringent quality management, and customer-driven product innovation.",
        image: Slide6
    },
    {
        id: 7,
        heading: 'Smarter Utility-Scale Solar',
        paraText: "All-in-one smart solutions deliver higher power gains and lower LCOE.",
        image: Slide7
    }

];

const MAQSolarPowerHome = () => {

    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <MAQHeader />

            <Box id="BoxId">
                <Swiper
                    centeredSlides={true}
                    spaceBetween={0}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className=""
                >
                    {data.map(({ id, image, paraText, heading }) => {
                        return (
                            <SwiperSlide key={id} className="">
                                <div className="custom_solarimg">
                                    <img src={image} alt="img" />
                                    <MyTitleMessage>
                                        <div className="titleMessage">
                                            <div className="heading">
                                                <div className="main text-center mb-3">
                                                    <span>
                                                        <strong style={{ textTransform: 'uppercase' }}>{heading}</strong>
                                                    </span>
                                                    <p>{paraText}</p>
                                                    <Button className="btnn">Learn More</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </MyTitleMessage>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>

            <Box className='sectors_weworkIn'>

                <div className="row gap-4 sector_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={aliquot_banners1} alt="img" />
                            </div>
                            <div>
                                <h6>Residential Solutions</h6>
                                <p>Save on your electricity bills, reduce your carbon footprint and increase the value of your home.</p>
                            </div>
                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={aliquot_banners2} alt="img" />
                            </div>
                            <div>
                                <h6>Utility-Scale Solutions</h6>
                                <p>Own a solar power plant, procure solar contracts or offer solar directly to your customers with a solid business partner.</p>
                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={aliquot_banners3} alt="img" />
                            </div>
                            <div>
                                <h6>Commercial Solutions</h6>
                                <p>Make the smart investment and choose solar for your business. Lock in energy rates, demonstrate corporate social responsibility and achieve energy independence.</p>
                            </div>

                        </div>
                    </Link>

                </div>
                {/* <div className="alldivbtn">
                    <Button>View All Resources</Button>
                </div> */}

            </Box>

            <Box className='athospital_welcomeDiv py-5'>
                <Box className="athospitalhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <h6>MAQ THE WORLD SOLAR POWER</h6>
                        <p className=" fw-bold">Utility and C&I Solutions</p>
                        <p>MAQ THE WORLD SOLAR POWER is the first smart PV solution with an optimized combination of MAQ THE WORLD SOLAR POWER industry-leading solar modules, state-of-the-art solar tracker systems, and world-class inverters.</p>
                        <Button className="morebtn">Learn More</Button>
                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                       <img src={ProductsResized} alt="img" />
                    </div>

                </Box>
            </Box>

            <Box className='athospital_welcomeDiv py-5'>
                <Box className="athospitalhome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <video
                            className=" w-100 h-100"
                            playsInline
                            muted
                            autoPlay
                            controls
                            alt="All the devices"
                            src={"https://static.trinasolar.com/sites/default/files/video/2022_en_Trinasolar_SuperFactory.mp4"}

                        />
                    </div>
                    <div className="textdiv col-lg-6 col-12">
                        <h6>MAQ THE WORLD SOLAR POWER 210 Vertex Super Factory</h6>
                        <p>The 210 Vertex Super Factory is home to the intelligent manufacturing of modules and the application of advanced technologies that are compatible with 210mm large-size wafers.</p>
                        <p>From full automation to top-class tech innovation, MAQ THE WORLD SOLAR POWER further leverages module efficiency and reliability to bring greater customer value. </p>
                        <Button className="morebtn">More information about Vertex</Button>
                    </div>

                </Box>
            </Box>

          

            <Box className='agriLogistic_div'>
                <div className="">
                    <h3 className=" fw-bold">MAQ THE WORLD SOLAR POWER is a leading global provider of complete solar energy solutions</h3>
                    <p className="mt-4" style={{letterSpacing:'1px'}}>Founded in 1997, MAQ THE WORLD SOLAR POWER is mainly engaged in PV products, PV systems and smart energy. PV products include R&D, production and sales of PV modules. PV systems consist of power stations and system products. Smart energy mainly comprises PV power generation and operation & maintenance, smart solutions for energy storage, smart microgrid, and development and sales of multi-energy systems. We are committed to leading the way in smart solar energy solutions and facilitating the transformation of new power systems for a net-zero future.</p>
                    <p className="mt-4" style={{letterSpacing:'1px'}}>Globalization is regarded as MAQ THE WORLD SOLAR POWER main corporate strategy. MAQ THE WORLD SOLAR POWER began to build up its global presence from its inception. The Company was founded in Changzhou, Jiangsu Province, China, where its global headquarters is based. In 2022, MAQ THE WORLD SOLAR POWER established its international headquarters in Shanghai. It actively strengthened the building of global teams. In recent years, the Company has recruited international high-level management and R&D talents from more than seventy countries and regions. It has set up regional headquarters in Zurich, USA Silicon Valley, Miami, Singapore, Dubai, offices or branches in Madrid, Mexico, Sydney, Rome, etc., as well as manufacturing bases in America, Thailand, Vietnam, Indonesia, and UAE with operations in more than 170 countries and regions around the world.</p>
                </div>
                <div className="OurStrength">
                    <div className="about_rowdetail">
                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ProjectDevelopment1} alt="img" />
                                </div>
                                <div>
                                    <h5>10.5+ GW</h5>
                                    <p>Projects Completed</p>
                                </div>


                            </div>
                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ProjectDevelopment2} alt="img" />
                                </div>
                                <div>
                                    <h5>140GW+</h5>
                                    <p>Global Modules Shipped</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ProjectDevelopment3} alt="img" />
                                </div>
                                <div>
                                    <h5>25+ Years</h5>
                                    <p>Development Experience</p>
                                </div>
                            </div>

                        </div>

                        <div className="agri_col12 p-0">
                            <div className="our_agrilogisticdiv">
                                <div className="iconimgdiv">
                                    <img src={ProjectDevelopment4} alt="img" />
                                </div>
                                <div>
                                    <h5>23,000+</h5>
                                    <p>Team Members</p>
                                </div>
                            </div>

                        </div>
                        

                    </div>
                </div>

            </Box>

            <Box className='record_topdiv'>
                <img src={Project23} alt="img" />
                <div className="record_head">
                    <h1 className=" text-center">A track record that speaks for itself. </h1>
                    <p>MAQ THE WORLD SOLAR POWER smart, industry-leading solutions are deployed by solar professionals in projects around the world.</p>
                    <Button className="morebtn">View success stories</Button>
                </div>

            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <h6>Georgia Power Utility Project</h6>
                        <p>Camilla, Georgia</p>
                        <p>Origis Energy and MAQ THE WORLD SOLAR POWER partnered together to build a solar project under tight deadlines and with limited supplies.</p>
                        <Button className="morebtn">Read More</Button>

                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={Camilaimg} alt="img" />
                    </div>

                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={Oshmanimg} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>Oshman Family JCC</h6>
                        <p>Palo Alto, California</p>
                        <p>Find out how the Oshman Family Jewish Community Center decided to power its buildings with solar energy using MAQ THE WORLD SOLAR POWER smart.</p>
                        <Button className="morebtn">Read More</Button>

                    </div>

                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="textdiv col-lg-6 col-12">
                        <h6>Park Villas</h6>
                        <p>San Diego, California</p>
                        <p>California's largest Multi-Family Affordable Solar Housing (MASH) project generates 775,000kWH of electricity annually, enough to power 144 housing units and property common areas. </p>
                        <Button className="morebtn">Read More</Button>

                    </div>
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={Villasimg} alt="img" />
                    </div>
                </Box>
            </Box>

            <Box className='LatestCustom_welcomeDiv'>
                <Box className="LatestCustomhome row">

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={Shoolimg} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>Clark County School District</h6>
                        <p>Las Vegas, Nevada</p>
                        <p>Using MAQ The World Solar Panels, the Clark County School District was able to reduce energy costs and provided significant energy savings to 18 elementary schools.</p>
                        <Button className="morebtn">Read More</Button>

                    </div>

                </Box>
            </Box>


            <Box className='trailblazer_topdiv'>
                <img src={Home_banner_Leading} alt="img" />
                <div className="trailblazer_head">
                    <h1 className=" text-center">A trailblazer in solar since 1997. </h1>
                    <p>With over 205 gigawatts shipped worldwide and counting, MAQ THE WORLD SOLAR POWER photovoltaic panels and solar system components provide clean, affordable and reliable energy to homes, businesses and power plants around the world. With a mission to accelerate global renewable energy development, MAQ THE WORLD SOLAR POWER is recognized for its commitment to sustainability and corporate social responsibility. </p>
                    <Button className="morebtn">Learn more</Button>
                </div>

            </Box>

            <div className=" getstarteddiv">
                <h1 className=" text-center fw-bold">Ready to get started? </h1>
                <p className="">With a longstanding reputation as a Tier 1 solar manufacturer, MAQ THE WORLD SOLAR POWER complete solar solutions offer
                    unprecedented value, reliability, and support for utility, C&I, and residential projects.</p>
            </div>

            <div className=" bg-primary-subtle collaboratediv">

                <div className="">
                    <h1 className=" fw-bold text-center py-5 ">Ready to collaborate? Contact us today!</h1>
                    <div className="card-body row ">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row justify-content-center maqformdiv">
                                    <div className="row justify-content-center contactusform">

                                        <div className="col-12">
                                            <label className="form-label" htmlFor="">Which business line are you interested in?</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Select business line:</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>
                                        <div className="col-12">
                                            <label className="form-label" htmlFor="">What type of solar project is most relevant to you?</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Select solar project:</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label" htmlFor="">First Name</label>

                                            <Field type='text' name='FullName' placeholder='First Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label" htmlFor="">Last Name</label>

                                            <Field type='text' name='FullName' placeholder='Last Name'
                                                className={
                                                    `form-control
                                                     ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>

                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Work Email</label>

                                            <Field type='text' name='EmailID' placeholder='Work Email'
                                                className={
                                                    `form-control
                                                      ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Mobile Number</label>

                                            <Field type='text' name='Phone' placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>

                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Job Tittle</label>

                                            <Field type='text' name='Phone' placeholder=''
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Company Name</label>

                                            <Field type='text' name='Phone' placeholder=''
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>

                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Company Website</label>

                                            <Field type='text' name='Phone' placeholder=''
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-3">
                                            <label className="form-label" htmlFor="">Company Type</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Company Type</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label" htmlFor="">Country</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Country</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label className="form-label" htmlFor="">Industry</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Industry</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <label className="form-label" htmlFor="">Corporate Level</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Corporate Level</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>

                                        <div className="col-12 mt-3">
                                            <label className="form-label" htmlFor="">Job Function</label>
                                            <Field component='select' name='FeedbackTopic' className={
                                                `form-control
                                            ${errors.FeedbackTopic && touched.FeedbackTopic ? ' is-invalid' : ''}`
                                            }>
                                                <option >Job Function</option>
                                                <option value={71}>Yes</option>
                                                <option value={82}>No</option>
                                            </Field>

                                            <p style={{ color: 'red', fontSize: '12px' }}> <ErrorMessage name="FeedbackTopic" /></p>
                                        </div>

                                        <div className=" mt-3">
                                        <label className="form-label" htmlFor="">Please include a detailed description of your inquiry to help us connect you with the right team member.</label>
                                            <Field as={'textarea'} rows={6} name='YourMessage' placeholder=''
                                                className={
                                                    `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-1 mb-3">
                                            <button type="submit" className="btnbtnbtn">
                                                Submit
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                    <div className="main_Contactsolar">
                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong> 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Email ID : </strong> abaris@softech.com</p>
                            </div>

                        </div>

                        {/* <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div> */}
                    </div>

                </div>
            </div>

            <MAQFooter />
        </>
    )
}

export default MAQSolarPowerHome