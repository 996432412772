import { Box } from '@mui/material'
import venture1 from '../../assest/maqworld/tech-agro-1.webp'
import venture2 from '../../assest/maqworld/image1820.webp'
import MAQFooter from '../../common/MAQ/footer/MAQFooter'
import MAQHeader from '../../common/MAQ/header/MAQHeader'
import '../../styles/VentureDetailStyle.css'
import image183 from '../../assest/maqworld/image183.jpg'
import image184 from '../../assest/maqworld/image184.jpg'
import image185 from '../../assest/maqworld/image185.jpg'
import image186 from '../../assest/maqworld/image186.jpg'
import MAQlogo from '../../assest/MAQlogo.jpeg'
import image187 from '../../assest/maqworld/image00188.jpg'
import image188 from '../../assest/maqworld/image0188.jpg'



const VentureDetail = () => {
    return (
        <>
            <MAQHeader />

            <div className='VentureDetaildiv'>
                <img src={venture1} alt="Avatar" />
            </div>

            <Box className='venturebox_welcomeDiv'>
                <img className='imgg' src={MAQlogo} alt="img" />
                <h5 className=" fw-bold pt-4 pb-3">AGRICULTURE FOR A SUSTAINABLE FUTURE…A NEW ICON IN THE AGRICULTURE SPACE IN THE UAE AND THE REGION</h5>
                <p className="pb-5">The Company is catering to the Food Security program being the national agenda of UAE.</p>
                <Box className="venturebox_home row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={venture2} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <p>Partnered with Green technology Group(GTG) who are pioneers in the farming using patented technology, Hydroponic systems and soilless agriculture since 2003, more than 15 regional farms constructed. Epitome of Integration of Technology and economic success under one roof.</p>
                        <p>Farming technique is involved in growing cut flowers, fruits, and vegetables along with other plants such as; nursery stock crops, annual bedding and garden plants, potted flowering plants, foliage plants, potted herbaceous perennials, cut cultivated greens and propagative floriculture materials.</p>
                    </div>

                </Box>
            </Box>

            <div className=' row venturedivimages'>
                <div className=' col-lg-3 col-12'>
                    <img src={image183} alt="Avatar" />
                </div>
                <div className=' col-lg-3 col-12'>
                    <img src={image184} alt="Avatar" />
                </div>
                <div className=' col-lg-3 col-12'>
                    <img src={image185} alt="Avatar" />
                </div>
                <div className=' col-lg-3 col-12'>
                    <img src={image186} alt="Avatar" />
                </div>

            </div>

            <div className='catgdiv'>
                <p>Categories include – Roses, lilies, green vegies, fruit veggies.</p>
                <p>New Arrivals – Amiga Cucumbers, Bell pepper, Salad Tomato, Kale, Lettuce</p>
            </div>

            <div className='ownedbydiv'>
                <h1>TECH AGRO IS JOINTLY OWNED BY</h1>
                <div className=' row'>
                    <div className=' col-lg-4 col-12'>
                        <img src={image187} alt="Avatar" />
                    </div>
                    <div className=' col-lg-4 col-12'>
                        <img src={image188} alt="Avatar" />
                    </div>
                    <div className=' col-lg-4 col-12'>
                        <img src={image187} alt="Avatar" />
                    </div>
                </div>
            </div>

            <div className='ownedbydiv'>
                <h1>PRESS RELEASES</h1>
                <div className='row align-items-center releasepressdiv'>
                    <div className=' col-lg-3 col-12'>
                        <img src={image187} alt="Avatar" />
                    </div>
                    <div className=' col-lg-8 col-12 pressrelease'>
                        <h1>MAQ THE WORLD GROUP</h1>
                        <p>https://maqtheworldgroup.com/</p>
                       

                    </div>
                </div>
            </div>


            <MAQFooter />

        </>
    )
}

export default VentureDetail