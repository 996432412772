

import people1 from '../assest/architecture/bayview-park01-copy.jpeg'
import people2 from '../assest/architecture/custom-home-building-resources-2.jpg'
import people3 from '../assest/architecture/luxury-custom-home-builder-01-1.jpg'



const TravelByCatogryData = [
    {
        id: 1,
        heading:'Latest Development – Bayview Park Homes',
        imgSrc: people1,
    },
    {
        id: 2,
        heading:'Our Custom Homes',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Resources',
        imgSrc: people3,
    }
   
];
export default TravelByCatogryData