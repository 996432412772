import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './featuredProjectSliderStyle.css';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { FaCircleRight } from "react-icons/fa6";


const FeaturedProjectSlider = ({ title, detailsData }) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 3,
                },
            }
        ],
    };

    return (
        <>
            <div className="FeaturedProject_slide">
                {/* <h1 className='fw-bold ms-3'>{title}</h1> */}
                <Slider {...settings}>
                    {detailsData?.map((item, index) => (
                        <div className="project_fetaureddiv">
                            <div>
                                <img src={item.imgSrc} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <p>{item.companyName}</p>
                                    <h6>{item.projectName}</h6>
                                </div>
                                <div>
                                    <p><Link><FaCircleRight className=" fs-2 text-primary-emphasis" /></Link></p>
                                </div>

                            </div>

                        </div>

                    ))}
                </Slider >
            </div >
        </>
    )
}

export default FeaturedProjectSlider
