import people1 from '../assest/BuildersDeveloper/Kochi.jpg'
import people2 from '../assest/BuildersDeveloper/Kannur.jpg'
import people3 from '../assest/BuildersDeveloper/Kollam.jpg'
import people4 from '../assest/BuildersDeveloper/Thrissur.jpg'


const KeralaCityData = [
    {
        id: 1,
        heading: 'Kochi',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Kannur',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Kollam',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Thrissur',
        imgSrc: people4,
    },
    

];
export default KeralaCityData