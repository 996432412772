import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";

import 'react-pro-sidebar/dist/css/styles.css';
import "./sideBar.css"
import { MdEventNote,MdGroupWork,MdOutlineQuestionAnswer,MdOutlineSettingsApplications } from "react-icons/md";
import { Link } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { GoGift } from "react-icons/go";
import { TfiUser } from "react-icons/tfi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { SiBento } from "react-icons/si";
import { GiSkills } from "react-icons/gi";
import { CgWebsite } from "react-icons/cg";

const SideBar = () => {
  

  return (
    <>
      <div id="main-div">
        <ProSidebar
          style={{ height: "100%", position: "absolute" }}
        >
          <div className="sidebar-body-div">
            <SidebarContent className="main-div">
              <Menu className="sidebar-content-div"
                iconShape="circle"
              >
                <p className="Menus-p">MENUS</p>

                <MenuItem icon={<GoHome />}> Home <Link to={'/'} /></MenuItem>

                <SubMenu title="Our Group" icon={<GiSkills />}>
                  <MenuItem icon={<MdEventNote />}>Who We Are<Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Our Chairman <Link to="/Our_Chairman" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Our Directors <Link to="/Our_Directors" /></MenuItem>
                </SubMenu>

                <SubMenu title="Board of Directors" icon={<GiSkills />}>
                  <MenuItem icon={<MdEventNote />}>WALEE QURESHI<Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>NOOR QURESHI <Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>ASHRAF QURESHI <Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>ASLAM QURESHI <Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>HAROON QURESHI <Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>IMRAN QURESHI <Link to="" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>ABDULLAH QURESHI <Link to="" /></MenuItem>
                </SubMenu>

                <SubMenu title="Businesses" icon={<SiBento />}>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Capital Finance<Link to="/maq_capital_finance" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Import & Export <Link to="/maq_import_export" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Infrastructure <Link to="/maq_architecture" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Builders & Developers <Link to="/maq_builder_and_developer" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World InfraMart<Link to="/maq_infra_mart" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Food Stuff <Link to="https://maqfoodstuff.com/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Hyper Market <Link to="https://www.onetouchautomation.co/" /></MenuItem>
                  {/* <MenuItem icon={<MdEventNote />}>MAQ The World Mobile Mart <Link to="https://www.onetouchautomation.co/" /></MenuItem> */}
                  {/* <MenuItem icon={<MdEventNote />}>MAQ The World Hospitals<Link to="/maq_hospital" /></MenuItem> */}
                  {/* <MenuItem icon={<MdEventNote />}>MAQ The World University <Link to="/maq_university" /></MenuItem> */}
                  {/* <MenuItem icon={<MdEventNote />}>MAQ The World International Schools <Link to="/maq_international_schools" /></MenuItem> */}
                  <MenuItem icon={<MdEventNote />}>MAQ The World Crockery <Link to="https://crockery.maqtheworldgroup.com/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Healthcare<Link to="/maq_hospital" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Cosmetics <Link to="https://cosmetics.maqtheworldgroup.com/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Fashions <Link to="https://fashions.maqtheworldgroup.com" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Marine Logistics <Link to="/maq_marine_logistics" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Agri Logistics<Link to="/maq_agri_logistic" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Shipping All World <Link to="/maq_shipping_all_world" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Solar Power <Link to="/maq_solar_power" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Architecture <Link to="/maq_architecture" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World MIS Furniture & Decorators<Link to="/MIS_furniture_decorators" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Interiors & Decorators <Link to="/MIS_furniture_decorators" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Al MAQ The World Tour & Travels <Link to="/al_maq_tour_travel" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World International Payment Gateway <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World NGO Trust<Link to="/NGO_trust" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Franchise <Link to="/restaurant" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Ayurveda <Link to="https://ayurvedic.maqtheworldgroup.com" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Projects <Link to="https://projects.maqtheworldgroup.com/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Fintech <Link to="https://b2cfintech.maqtheworldgroup.com/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Wilmar <Link to="maq_wilmar" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>MAQ The World Green Energy <Link to="/maq_green_energy" /></MenuItem>

                </SubMenu>

                

                <MenuItem icon={<MdOutlineQuestionAnswer />}>FAQs<Link to={'/faq'} /></MenuItem>

                <MenuItem icon={<MdGroupWork />}>Blog<Link to={'/blog'} /></MenuItem>

                <MenuItem icon={<MdOutlineSettingsApplications />}>Services <Link to={'/our-service'} /></MenuItem>

                {/* <SubMenu title="Check Out" icon={<GoGift />}>
                  <MenuItem icon={<MdEventNote />}>Github<Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>LinkedIn <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>TwitterX <Link to="/" /></MenuItem>
                  <MenuItem icon={<MdEventNote />}>Youtube <Link to="/" /></MenuItem>
                </SubMenu> */}

                {/* <MenuItem icon={<CgWebsite />}>Dealing Sectors<Link to={'/dealing_sectors'} /></MenuItem>
                <MenuItem icon={<CgWebsite />}>Why choose us<Link to={'/why_choose_us'} /></MenuItem> */}

                <MenuItem icon={<CgWebsite />}>Testimonials<Link to={'/testimonials'} /></MenuItem>
                <MenuItem icon={<BiMessageSquareDetail />}>Contact<Link to={'/contactus'} /></MenuItem>


              </Menu>
            </SidebarContent>
          </div>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;

