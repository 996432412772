import furniture1 from '../assest/importExport/furniture/Kids Playpens.jpeg'
import furniture2 from '../assest/importExport/furniture/Gaming Chairs.jpg'
import furniture3 from '../assest/importExport/furniture/Up-holstered Beds.jpg'
import furniture4 from '../assest/importExport/furniture/Sofa Beds.jpg'
import furniture5 from '../assest/importExport/furniture/Mattresses.jpg'
import furniture6 from '../assest/importExport/furniture/Dining Tables.jpg'
import furniture7 from '../assest/importExport/furniture/Office Chairs.jpg'
import furniture8 from '../assest/importExport/furniture/Bedroom Sets.jpg'
import furniture9 from '../assest/importExport/furniture/Wardrobes.jpg'
import furniture10 from '../assest/importExport/furniture/Living Room Chairs.jpg'
import furniture11 from '../assest/importExport/furniture/Outdoor Tables.jpg'
import furniture12 from '../assest/importExport/furniture/Furniture Hinges.jpg'




const FurnitureData = [
    {
        id: 1,
        heading:"Kids' Playpens",
        imgSrc: furniture1,
    },
    {
        id: 2,
        heading:"Gaming Chairs",
        imgSrc: furniture2,
    },
    {
        id: 3,
        heading:"Up-holstered Beds",
        imgSrc: furniture3,
    } ,
     {
        id: 4,
        heading:"Sofa Beds",
        imgSrc: furniture4,
    }
    ,
    {
       id: 5,
       heading:"Mattresses",
       imgSrc: furniture5,
   }
   ,
   {
      id: 6,
      heading:"Dining Tables",
      imgSrc: furniture6,
  }
  ,
  {
     id: 7,
     heading:"Office Chairs",
     imgSrc: furniture7,
 }
 ,
 {
    id: 8,
    heading:"Bedroom Sets",
    imgSrc: furniture8,
}
,
 {
    id: 9,
    heading:"Wardrobes",
    imgSrc: furniture9,
}
,
 {
    id: 10,
    heading:"Living Room Chairs",
    imgSrc: furniture10,
}
,
 {
    id: 11,
    heading:"Outdoor Tables",
    imgSrc: furniture11,
}
,
 {
    id: 12,
    heading:"Furniture Hinges",
    imgSrc: furniture12,
}

];
export default FurnitureData