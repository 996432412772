import people1 from '../assest/BuildersDeveloper/Panaji.jpg'
import people2 from '../assest/BuildersDeveloper/Margao.jpg'
import people3 from '../assest/BuildersDeveloper/Ponda.jpg'
import people4 from '../assest/BuildersDeveloper/Mapusa.jpg'


const GoaCityData = [
    {
        id: 1,
        heading: 'panaji',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Margao',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Ponda',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Mapusa',
        imgSrc: people4,
    },
    

];
export default GoaCityData