
import people1 from '../assest/BuildersDeveloper/Chennai.jpg'
import people2 from '../assest/BuildersDeveloper/Coimbatore.jpg'
import people3 from '../assest/BuildersDeveloper/Madurai.jpg'
import people4 from '../assest/BuildersDeveloper/Salem.jpg'


const TamilNaduCityData = [
    {
        id: 1,
        heading: 'Chennai',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Coimbatore',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Madurai',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Salem',
        imgSrc: people4,
    },
    

];
export default TamilNaduCityData