
import people1 from '../assest/BuildersDeveloper/delhi.jpg'
// import people2 from '../assest/BuildersDeveloper/Mysuru.jpg'
// import people3 from '../assest/BuildersDeveloper/Hubballi-Dharwad.jpg'
// import people4 from '../assest/BuildersDeveloper/Mangaluru.jpg'


const DelhiCityData = [
    {
        id: 1,
        heading: 'New Delhi',
        imgSrc: people1,
    },
    // {
    //     id: 2,
    //     heading: 'Mysuru',
    //     imgSrc: people2,
    // },
    // {
    //     id: 3,
    //     heading: 'Hubballi-Dharwad',
    //     imgSrc: people3,
    // },
    // {
    //     id: 4,
    //     heading: 'Mangaluru',
    //     imgSrc: people4,
    // },
    

];
export default DelhiCityData