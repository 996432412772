
import people1 from '../assest/BuildersDeveloper/Lucknow.jpg'
import people2 from '../assest/BuildersDeveloper/Agra.jpg'
import people3 from '../assest/BuildersDeveloper/noida.jpg'
import people4 from '../assest/BuildersDeveloper/ghaziabad.jpg'


const UttarPradeshCityData = [
    {
        id: 1,
        heading: 'Lucknow',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Ghaziabad',
        imgSrc: people4,
    },
    {
        id: 3,
        heading: 'Noida',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Agra',
        imgSrc: people2,
    },
    

];
export default UttarPradeshCityData