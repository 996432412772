

import people1 from '../assest/hajjUmrahTour/madinah.webp'
import people2 from '../assest/hajjUmrahTour/umrah.webp'
import people3 from '../assest/hajjUmrahTour/ramadan.webp'
import people4 from '../assest/hajjUmrahTour/mecca.webp'



const AlMaqHajjUmrahData = [
    {
        id: 1,
        heading:'Umrah 2024',
        imgSrc: people4,
    },
    {
        id: 2,
        heading:'Hajj 2024',
        imgSrc: people2,
    },
    {
        id: 3,
        heading:'Ramadan 2024',
        imgSrc: people3,
    },
     {
        id: 4,
        heading:'Ziyarat 2024',
        imgSrc: people1,
    },
];
export default AlMaqHajjUmrahData