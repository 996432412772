
import RestaurantFooter from "../../common/Restaurant/RestaurantFooter/RestaurantFooter"
import RestaurantHeader from "../../common/Restaurant/RestaurantHeader/RestaurantHeader"
import '../../styles/RestaurantDisclaimerStyle.css'


const RestaurantDisclaimer = () => {
    return (
        <>
            <RestaurantHeader />

            <div className="BusinessSuppliers_head">
                <h4>Disclaimer</h4>
            </div>
            <div className="container">
                <div className="Disclaimer">
                    <h6>Please read the following disclaimer carefully before using this web site:</h6>
                    <h6>General</h6>
                    <ul>
                        <li>Franchise India aims to provide you with a wealth of Franchise experience and other services through this website which are subject to the conditions as mentioned in this disclaimer.</li>
                        <li>We take no responsibility for any failure to provide this service(s), including but not limited to storing or delivering any user communications or settings or anything relating to the use of this website.</li>
                        <li>We are not liable for any loss suffered by you through the use of this website howsoever caused, including but not limited to loss caused through but not limited to our negligence, breach of contract, copyright infringement, and breach of other intellectual property rights or defamation.</li>
                        <li>All contents, links and listings provided within www.MAQfranchise.com and related websites are provided for information purpose only. We make no warranties and are not liable for any actions or representations of any listed company within www.MAQfranchise.com and related websites. We do not offer advice nor do endorse any of the contents or information provided by advertisers. Futhermore, the provided contents have not been confirmed for accuracy and we deny any responsibility to do so.</li>
                        <li>You should always seek independent advice before acting on any information on the Website.</li>
                        <li>We take great care in making sure that all information we provide is accurate but we are not responsible for any loss suffered by you as a consequence of any of your action you take or omit to take on the basis of any of the information we provide. It is the sole responsibility of the prospective buyer and website user to obtain all documents and verify information directly from the Company.</li>
                        <li>We reserve the right to use, reproduce and modify all content submitted by you to the site, including but not limited to comments published by you on the site. We reserve to use our absolute discretion in exercising this right.</li>
                        <li>We are not liable for content published on the website by users of the Website. If you would like to alert us to content that you believe to be inappropriate, please contact us via the website or by sending us an email. We will deal with your request as soon as reasonably possible.</li>
                        <li>All contents and code are copyright 1999-2010, of Franchise India Holding Limited. All Rights Reserved. We reserve the right at our sole discretion to refuse any advertising of submitted information</li>
                        <li>We are not responsible for the transaction that takes place between the investors and franchisors listed on www.MAQfranchise.com. All the franchisor and investor listed on www.MAQfranchise.com are purely for information purpose and represent neither endorsement nor recommendation of such companies by Franchise India.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Obligations</h6>
                    <ul>
                        <li>You must not use the services and functionality provided in this website in any way that causes damage to this website or is unlawful, harmful or fraudulent in any manner or for any competitive intelligence, reverse engineering or for copying of any of its content or functionality.</li>
                        <li>You agree to accord respect to other users of the website and not to interfere with their legitimate use of the website and our services.</li>
                        <li>You agree to indemnify us against all costs, claims, liabilities, demands or expenses incurred as a result of any breach of these Terms by you.</li>
                        <li>We reserve the right to block your access to the website or delete your user account at any time at our absolute discretion.</li>
                        <li>You will not publish or cause to be published any unlawful, defamatory, obscene, threatening, offensive, harmful or otherwise objectionable content.</li>
                        <li>You confirm that you are the author of any content submitted to the website and agree to waive all your moral rights to be identified as the author and your copyright to such content.</li>
                        <li>10.3% service tax is applicable on all the transactions that take place with regards to dotcom service. The paid activation of the account will happen on the realization of the cheque and the paid client will have access to the details of the investors. In case of any changes to be made in your account the information needs to be mailed to us for the changes to be done from your backend.</li>
                        <li>By submitting a request for information regarding any of our sponsors, you represent and warrant that all information submitted is true and non - misleading and does not violate any law or regulation. However Franchise India reserves the right to deny service to any user at any time in its sole discretion.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Email/Newsletter Subscription and Membership obligations</h6>
                    <ul>
                        <li>In case you join the Franchise India community or submit for a newsletter/ membership subscription or opt to receive emails from www.MAQfranchise.com, the details you enter on the registration form will be used by us to provide you with franchise industry newsletters and/or special promotions and other information. If at any time you wish to stop receiving these e-mails, simply follow the unsubscribe instructions at the bottom of the e-mail. By submitting details to www.MAQfranchise.com and asking us to disclose your information to advertisers, you agree to receive telephone calls and/or emails about franchise and business opportunities even if you are registered with us anywhere in the Do Not Call List.</li>
                        <li>You agree to provide your real name, last name and accurate and truthful personal information in your registration.</li>
                        <li>You agree to keep your username and password safe from use by third parties and not to share your registration details with anyone else.</li>
                        <li>You agree to indemnify us against any unauthorised use of your username and password, howsoever caused.</li>
                        <li>We reserve the right to modify the membership information you provide, as well as to delete, disable and/or restrict your account in our absolute discretion.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Franchise India Verified</h6>
                    <ul>
                        <li>The "Franchise India Verified" is limited assurance offered by www.MAQfranchise.com that the name and contact information of the advertiser and the category in which the advertiser is listed by www.MAQfranchise.com, have been verified as existing and correct at the time of the advertiser's application to register with www.MAQfranchise.com.</li>
                        <li>www.MAQfranchise.com makes no representations or guarantees, whether express or implied, including but not limited to guarantees of the continued existence and/or operations of the advertiser, or the reliability, quality, or support, of services as a franchisor for taking a franchise offered by the advertiser. Buying franchise from advertisers shall be at your own risk.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Governing Law</h6>
                    <ul>
                        <li>The Terms and the website are governed by Indian law and you agree to submit to the jurisdiction of the Delhi Courts.</li>
                        <li>By submitting personal information to us, and/or by using our website, you agree that we may collect, use and disclose such personal information for the intended purpose only.</li>
                        <li>Payments collected for various services are non- refundable. However Franchise India Holding Limited reserves its discretion in the matter of refund of any payments made for its services.</li>
                    </ul>
                </div>

                <div className="Disclaimer">
                    <h6>Variation</h6>
                    <p>We reserve the right to amend or modify these Terms and Conditions at any time without notice.</p>
                    <h6>Contact Information</h6>
                    <p>Your Privacy is very important to us. If you have further questions or feedback, please email us at info@MAQfranchise.com.</p>
                    <p>Thank you for visiting www.MAQfranchise.com.</p>
                </div>
            </div>

            <RestaurantFooter />

        </>
    )
}

export default RestaurantDisclaimer