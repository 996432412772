import services1 from '../assest/Infrastructure/tax-incentives1_overview.jpg'
import services2 from '../assest/Infrastructure/growth-support-tile.jpg'
import services3 from '../assest/Infrastructure/growth-support_overview.jpg'
import services4 from '../assest/Infrastructure/innovation-dev-support_overview.jpg'






const OpportunitiesGrowBusinessData = [
    {
        id: 1,
        heading:'Tax-Based Incentives',
        textInfo:'All forms of tax incentives that enable savings and cost advantages to businesses.',
        imgSrc: services1,
    },

    {
        id: 2,
        heading:' Operational Support',
        textInfo:'Programs offering assistance or training to ensure more competitive businesses.',
        imgSrc: services2,
    },
    {
        id: 3,
        heading:'Growth Support',
        textInfo:'Funds, loans and other means of financial support to stimulate business growth.',
        imgSrc: services3,
    } ,

     {
        id: 4,
        heading:'Innovation Development Support',
        textInfo:"Financial incentives to foster university collaboration, research and innovation.",
        imgSrc: services4,
    },
   
];
export default OpportunitiesGrowBusinessData