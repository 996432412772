import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import imgtop from '../../assest/capitalFinance/finance-gold-coins.jpg'
import { Box } from "@mui/material"
import '../../styles/MAQCapitalFinanceHomeStyle.css'
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import conference from '../../assest/iconfinance/icons8-conference-16.png'
import financial from '../../assest/iconfinance/icons8-financial-96.png'
import financialgrowth from '../../assest/iconfinance/icons8-financial-growth-64.png'
import loan from '../../assest/iconfinance/icons8-loan-64.png'
import prize from '../../assest/iconfinance/icons8-prize-64.png'
import truck from '../../assest/iconfinance/icons8-truck-64.png'


const MAQCapitalFinanceHome = () => {
    return (
        <>
            <MAQHeader />
            <Box className='finance_topdiv'>
                <img src={imgtop} alt="img" />
                <div className="the_finance">
                    <h1>MAQ THE WORLD CAPITAL FINANCE</h1>
                    {/* <h1>With MAQ THE WORLD CAPITAL FINANCE, you will never have to miss a business opportunity</h1> */}
                    <p>Since 1973, we’ve provided financial assistance to organizations and individuals, to help turn their business goals into reality.</p>
                    <Button>Our Services</Button>
                </div>
            </Box>
            <Box className='Finance_welcomeDiv'>
                <h1>WELCOME TO MAQ THE WORLD CAPITAL FINANCE</h1>
                {/* <hr className=" mx-5 fw border-3" /> */}
                <p>Your Business Needs a Financing partner like us to grow and sustain the growth you Have Achieved. So, join hands with us, make wiser financial decisions for your business!</p>

                <Box className="CapitalFinanceHome row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={imgtop} alt="img" />
                    </div>

                    <div className="textdiv col-lg-6 col-12">
                        <h6>WE SUPPORT YOUR BUSINESS GROWTH</h6>
                        <p>We Every business has its own growth-path and unique needs. Whether you are planning to purchase more commercial vehicles, need to file some Patents, Trademark & Copyright or simply looking for the best funding options; MAQ THE WORLD CAPITAL FINANCE is here to provide you tailor-made solutions to suit your business needs.</p>
                    </div>

                </Box>
            </Box>


            <Box className='mb-2'>
                <div className=" row me-0">

                    <div className="col-lg-4 col-12 p-0">
                        <div className="our_workdiv_finance">
                            <div>
                                <h5>ABOUT US</h5>
                                <p>MAQ THE WORLD CAPITAL FINANCE is a Non-Banking Finance Company (NBFC) registered with the Reserve Bank of India (RBI). The Company was incorporated in the year 1973. The Company is presently in the business of various financial services.</p>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-12 p-0">

                        <div className="our_workdiv_finance">
                            <div>
                                <h5>CSR</h5>
                                <p>CSR is the process by which an organization thinks about and evolves its relationships with stakeholders for the common good, and demonstrates its commitment in this regard by adoption of appropriate business processes and strategies. Thus CSR is not charity or mere donations.</p>
                            </div>
                            <div>
                                <Button>Read More</Button>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_workdiv_finance">
                            <div>
                                <h5>OUR TEAM</h5>
                            </div>
                            <div className="img_div">
                                <Link className="linktag_ngo" to={'/'}>
                                    <img src={imgtop} alt="img" />
                                </Link>

                            </div>
                        </div>

                    </div>


                </div>
            </Box>

            <div className="providedfinancial">
                <h1>We've provided financial services to clients for over 4 decades</h1>
            </div>

            <Box className='servicesFinance_div'>
                <div className="Finance_welcomeDiv">
                    <h1>OUR SERVICES</h1>
                </div>
                <div className="row me-0">
                    <div className="col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={loan} alt="img" />
                            </div>
                            <div>
                                <h5>Loan Against Securities</h5>
                                <p>Whether your business is small or large, it requires funding from time to time. Why monetize your investments when you can get a Loan Against Shares (LAS) also known as Loan Against Securities which would help you to leverage your investments.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={truck} alt="img" />
                            </div>
                            <div>
                                <h5>Commercial Vehicle Finance</h5>
                                <p>If you are considering a loan to expand your fleet size or need refinance of your existing loan or planning to buy a used vehicle, you can count on our assistance .We will arrange  the loan from banks through which we have tie-ups at attractive</p>
                            </div>
                        </div>
                    </div>
                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={prize} alt="img" />
                            </div>
                            <div>
                                <h5>Patents Trademark & Copyright</h5>
                                <p>Copyright provides exclusive right(s) to the author(s) for their literary and artistic works. The main objective of such protection is to encourage and reward creative work.</p>
                            </div>
                        </div>

                    </div>
                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={conference} alt="img" />
                            </div>
                            <div>
                                <h5>Global Convention</h5>
                                <p>Global Intellectual Property Convention (GIPC) is Asia’s leading conference for in-house IP counsels and innovators to interact with IP attorneys from around the world to discuss best practices and solutions to maximise the value of their innovation and IP.</p>
                            </div>
                        </div>

                    </div>
                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={financialgrowth} alt="img" />
                            </div>
                            <div>
                                <h5>IP Valuation</h5>
                                <p>In recent years, smart companies have effectively started using Intellectual Property system to create, identify, and manage the value of most of their intangible assets by developing and executing intellectual property asset management strategy.</p>
                            </div>
                        </div>

                    </div>
                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_services_finance">
                            <div className="logoimgdiv">
                                <img src={financial} alt="img" />
                            </div>
                            <div>
                                <h5>Other Financial Services</h5>
                                <p>We further manage Business Funding, Inter Corporate Deposits, Ecommerce Seller Funding other financial products.</p>
                            </div>
                        </div>

                    </div>


                </div>
            </Box>
            <div className="providedfinancial">
                <div>
                    <h1>Get In Touch</h1>
                    <Button>Contact Now</Button>
                </div>
            </div>
            <MAQFooter />
        </>
    )
}

export default MAQCapitalFinanceHome