
import people1 from '../assest/BuildersDeveloper/ahmedabad.jpg'
import people2 from '../assest/BuildersDeveloper/Gandhi Nagar.jpg'
import people3 from '../assest/BuildersDeveloper/Surat.jpg'
import people4 from '../assest/BuildersDeveloper/Vadodara.jpg'


const GujratCityData = [
    {
        id: 1,
        heading: 'Ahmedabad ',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Gandhi Nagar',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Surat',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Vadodara',
        imgSrc: people4,
    },
    

];
export default GujratCityData