import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import polygon from '../../assest/hajjUmrahTour/mecca.webp'
import { Box, Button } from "@mui/material"
import '../../styles/almaqTourAndTravelsHomeStyle.css'
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import SchoolStoriesData from "../../data/SchoolStoriesData"
import AlMaqHajjUmrahData from "../../data/AlMaqHajjUmrahData"
import SliderAll from "../../common/hajjumrah/Sliderside/SliderAll"
import HotelUmrahData from "../../data/HotelUmrahData"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfraMartKeyProjectData from "../../data/InfraMartKeyProjectData"
import ClientsSpeakData from "../../data/ClientsSpeakData"

const AlMAQTourAndTravelsHome = () => {
    const quotes = [
        "And whosoever can afford should visit the House on pilgrimage as duty to God. Whosoever denies should remember that God is above heed of the world. - The Quran (3:97)",
        "For an accepted Hajj, there is no reward besides Jannah (Paradise). - Prophet Muhammad (SAWS)",
        "Hasten in performing Hajj, for verily one never knows what will befall him. - Prophet Muhammad (SAWS)",
        "An ‘Umrah after the other stands for the atonement of the sins committed in between. Hajj offered with all its requirements is rewarded with Paradise. (Muslim, No: 1349) - Prophet Muhammad (SAWS)",
        "The pillars of Islam are five: To witness there is no god but Allah and that Muhammad is the messenger of Allah, to perform Salat, to pay Zakat, to perform Hajj and fast Ramadan.�? - Prophet Muhammad (SAWS)"
    ];

    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
                setFade(true);
            }, 1000); // 1 second for fade out
        }, 2000); // 2 seconds for each quote

        return () => clearInterval(interval);
    }, [quotes.length]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 3,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 6,
                },
            }
        ],
    };
    return (
        <>
            <MAQHeader />

            <Box className='Hajjumrah_topdiv'>
                <img src={polygon} alt="img" />
                <div className="hajjumrah_head">
                    <h1>Al MAQ THE WORLD Tour & Travels</h1>
                    <p>A Journey Filled With Blessings & Mercy.</p>
                    <h5>HAJJ | UMRAH | ZIARAT</h5>
                </div>
            </Box>
            <div className={`quote-container ${fade ? 'fade-in' : 'fade-out'}`}>
                {quotes[currentQuoteIndex]}
            </div>


            <div className="PopularTours_div">
                <div className="tour_slide">
                    {AlMaqHajjUmrahData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="populartour_Slider">
                                <h4>{item.heading}</h4>
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <Button className="booknowbtn" fullWidth>Read More</Button>

                            </div>
                        </Link>

                    ))}
                </div >
            </div >

            <div className="row hotelforumrah">
                <div className=" col-lg-6 col-12">
                    <SliderAll images={HotelUmrahData} />
                </div>
                <div className=" col-lg-6 col-12 LuxuriousHotel">
                    <h1>Luxurious Hotel Accommodations For Umrah At The Most Economical Rates</h1>
                    <p>Book Exclusive Umrah Packages for the most Economical Rates with accommodation at Luxurious Hotels in Makkah & Madinah.</p>
                    <Button className="">Know More</Button>
                </div>

            </div>

            <div className="row hotelforumrah">

                <div className=" col-lg-6 col-12 LuxuriousHotel">
                    <h1>TOI Features Dr. Mohd Bilal</h1>
                    <p>Dr Mohd Bilal was featured in The Times Of India, Mumbai 2018. Referring to Dr. Kherada as the Walking Encyclopaedia on Hajj and Umrah, his decades of experience and dedication towards the faith has earned him this honour. Undoubtedly, Dr. Kherada is known for his deep and massive amounts of knowledge across the country regarding the holy Hajj and Umrah. Click on the image beside to read the entire article.</p>
                    <Button className="">Know More</Button>
                </div>

                <div className=" col-lg-6 col-12 hajjimgdiv">
                    <img src={polygon} alt="img" />
                </div>

            </div>

            <div className="">
                <h4 className='fw-bold text-center'>Clients Speak</h4>
                <Slider {...settings} className="bbbeeeee">
                    {ClientsSpeakData?.map((item, index) => (
                        <div key={item.id} className="clientreview_topimg">
                            <img src={item.imgSrc} alt={`img-${item.id}`} />
                        </div>
                    ))}
                </Slider>
            </div>

            <div className="hajjharam">
                <img src={polygon} alt="img" />

            </div>

            <MAQFooter />

        </>
    )
}

export default AlMAQTourAndTravelsHome