
import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import plantsGift from '../../assest/plants-gift.webp'
import bride from '../../assest/bride.webp'
import flowers345 from '../../assest/flowers-345.webp'
import Conferencesandmeetings from '../../assest/Conferencesandmeetings.webp'
import audience from '../../assest/audience-1850.webp'
import awards416 from '../../assest/awards-416.webp'
import podium2 from '../../assest/podium2.webp'
import crowd198 from '../../assest/crowd-198.webp'
import { FaChevronCircleRight } from "react-icons/fa";
import flowchart4 from '../../assest/flowchart4.webp'


const GreenCelebrations = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={plantsGift} alt="img" />
                <h1 className="h1donate text-center">Green Celebrations</h1>
            </Box>
            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Green <span>Celebrations</span> </h1>
                </div>
                <div className="activitiesDiv">
                    <p className=" fs-4">Does the site of the empty plastic cups and bottles dumped at the end of a ‘Save the Planet’ event make you peeved?</p>
                    <p className=" fs-4">It is becoming increasingly important to ensure we are practicing sustainable living in all forms, especially through the events we attend. Creating green events will allow us to reduce our carbon footprint and be more environmentally conscious.</p>
                </div>

            </div>

            <div className=" row">
                <div className="Partners_imgdiv justify-content-center">
                    <div className="col-12 col-lg-2 text-center">
                        <img src={bride} alt="img" />
                        <h5 className="mt-3 mb-0">Weddings</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={flowers345} alt="img" />
                        <h5 className="mt-3 mb-0">Parties</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={Conferencesandmeetings} alt="img" />
                        <h5 className="mt-3 mb-0">Conferences and meetings</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={audience} alt="img" />
                        <h5 className="mt-3 mb-0">Music Concert</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={awards416} alt="img" />
                        <h5 className="mt-3 mb-0">Award Fuctions</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={podium2} alt="img" />
                        <h5 className="mt-3 mb-0">Religious Festivities</h5>
                    </div>
                    <div className="col-12 col-lg-2 text-center">
                        <img src={crowd198} alt="img" />
                        <h5 className="mt-3 mb-0">Political Gatherings</h5>
                    </div>

                </div>
            </div>

            <div class="Ecosystem_bdr bdr-txt3 border-0 mx-5 mb-0 h-auto">
                <h5>Sustainable Events Management</h5>
                <ul class="list-icon theme-colored">
                    <li className=" fs-5"><FaChevronCircleRight className="icons" />Achieve strategic goals which serve economic, environmental and social interests</li>

                    <li className=" fs-5"><FaChevronCircleRight className="icons" />Reduce negative impacts and harm on land and resources</li>

                    <li className=" fs-5"><FaChevronCircleRight className="icons" />Bring positive results for local and global society to inspire sustainable lifestyle change</li>

                </ul>
            </div>

            <div class="row Ecosystem_Benefits">
                <div class="col-lg-12">
                    <h3><strong>Benefits</strong></h3>
                </div>

                <div class="col-lg-6">
                    <div class="Ecosystem_bdr bdr-txt3">
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" />New and emerging concept in India which will increase your brand value</li>

                            <li><FaChevronCircleRight className="icons" />Conserves the environment & supports local communities</li>

                            <li><FaChevronCircleRight className="icons" />Saves money</li>

                            <li><FaChevronCircleRight className="icons" />Attracts new stakeholders</li>

                        </ul>
                    </div>

                </div>

                <div class="col-lg-6">
                    <div class="Ecosystem_bdr bdr-txt">
                        <ul class="list-icon theme-colored">
                            <li><FaChevronCircleRight className="icons" /> Improves show and long term success and profitability of event</li>

                            <li><FaChevronCircleRight className="icons" />Matches attendees ideals</li>

                            <li><FaChevronCircleRight className="icons" />Promotes a sustainable lifestyle</li>

                            <li><FaChevronCircleRight className="icons" />Create new revenue streams - CSR activities</li>

                        </ul>

                    </div>
                </div>

            </div>

            <div className="flowChart3">
                <h5 className=" my-5 text-center">Get in touch with us to make your next event green info@MAQNGOTrust.org</h5>
                <img src={flowchart4} alt="img" />
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default GreenCelebrations