import hotel1 from '../assest/hajjUmrahTour/hotel1.webp'
import hotel2 from '../assest/hajjUmrahTour/hotel2.webp'
import hotel3 from '../assest/hajjUmrahTour/hotel3.webp'
import hotel4 from '../assest/hajjUmrahTour/hotel4.webp'
import hotel5 from '../assest/hajjUmrahTour/hotel5.webp'
import hotel6 from '../assest/hajjUmrahTour/hotel6.webp'



const HotelUmrahData = [
    {
        id: 1,
        heading:'Hotel Le Meridien - Makkah',
        imgSrc: hotel1,
    },
    {
        id: 2,
        heading:'Hotel Le Meridien - Makkah',
        imgSrc: hotel2,
    },
    {
        id: 3,
        heading:'Hotel Le Meridien - Makkah',
        imgSrc: hotel3,
    }, {
        id: 4,
        heading:'Hotel Le Meridien - Makkah ',
        imgSrc: hotel4,
    }, {
        id: 5,
        heading:'Hotel Le Meridien - Makkah ',
        imgSrc: hotel5,
    }, {
        id: 6,
        heading:'Hotel Le Meridien - Makkah ',
        imgSrc: hotel6,
    }
];
export default HotelUmrahData