import { Box } from "@mui/material"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Link } from "react-router-dom"
import { FaLinkedin } from "react-icons/fa";
import member1 from '../../assest/sld2.webp'
import member2 from '../../assest/slid1.webp'

const OurTeam = () => {
    return (
        <>
            <MAQHeader />
            <Box className='infraTeam_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className=" fw-bold">Our Team</h1>
                </div>

                <div className="row gap-3 infraTeam_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Satya Kaliki</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>

                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Satya Kaliki</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>
                    
                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Muhammad</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>
                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member1} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Satya Kaliki</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_infraTeam" to={'/'}>

                        <div className="infraTeam_areadiv">
                            <div>
                                <img src={member2} alt="img" />
                            </div>
                            <div className="teaminfo">
                                <div>
                                    <h6>Satya Kaliki</h6>
                                    <p>Muhammad is the Co-Founder of MAQ THE WORLD GROUP, a con-tech solutions company that is rebuilding the future of construction through innovation and technology.</p>
                                </div>

                            </div>

                        </div>
                    </Link>

                </div>

               

            </Box>
            <MAQFooter />
        </>
    )
}

export default OurTeam