
import img1 from '../assest/wilmar/img-1.webp'
import img2 from '../assest/wilmar/img-4.jpg'
import img3 from '../assest/wilmar/img-5.jpg'
import img4 from '../assest/wilmar/img-6.jpg'



const WilmarAboutOilData = [
    {
        id: 1,
        heading:'Edible Oil',
        imgSrc: img1,
    },
    {
        id: 2,
        heading:'Vanaspati',
        imgSrc: img2,
    },
    {
        id: 3,
        heading:'Speciality Fats',
        imgSrc: img3,
    }, 
    {
        id: 4,
        heading:'Lauric Range ',
        imgSrc: img4,
    }
     
];
export default WilmarAboutOilData