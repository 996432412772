import { Box, Button } from "@mui/material"
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx"
import RealEstateHeader from "../../common/realEstate/header/RealEstateHeader"
import MarxGroupGalleryData from "../../data/MarxGroupGalleryData"
import home1 from '../../assest/home1.webp'
import home2 from '../../assest/home2.webp'
import innerhome2 from '../../assest/innerhome2.webp'
import homekey from '../../assest/homekey.webp'
import home4 from '../../assest/home4.webp'
import home5 from '../../assest/home5.webp'
import home6 from '../../assest/home6.webp'
import home7 from '../../assest/home7.webp'
import homeImg from '../../assest/homeimg.webp'
import smallLogo from '../../assest/MAQlogo.jpeg'
import '../../styles/RealEstateHomeStyle.css'
import { BsCaretDownFill } from "react-icons/bs";
import ex1 from '../../assest/ex1.webp'
import ex2 from '../../assest/ex2.webp'
import ex3 from '../../assest/ex3.webp'
import ex4 from '../../assest/ex4.webp'
import AVTR1 from '../../assest/ex3.webp';
import AVTR2 from '../../assest/ex3.webp';
import AVTR3 from '../../assest/ex3.webp';
import AVTR4 from '../../assest/ex3.webp';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import RealEstateFooter from "../../common/realEstate/footer/RealEstateFooter"

import { Link } from "react-router-dom"
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import Banner4 from '../../assest/BuildersDeveloper/Banner4.jpg'
import '../../styles/maqBuildersDevelopersHomeStyle.css'
import UAECountryCityData from "../../data/UAECountryCityData"
import MaharashtraCityData from "../../data/MaharashtraCityData"
import RajisthanCityData from "../../data/RajisthanCityData"
import UttarakhandCityData from "../../data/UttarakhandCityData"
import HaryanaCityData from "../../data/HaryanaCityData"
import TelanganaCityData from "../../data/TelanganaCityData"
import UttarPradeshCityData from "../../data/UttarPradeshCityData"
import PunjabCityData from "../../data/PunjabCityData"
import MadhyaPradeshCityData from "../../data/MadhyaPradeshCityData"
import ChattisgarhCityData from "../../data/ChattisgarhCityData"
import BiharCityData from "../../data/BiharCityData"
import GujratCityData from "../../data/GujratCityData"
import KarnatakaCityData from "../../data/KarnatakaCityData"
import DelhiCityData from "../../data/DelhiCityData"
import AndhraPradeshCityData from "../../data/AndhraPradeshCityData"
import KeralaCityData from "../../data/KeralaCityData"
import TamilNaduCityData from "../../data/TamilNaduCityData"
import GoaCityData from "../../data/GoaCityData"
import WestBengalCityData from "../../data/WestBengalCityData"

const data = [
    {
        id: 1,
        name: "Mohd Bilal",
        review:
            "Purchased a plot through MAQ Group and experience was great. Deal closed within the time and all paper work completed by them.",
        image: AVTR1
    },
    {
        id: 2,
        name: "Mohd Bilal",
        review:
            "Sold my house by MAQ THE WORLD BUILDER & DEVELOPER in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through MAQ.",
        image: AVTR2
    },
    {
        id: 3,
        name: "Mohd Bilal",
        review:
            "Purchased a commercial property in Noida through MAQ Group. Deal was done in proper manner and transparent way. great experience.",
        image: AVTR3
    },
    {
        id: 4,
        name: "Mohd Bilal",
        review:
            "Sold my house by MAQ THE WORLD BUILDER & DEVELOPER in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through MAQ.",
        image: AVTR4
    },
    {
        id: 5,
        name: "Mohd Bilal",
        review:
            "Sold my house by MAQ THE WORLD BUILDER & DEVELOPER in 2016. really good experience. All the payment received in proper way and timely. Transfer process was very easy through MAQ.",
        image: AVTR4
    },
    {
        id: 6,
        name: "Mohd Bilal",
        review:
            "Purchased a commercial property in Noida through MAQ Group. Deal was done in proper manner and transparent way. great experience.",
        image: AVTR4
    }
];

const RealEstateHome = () => {
    return (
        <>
           
            {/* <RealEstateHeader /> */}
            <MAQHeader />

            <Box className='topdiv_Builders'>
                <img src={Banner4} alt="img" />
                <div className="Buildershead">
                    <h1>MAQ The World Builders & Developers</h1>
                </div>
            </Box>

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">United Arab Emirates (UAE)</h1>
                <div className="city_countrydiv_slide">
                    {UAECountryCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4 bg-white" style={{letterSpacing:'3px'}}>INDIA</h1>
                <h1 className=" fw-bold py-4 mt-5">MAHARASHTRA</h1>
                <div className="city_countrydiv_slide">
                    {MaharashtraCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">GOA</h1>
                <div className="city_countrydiv_slide">
                    {GoaCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Gujrat</h1>
                <div className="city_countrydiv_slide">
                    {GujratCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Karnataka</h1>
                <div className="city_countrydiv_slide">
                    {KarnatakaCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">RAJASTHAN</h1>
                <div className="city_countrydiv_slide">
                    {RajisthanCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">HARYANA</h1>
                <div className="city_countrydiv_slide">
                    {HaryanaCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Delhi</h1>
                <div className="city_countrydiv_slide">
                    {DelhiCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Uttar Pradesh</h1>
                <div className="city_countrydiv_slide">
                    {UttarPradeshCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >


            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Andhra pradesh</h1>
                <div className="city_countrydiv_slide">
                    {AndhraPradeshCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Telangana</h1>
                <div className="city_countrydiv_slide">
                    {TelanganaCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">kerala</h1>
                <div className="city_countrydiv_slide">
                    {KeralaCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Tamil Nadu</h1>
                <div className="city_countrydiv_slide">
                    {TamilNaduCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">UTTARAKHAND</h1>
                <div className="city_countrydiv_slide">
                    {UttarakhandCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div > 

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Punjab</h1>
                <div className="city_countrydiv_slide">
                    {PunjabCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Madhya Pradesh</h1>
                <div className="city_countrydiv_slide">
                    {MadhyaPradeshCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Chattisgarh</h1>
                <div className="city_countrydiv_slide">
                    {ChattisgarhCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">Bihar</h1>
                <div className="city_countrydiv_slide">
                    {BiharCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >

            <div className="city_countrydiv">
                <h1 className=" fw-bold py-4">west bengal</h1>
                <div className="city_countrydiv_slide">
                    {WestBengalCityData?.map((item, index) => (
                        <Link>
                            <div key={item.id} className="city_countrydiv_life_Slider">
                                <img src={item.imgSrc} alt={`img-${item.id}`} />
                                <h4>{item.heading}</h4>
                            </div>
                        </Link>

                    ))}
                </div >
                {/* <Button className="explorebtn">Explore More</Button> */}
            </div >



            {/* <TopSliderAllMax images={MarxGroupGalleryData} /> */}

            <Box className="fantasy row">
                <div className="textfantasy col-lg-6 col-12">
                    <div className="small_logo_div">
                        <img src={smallLogo} alt="img" />
                    </div>
                    <h5>MAQ THE WORLD BUILDER & DEVELOPER </h5>
                    <h6>LET THE EXPERTS HELP YOU</h6>
                    <h1>Taking care of your MAQ THE WORLD BUILDER & DEVELOPER needs is what we do best</h1>
                    <Button>TALK TO US</Button>
                </div>
                <div className="imggdiv col-lg-6 col-12">
                    <img src={home1} alt="img" />
                </div>
            </Box>

            <section id="about">
                <Box className="fantasy secondbgcolor row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={home2} alt="img" />
                    </div>

                    <div className="textfantasy col-lg-6 col-12">
                        <p>We are a MAQ THE WORLD BUILDER & DEVELOPER situated in Noida and serving from 2010. We deals in buying or selling any kind of property like Residential, Commercial or Industrial in Noida, Greater Noida, Noida Extension & Yamuna Authority. We also deals in Renting.</p>
                        <p>We are best in selling & buying Noida Authority Plots, Houses and Villas. We are expert in Kisan Quota Sectors.</p>
                        <p>You can trust us for your property related service, We deals in transparent way.</p>
                        <p>We will also help you in property documentation related services, like Sale Purchase agreements, TM, Lease Deeds, Transfer Deeds, Registry, Mutation etc.</p>
                        <Button>GET IN TOUCH</Button>
                    </div>

                </Box>
            </section>

            <section id="selling">
                <Box className="fantasy row">
                    <div className="imggdiv col-lg-6 col-12">
                        <img src={innerhome2} alt="img" />
                    </div>

                    <div className="textfantasy col-lg-6 col-12">
                        <h1>Looking to sale your property?</h1>
                        <p>If you want to sale your property you can reach us, we will sale your property with best price and transfer process will be so easy.</p>
                        <Button>CONTACT US</Button>
                    </div>

                </Box>
            </section>

            <section id="buying">
                <Box className="fantasy row">
                    <div className="textfantasy col-lg-6 col-12">
                        <h1>Looking to buy a house or plot??</h1>
                        <p>If you are looking to buy a independent house or plot in Noida/Greater Noida/Noida Extension or Yamuna Authority, You are at Right Place.</p>
                        <Button>CONTACT US</Button>
                    </div>

                    <div className="imggdiv col-lg-6 col-12">
                        <img src={homekey} alt="img" />
                    </div>

                </Box>
            </section>

            <section id="dealing_sectors">
                <Box className='PlotsHouse'>
                    <div className="PlotsHouse_heading">
                        <h3 className="ps-2"><BsCaretDownFill className=" fs-1" /> Residential Plots & Independent House are available  </h3>
                    </div>
                    <div className="divhouselocation ps-3">
                        <Link className="linktag" to={'/home_area'}>
                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home1} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-63/A</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className="linktag" to={'/'}>
                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home2} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-130</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className="linktag" to={'/'}>

                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home4} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-130</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className="linktag" to={'/'}>

                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home5} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-63/A</h5>
                                </div>

                            </div>

                        </Link>
                        <Link className="linktag" to={'/'}>


                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home6} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-63/A</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className="linktag" to={'/'}>

                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={home7} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-63/A</h5>
                                </div>

                            </div>
                        </Link>
                        <Link className="linktag" to={'/'}>
                            <div className="PlotsHouse_area">
                                <div>
                                    <img src={homeImg} alt="img" />
                                </div>
                                <div>
                                    <h5>SECTOR-63/A</h5>
                                </div>

                            </div>
                        </Link>

                    </div>
                </Box>
            </section>

            <section id="why_choose_us">
                <Box className="Why_choose_us">
                    <h2>Why choose us</h2>
                    <div className="row ps-3 me-0">
                        <div className="chooseDiv">
                            <div>
                                <img src={ex1} alt="img" />
                            </div>
                            <div className="textdiv">
                                <h5>12+ years experience in the industry</h5>
                            </div>

                        </div>

                        <div className="chooseDiv">
                            <div>
                                <img src={ex2} alt="img" />
                            </div>
                            <div className="textdiv">
                                <h5>Top 10 MAQ THE WORLD BUILDER & DEVELOPER in Noida</h5>
                            </div>

                        </div>

                        <div className="chooseDiv">
                            <div>
                                <img src={ex3} alt="img" />
                            </div>
                            <div className="textdiv">
                                <h5>Works with genuine properties only</h5>
                            </div>

                        </div>

                        <div className="chooseDiv">
                            <div>
                                <img className="imggg" src={ex4} alt="img" />
                            </div>
                            <div className="textdiv">
                                <h5>All the deals done in transprent way</h5>
                            </div>

                        </div>
                    </div>
                </Box>
            </section>

            <section id="testimonials">
                <h2 className="testimonials_h2">Client Testimonials</h2>
                <Swiper
                    centeredSlides={false}
                    spaceBetween={30}
                    // slidesPerView={3}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="container testimonials__container"
                    breakpoints={{
                        // when window width is >= 640px
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                        // when window width is >= 768px
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        // when window width is >= 1024px
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                    }}
                >
                    {data.map(({ id, name, review, image }) => {
                        return (
                            <SwiperSlide key={id} className="testimonial">
                                <p className="client__review">
                                    {review}
                                </p>
                                <h5 className="client__name"> - {name}</h5>

                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </section>

            <RealEstateFooter />

        </>
    )
}

export default RealEstateHome