import React, { useState } from "react";
import "./MAQFooterStyle.css";
import { Link } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";

const contacts = [
  {
    type: 'whatsapp',
    value: '+91 8851746286'
  },
  // {
  //   type: 'whatsapp',
  //   value: '+91 8851746286'
  // },
  // {
  //   type: 'whatsapp',
  //   value: '+91 8851746286'
  // }
];

const mail = [
  {
    recipient: 'info@abariswebsite.com',
    subject: '',
    body: ''
  }
  // , {
  //   recipient: 'Bookings@abariswebsite.com',
  //   subject: '',
  //   body: ''
  // }, {
  //   recipient: 'Queries@abariswebsite.com',
  //   subject: '',
  //   body: ''
  // }
];

function MAQFooter() {
  const [openSubscribe, setOpenSubscribe] = useState(false)
  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  const toggleSubscribeBox = () => {
    console.log('Toggling subscribe box');
    setOpenSubscribe(!openSubscribe);
  };

  const emailClick = (recipient, subject, body) => {
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const handleContactClick = (phoneNumber) => {
    window.location.href = `whatsapp://send?phone=${encodeURIComponent(phoneNumber)}`;
  };

  return (
    <>

      <div className="footerdiv">
        <div className="topfooterblock">
          <div className="container mainwapdiv">
            <div className="aboutblock">
              <h2>About</h2>
              <ul>
                <li>
                  <Link to={'/our-service'} className="">
                    Services
                  </Link>
                </li>

                <li>
                  <Link className="" to="/our-client">
                    Our Clients
                  </Link>
                </li>
                <li>
                  <Link className="" to={'/testimonials'}>
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link className="" to="/contactus">
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link className="" to="/jobs">
                    Jobs
                  </Link>
                </li>
                <li>
                  <Link className="" to="/faq">
                    FAQs
                  </Link>
                </li>
                <li>
                  <Link className="" to="/blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to='/site-map' className="">
                    SiteMap
                  </Link>
                </li>

                <li>
                  <Link className="" to="/video-gallery">
                    Video Gallery
                  </Link>
                </li>

                <li>
                  <Link className="" to={'/our_team'}>
                    Our Team
                  </Link>
                </li>

                {/* <li>
                  <Link className="" to={'/advertise-here'}>
                    Advertise Here
                  </Link>
                </li> */}

                <li>
                  <Link onClick={toggleSubscribeBox} className="">
                    Subscribe letter
                  </Link>
                  <Box className={openSubscribe ? 'visiblesubscribe' : 'hiddensubscribe'}>
                    <div className="emailtoggle">
                      <TextField placeholder="email" />
                      <Button variant="contained">Submit</Button>
                    </div>
                  </Box>
                </li>

                {/* <li>
                  <Link className="" to={'/buy-our-franchise'}>
                    Buy Our Franchise
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="secondblock">
              <h3>Contact Us</h3>
              <div className="block">
                <h2>Mobile : </h2>
                <div className="main_ptagg">
                  {contacts.map((contact, index) => (
                    <p key={index} onClick={() => handleContactClick(contact.value)}>
                      {contact.value},
                    </p>
                  ))}
                </div>
              </div>
              <div className="block">
                <h2>Email : </h2>
                <div className="main_ptagg">
                  {mail.map((email, index) => (
                    <p key={index}
                      onClick={() => emailClick(email.recipient, email.subject, email.body)}
                    >
                      {email.recipient},
                    </p>
                  ))}
                </div>
              </div>
              <div className="block">
                <h2>Address : </h2>
                <div className="main_ptagg">
                  <p>
                    98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="midfooterblock">
          <div className="block text-center">
            <h2 className="">Follow Us on</h2>
            <div className="innersocial">
              <Link className="" to="">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                  alt="facebook"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                  alt="linkedin"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                  alt="instagram"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                  alt="youTube"
                  style={{ width: 32, height: 32 }}
                />
              </Link>

            </div>
          </div>
        </div>
        <div className="fotrblack">
          <div className="copyrightblock">
            <div className="maincopyrightwapper">
              <div className="privacypolicy pt-3">
                <Link className="" to="/privacy-policy"> Privacy Policy </Link>
                <span>|</span>
                <Link to="/term-condition"> Terms & Conditions </Link>
              </div>
            </div>
            <div className="copy mt-2">
              Copyright © 2024 MAQ THE WORLD GROUP. All Rights Reserved.
            </div>
            <div className="designdevelop d-flex justify-content-center mt-2">
              <p>Powered By : <Link to={'https://www.abarissoftech.com/'}>Abaris Softech</Link> </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MAQFooter;
