import people1 from '../assest/BuildersDeveloper/Jaipur.jpg'
import people2 from '../assest/BuildersDeveloper/udaipur.jpg'
import people3 from '../assest/BuildersDeveloper/kota-city.jpg'
import people4 from '../assest/BuildersDeveloper/ajmer.jpg'


const RajisthanCityData = [
    {
        id: 1,
        heading: 'Jaipur',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Udaipur',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Kota',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Ajmer',
        imgSrc: people4,
    },
    

];
export default RajisthanCityData