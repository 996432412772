import { Box, Button } from "@mui/material"
import marxWeddinglogo from '../../assest/MAQlogo.jpeg'
import home5 from '../../assest/home5.webp'
import home1 from '../../assest/home1.webp'
import innerhome1 from '../../assest/innerhome1.webp'
import keyhome2 from '../../assest/keyhome2.webp'
import home2 from '../../assest/home2.webp'
import homekey from '../../assest/homekey.webp'
import key from '../../assest/key.webp'
import rate from '../../assest/rate.webp'
import shield from '../../assest/shield.webp'
import order from '../../assest/order.webp'
import '../../styles/HomeAreaStyle.css'
import { MdEmail, MdOutlineLanguage, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import home8 from '../../assest/home8.webp'
import footbg from '../../assest/footbg.webp'


const HomeArea = () => {
    return (
        <>
            <Box className='BG_imge'>
                <img src={marxWeddinglogo} alt="img" />
                <h1>SECTOR-63/A</h1>
                <h6>Residential Plots & House</h6>
                <div className="address">
                    <div className="namework">
                        <h2>MAQ THE WORLD BUILDER & DEVELOPER</h2>
                        <p>Builder & Developer </p>
                    </div>
                    <div>
                        <p>Address</p>
                        <p>PKB-37, SECTOR-122, NOIDA</p>
                    </div>

                </div>
            </Box>

            <Box className="fantasy row">
                <div className="imggdiv col-lg-6 col-12">
                    <img src={home5} alt="img" />
                </div>

                <div className="textfantasy col-lg-6 col-12">
                    <h1>SECTOR-63/A</h1>
                    <h2>Noida</h2>
                    <p>Noida Authority Allotted Sector 63A is a popular locality situated in Noida and the pin code of this locality is 201307.Some of the nearby localities to Sector 63A are Sector 65,Sector 63,Chhijarsi Colony, NH-24. The road quality is very good as shown by the average user's rating of 5.0</p>
                    <div className="details_work">
                        <div>
                            <img src={key} alt="img" />
                            <h1>500+</h1>
                            <p>Plots Sold</p>
                        </div>
                        <div>
                            <img src={rate} alt="img" />
                            <h1>450+</h1>
                            <p>Customers Who Are Pleased</p>
                        </div>

                    </div>
                </div>
            </Box>

            <Box className="fantasy row">

                <div className="textfantasy col-lg-6 col-12">
                    <h1 className=" mb-5">INDEPENDENT HOUSE</h1>
                    <h2>AREA - 53 SQMTR</h2>
                    <h3 className=" fw-bold mb-5">STILT + 2 FLOORS 12 Meter Wide Road East Facing</h3>
                    <h2>PRICE - 1.05 CR.</h2>
                    <Button className="mt-5 buttonclick">Interested ? / Reach Us</Button>
                </div>

                <div className="imggdiv col-lg-6 col-12">
                    <img src={home1} alt="img" />
                </div>

            </Box>

            <Box className="fantasy row">
                <div className="imggdiv col-lg-6 col-12">
                    <img src={innerhome1} alt="img" />
                </div>

                <div className="textfantasy col-lg-6 col-12">
                    <h1 className=" mb-5">INDEPENDENT HOUSE</h1>
                    <h2>AREA - 57 SQMTR</h2>
                    <h3 className=" fw-bold mb-5">Stilt + 1 Floor Under Construction 12 Meter Road Green Belt Facing East Facing</h3>
                    <h2>PRICE - 1.05 CR.</h2>
                    <Button className="mt-5 buttonclick">Interested ? / Reach Us</Button>
                </div>
            </Box>

            <Box className="fantasy row">

                <div className="textfantasy col-lg-6 col-12">
                    <h1 className=" mb-5">EMPTY PLOT</h1>
                    <h2>AREA - 80 SQMTR</h2>
                    <h3 className=" fw-bold mb-5">18 Meter Road East Facing</h3>
                    <h2>PRICE - 1.15 CR.</h2>
                    <Button className="mt-5 buttonclick">Interested ? / Reach Us</Button>
                </div>

                <div className="imggdiv col-lg-6 col-12">
                    <img src={keyhome2} alt="img" />
                </div>

            </Box>

            <Box className="fantasy row">
                <div className="imggdiv col-lg-6 col-12">
                    <img src={home2} alt="img" />
                </div>

                <div className="textfantasy col-lg-6 col-12">
                    <h1 className=" mb-5">COMPLETION PLOT</h1>
                    <h2>AREA - 173 SQMTR</h2>
                    <h3 className=" fw-bold mb-5">12 Meter Road Green Belt Facing South Facing</h3>
                    <h2>PRICE - 2.20 CR.</h2>
                    <Button className="mt-5 buttonclick">Interested ? / Reach Us</Button>
                </div>
            </Box>

            <Box className="fantasy row">

                <div className="textfantasy col-lg-6 col-12">
                    <h1 className=" mb-5">COMPLETION PLOT</h1>
                    <h2>AREA - 225 SQMTR</h2>
                    <h3 className=" fw-bold mb-5">FNG Facing Green Belt Facing12 Meter Road West Facing</h3>
                    <h2>PRICE - 3.25 CR.</h2>
                    <Button className="mt-5 buttonclick">Interested ? / Reach Us</Button>
                </div>

                <div className="imggdiv col-lg-6 col-12">
                    <img src={homekey} alt="img" />
                </div>

            </Box>

            <div className="our_service">
                <div>
                    <h1>Our Service</h1>
                    <h2>We Offer</h2>
                    <p>We are best in Building & Developer. we will close your deal in a very profesional way.</p>
                </div>
                <div>
                    <div className="imgicondiv">
                        <img src={order} alt="img" />
                        <h3>Advisory & Consultant</h3>
                    </div>
                    <p>We will give proper advice when you need</p>
                </div>
                <div>
                    <div className="imgicondiv">
                        <img src={shield} alt="img" />
                        <h3>Documentation</h3>
                    </div>
                    <p>Complete documentation from starting</p>
                </div>

            </div>
            <Box className='homecoverimgdiv'>
                <img src={home8} alt="img" />
            </Box>

            <Box className='row align-items-center bg-primary-subtle overflow-hidden me-0'>
                <div className=" col-lg-6 col-12 bgimgecontact">
                    <img src={marxWeddinglogo} alt="img" />
                    <h1>MAQ THE WORLD BUILDER & DEVELOPER</h1>
                    <p>Builder & Developer Company</p>
                </div>
                <div className=" col-lg-6 col-12 ContactUs">
                    <h1 className="h1tag">Contact <span>Us</span></h1>

                    <div className="contactdiv">
                        <div className="icondiv">
                            <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                        </div>
                        <div>
                            <h6>Phone</h6>
                            <p>8851746286</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="icondiv">
                            <p> <MdEmail className=" fs-1 p-2" /></p>
                        </div>
                        <div>
                            <h6>E-Mail</h6>
                            <p>MAQe@gmail.com</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="icondiv">
                            <p> <MdOutlineLanguage className=" fs-1 p-2" /></p>
                        </div>
                        <div>
                            <h6>Web</h6>
                            <p>www.abarissoftech.com</p>
                        </div>

                    </div>

                    <div className="contactdiv">
                        <div className="icondiv">
                            <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                        </div>
                        <div>
                            <h6>Address</h6>
                            <p>98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025</p>
                        </div>

                    </div>


                </div>
            </Box>

            <Box className='thankydiv'>
                <img src={footbg} alt="img" />
                <div className="thnks">
                    <h1>THANK YOU</h1>
                    <p>Thank you for your time, and we hope to see you again soon.</p>
                </div>
            </Box>

        </>
    )
}

export default HomeArea