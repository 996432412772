

import mrDevashish from '../../assest/sld3.webp'
import mrDevashishfounder from '../../assest/sld3.webp'
import mrAnand from '../../assest/sld3.webp'
import mrSanday from '../../assest/sld3.webp'
import { Box } from "@mui/material"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marxacedmy from '../../assest/MAQlogo.jpeg'
import marxMedia from '../../assest/MAQlogo.jpeg'
import marxFashionlogo from '../../assest/MAQlogo.jpeg'
import marxFoundlogo from '../../assest/MAQlogo.jpeg'
import { Link } from "react-router-dom";
import '../../styles/marxGroupStyle.css'
import marxEventLogo from '../../assest/MAQlogo.jpeg'
import realState from '../../assest/MAQlogo.jpeg'
import marxAgroFood from '../../assest/MAQlogo.jpeg'
import marxMotionPic from '../../assest/MAQlogo.jpeg'
import RunningSlide from '../../common/RunningSlideAllMarx/RunningSlide'
import { useEffect } from 'react'
import MarxGroupGalleryData from '../../data/MarxGroupGalleryData'
import MAQHeader from '../../common/MAQ/header/MAQHeader'
import MAQFooter from '../../common/MAQ/footer/MAQFooter'




const Home = () => {
    // useEffect(() => {
    //     document.title = "MAQ THE WORLD GROUP";
    // }, []);


    const latestNews = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 3,
                },
            }
        ],
    };

    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            <MAQHeader />
            <div className="main-div">
                <Box className="maindiv-box row">
                    <div className="alltabs col-3">
                        <p className='mange-tab' onClick={() => openNewTab('/maq_builder_and_developer')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxEventLogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>
                                MAQ THE WORLD BUILDER & DEVELOPER
                            </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/NGO_trust')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxMedia}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ NGO Trust</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/restaurant')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFoundlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Franchise</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_capital_finance')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Capital Finance </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_international_schools')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ International Schools</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://maqfoodstuff.com/')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Food Stuff</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_marine_logistics')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Marine Logistics</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://cosmetics.maqtheworldgroup.com/')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Cosmetics</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://fashions.maqtheworldgroup.com')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Fashions </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://ayurvedic.maqtheworldgroup.com')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Ayurveda </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_infra_mart')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Infra Mart</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://projects.maqtheworldgroup.com/')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Projects</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('https://b2cfintech.maqtheworldgroup.com/')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Fintech</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_hospital')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Hospitals</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_wilmar')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Wilmar</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_defence')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Defence</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_green_energy')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Green Energy</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/maq_agri_logistic')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Agri Logistics</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/al_maq_tour_travel')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Al MAQ Tour & Travels (Haj & Umrah)</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Solar</Link>
                        </p>



                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Marriage</Link>
                        </p>


                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Martial Arts</Link>
                        </p>




                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MIS Furniture & Decorators</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Shipping All World</Link>
                        </p>


                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Realty</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ International Payment Gateway</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Data Centre</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Power</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Hotels</Link>
                        </p>


                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ THE WORLD UNIVERSITY</Link>
                        </p>



                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Port</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Logistics</Link>
                        </p>



                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Total Gas</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Transmission</Link>
                        </p>



                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Cement</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Airport</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Corporate</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Solar</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Agri Fresh</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Natural Resources</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Ganga Expressway</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ Bhagalpur West Power</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ SuperMarket</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Mall</Link>
                        </p>


                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Infrastructure</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/mare')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>MAQ The World Import & Export</Link>
                        </p>

                    </div>
                    <div className="tabpanels col-8">
                        <div>
                            {/* <div className="about-marx-entmnt">
                                <h1>Welcome</h1>
                                <p>The Name Of Company MAQ The World Group Has Been Inspired From The Famous German Philospher, Political Economist, Historian, Political Theorist Sociologist, Communist & Revolutionary Karl Marx As He Ruled The Entire World In 18th Century With His Modified Ideas, Hard Work, Determination & Will Power To Achive Goals In Life. Which Inspires Me To Do Something In Life As An Inspiration.</p>
                            </div> */}
                            <div className="about-marx-entmnt">
                                <h1>About Us</h1>
                                <p>
                                    MAQ The World Group has Emerged As one of the Biggest & Powerful Conglomerate in India which has been diversified into different sectors such as : MAQ Real Estate , MAQ NGO Trust, MAQ Franchise, MAQ Marriage, MAQ Martial Arts, MAQ Capital, MAQ Food Stuff, Pan - India & Worldwide Level Respectively.
                                </p>
                            </div>

                            <div className="about-marx-entmnt">
                                <h1>Why to Choose Us</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                            <div style={{ color: 'white', borderTop: '1px solid white' }}></div>
                            <div className="founder-chairmsg pt-4">

                                <div className="foundermsg">
                                    <img src={mrDevashishfounder} alt="img" />
                                    <p className=" fw-bold fs-4">Mr. Devashish Chaurdhary</p>
                                    <h1>Founder / Chairperson</h1>
                                    <p>(Message)</p>
                                    <p></p>
                                </div>
                            </div>

                            <div className="board-director" style={{ borderTop: '2px solid white' }}>
                                <h1>Board of Directors</h1>
                                <div className="each-director">
                                    <div className="">
                                        <img src={mrAnand} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Anand Chaurdhary</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrSanday} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Sanjay Dubey</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrDevashish} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Sanjay Bhandari</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="GALLERY" images={MarxGroupGalleryData} />

            <div className="latestnews-sldddiv">
                <h1 className=' text-white fw-bold ms-3'>UP-COMING VENTURES</h1>
                <div className="divlatest">
                    <Slider className="slidrbox" {...latestNews}>
                        <div className="div-slide">
                            <img src={marxacedmy} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxFashionlogo} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxMotionPic} alt="img" />
                        </div>
                        <div className="div-slide">
                            <img src={marxAgroFood} alt="img" />
                        </div>

                    </Slider>
                </div>
            </div>

            <MAQFooter />
        </>
    )
}

export default Home
