import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"
import { Box, Button } from "@mui/material"
import '../../styles/MAQMarineLogisticsHomeStyle.css'
import imgtop from '../../assest/audience-1850.webp'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OurPartnerData from "../../data/OurPartnerData"

import { Link } from "react-router-dom"
import MarineLogisticTopData from "../../data/MarineLogisticTopData"

import marine1 from '../../assest/marine logistic/100.jpg'
import marine2 from '../../assest/marine logistic/102.webp'
import marine3 from '../../assest/marine logistic/104.webp'
import marine4 from '../../assest/marine logistic/85.jpg'
import marine5 from '../../assest/marine logistic/90.jpg'
import marine6 from '../../assest/marine logistic/93.jpg'
import marine7 from '../../assest/marine logistic/97.jpg'
import newsroom1 from '../../assest/marine logistic/7.jpg'
import newsroom2 from '../../assest/marine logistic/16.jpg'
import newsroom3 from '../../assest/marine logistic/76.jpeg'

import polygon from '../../assest/marine logistic/polygon.png'
import arrivals from '../../assest/marine logistic/arrivals.png'
import sugarcube from '../../assest/marine logistic/sugar-cube.png'

const MAQMarineLogisticsHome = () => {
    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        MoreInformation: '',
        Service: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        MoreInformation: yup.string().required('More Information is Requird!'),
        Service: yup.string().required('Service is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    centerMode: true,
                    centerPadding: '1px',
                },
            },
            {
                breakpoint: 9999, // A large value to ensure the last breakpoint always applies
                settings: {
                    slidesToShow: 5,
                },
            }
        ],
    };
    return (
        <>
            <MAQHeader />


            <Box className='logistic_experience'>
                <img src={imgtop} alt="img" />
                <div className="row marinemaindiv me-0">
                    <div className="col-lg-6 col-12 logisticexperience_detail">
                        <h1>Simplifying Your Logistic Experience</h1>
                        <p>Bulk or breakbulk, Freight Forwarding or Warehousing, MAQ THE WORLD MARINE LOGISTICS simplifies processes and exceeds expectations!</p>
                    </div>
                    <div className="col-lg-6 col-12 logisticexperience_detail">
                        <div className="card-body row mt-4">
                            <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                                {({ errors, touched, resetForm }) => {
                                    return <Form className="row formdiv WarehousingLogistic">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='FullName' placeholder='Name'
                                                    className={
                                                        `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                            </div>

                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='EmailID' placeholder='Email'
                                                    className={
                                                        `form-control
                                                    ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                            </div>
                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='Phone' placeholder='Phone'
                                                    className={
                                                        `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                            </div>

                                            <div className="col-lg-6 col-12 mt-2">

                                                <Field type='text' name='MoreInformation' placeholder='Please Provide More Information'
                                                    className={
                                                        `form-control
                                                    ${errors.MoreInformation && touched.MoreInformation ? ' is-invalid' : ''}`
                                                    }
                                                />
                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="MoreInformation" /></p>
                                            </div>

                                            <div className="col-12 mt-2">
                                                <Field component='select' name='Service' className={
                                                    `form-control
                                                  ${errors.Service && touched.Service ? ' is-invalid' : ''}`
                                                }>
                                                    <option >What Service Are You Interested In?</option>
                                                    <option value={71}>Yes</option>
                                                    <option value={82}>No</option>
                                                </Field>

                                                <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Service" /></p>
                                            </div>


                                            <div className="col-12">
                                                <button type="submit" className="btn btn-success w-100" style={{ margin: "10px 0" }}>
                                                    Send
                                                </button>

                                            </div>
                                        </div>

                                    </Form>
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Box>

            <Box className='logistic_topdiv'>
                <h1>South Africa's Leading Freight Forwarder!</h1>
                <p>MAQ THE WORLD MARINE LOGISTICS & Logistics is a leading, internationally recognised freight forwarding business.</p>
                <p>We specialise in custom supply chain solutions to fit your needs and solve all your logistics problems!</p>
                <p>MAQ THE WORLD MARINE LOGISTICS, has the capacity, resources and infrastructure to deliver the logistics services required for doing business in Africa and globally.</p>
                <Button>More About MAQ THE WORLD MARINE LOGISTICS</Button>
            </Box>

            <TopSliderAllMax images={MarineLogisticTopData} />

            <Box className='logistic_topdiv'>
                <h1>Why Choose MAQ THE WORLD MARINE LOGISTICS?</h1>
                <p>Our team of experts are proficient at designing bespoke, value-added solutions that meet commercial and logistics requirements across the globe.</p>
                <p>From our experience, we can handle any product and ship any cargo that you need moved! We, at MAQ THE WORLD MARINE LOGISTICS & Logistics have the facilities, competency and ability to handle your cargo or product safely and affordably!</p>
            </Box>


            <Box className='logistic_clients'>
                <h1>Clients</h1>
                <p>Here are some of our clients that use MAQ THE WORLD MARINE LOGISTICS & Logistics for all their logistic needs:</p>

                <div className="our_partner">
                    {/* <h1 className='fw-bold ms-3 text-white'>{title}</h1> */}
                    <Slider {...settings}>
                        {OurPartnerData?.map((item, index) => (
                            <div key={item.id} className="item-topimg">
                                <img style={{ width: '200px', height: '300px', objectFit: 'contain' }} src={item.imgSrc} alt={`img-${item.id}`} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </Box>

            <Box className='sectors_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">Sectors we work In</h1>
                    <p>As we specialise in custom-built logistic solutions to fit your needs, we can fulfil any project demands. This means that we have experience in various sectors of the logistics and storage industry.</p>
                </div>

                <div className="row gap-4 sector_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine1} alt="img" />
                            </div>
                            <div>
                                <h6>Supply Chain Solutions</h6>
                                <p>Start to finish, MAQ THE WORLD MARINE LOGISTICS designs and develops custom supply chain solutions to bridge any freight challenge.</p>
                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine2} alt="img" />
                            </div>
                            <div>
                                <h6>International Forwarding</h6>
                                <p>Our extensive experience in ocean, air and road freight operations ensure that every shipment arrives promptly and in perfect condition.</p>
                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine3} alt="img" />
                            </div>
                            <div>
                                <h6>Metals & Minerals</h6>
                                <p>We base our supply chain on matching our customers’ commodity flows and volume with supply chain ownership that delivers the correct capacity.</p>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={marine4} alt="img" />
                            </div>
                            <div>
                                <h6>Warehousing & Storage</h6>
                                <p>MAQ THE WORLD MARINE LOGISTICS is active in 23+ African countries with direct access to approximately 500 000 m² of group warehousing space.</p>
                            </div>

                        </div>
                    </Link>
                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={marine6} alt="img" />
                            </div>
                            <div>
                                <h6>Ships Agency</h6>
                                <p>Our highly skilled and experienced ships agency team will oversee and coordinate all port aspects. We provide you with a full spectrum of agency services.</p>
                            </div>

                        </div>

                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={marine5} alt="img" />
                            </div>
                            <div>
                                <h6>Mining Logistics</h6>
                                <p>MAQ THE WORLD MARINE LOGISTICS provides a wide range of bespoke solutions for logistics challenges in the mining industry.</p>
                            </div>

                        </div>

                    </Link>


                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>

                        <div className="sector_areadiv">
                            <div>
                                <img src={marine7} alt="img" />
                            </div>
                            <div>
                                <h6>Project Cargo</h6>
                                <p>Project cargo is one of our specialities. We are experienced in handled the largest of machinery, door-to-door!</p>
                            </div>

                        </div>

                    </Link>

                </div>

                <div className="ViewServices_btn">
                    <Button>View Services</Button>
                </div>

            </Box>


            <Box className='CustomisedSolutions_div'>
                <div className="logistic_topdiv">
                    <h1>Customised Solutions To Fit Your Logistic Needs!</h1>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12 p-0">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={polygon} alt="img" />
                            </div>
                            <div>
                                <h5>Design</h5>
                                <p>We understand our clients' business and specialise in creating bespoke solutions for their specific requirements.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 p-0">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={arrivals} alt="img" />
                            </div>
                            <div>
                                <h5>Deliver</h5>
                                <p>We go the extra mile to deliver on our clients’ expected solutions. Therefore, our team provides excellent service, along with clear communication and full transparency.</p>
                            </div>
                        </div>
                    </div>

                    <div className=" col-lg-4 col-12 p-0">
                        <div className="our_solutioncustomize">
                            <div className="iconimgdiv">
                                <img src={sugarcube} alt="img" />
                            </div>
                            <div>
                                <h5>Differentiate</h5>
                                <p>Our aim is to differentiate our supply chain offering and provide you with a competitive advantage for your route to market.</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="contactSolutions_btn">
                    <Button>Contact Us Now</Button>
                </div>
            </Box>


            <Box className='sectors_weworkIn MarineLatestProjects'>
                <div className="logistic_topdiv">
                    <h1 className="">MAQ THE WORLD MARINE LOGISTICS Latest Projects</h1>
                </div>

                <div className="row gap-4 sector_alldetail ">

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine1} alt="img" />
                            </div>
                            <div>
                                <h6>Magnetite/Iron Ore Bulk Cargo</h6>
                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine2} alt="img" />
                            </div>
                            <div>
                                <h6>Container Depot, Durban</h6>
                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={marine3} alt="img" />
                            </div>
                            <div>
                                <h6>Paper Reels, Durban.</h6>
                            </div>

                        </div>
                    </Link>

                </div>

                <div className="ViewServices_btn">
                    <Button>view projects</Button>
                </div>

            </Box>

            <Box className='sectors_weworkIn'>
                <div className="logistic_topdiv">
                    <h1 className="">MAQ THE WORLD MARINE LOGISTICS Newsroom</h1>
                </div>

                <div className="row gap-4 sector_alldetail">

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={newsroom3} alt="img" />
                            </div>
                            <div>
                                <h6>The South African Rail Network Rejuvenation: A New Era for Logistics, Manufacturing, and Mining</h6>
                            </div>

                        </div>
                    </Link>

                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={newsroom1} alt="img" />
                            </div>
                            <div>
                                <h6>What Is The Supply Chain?</h6>
                            </div>

                        </div>
                    </Link>


                    <Link className="col-lg-3 col-12 linktag_sector" to={'/'}>
                        <div className="sector_areadiv">
                            <div>
                                <img src={newsroom2} alt="img" />
                            </div>
                            <div>
                                <h6>What Is International Freight Forwarding And How Does it Work?</h6>
                            </div>

                        </div>
                    </Link>

                </div>

                <div className="ViewServices_btn">
                    <Button>read more</Button>
                </div>

            </Box>

            <Box className='logistic_clients'>
                <h1>Contact MAQ THE WORLD MARINE LOGISTICS Today</h1>
                <p>Partner with MAQ THE WORLD MARINE LOGISTICS & Logistics for all your shipping and logistics needs. Contact us today to discuss how we can help your business thrive.</p>

                <div className="card-body row mt-4">
                    <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                        {({ errors, touched, resetForm }) => {
                            return <Form className="row justify-content-center formdiv">

                                <div className=" col-lg-7 col-12">
                                    <div className=" mt-4">

                                        <Field type='text' name='FullName' placeholder='Name'
                                            className={
                                                `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                    </div>

                                    <div className=" mt-4">

                                        <Field type='text' name='EmailID' placeholder='Email'
                                            className={
                                                `form-control
                                                    ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                    </div>
                                    <div className=" mt-4">

                                        <Field type='text' name='Phone' placeholder='Phone'
                                            className={
                                                `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                    </div>

                                    <div className=" mt-4">

                                        <Field type='text' name='MoreInformation' placeholder='Please Provide More Information'
                                            className={
                                                `form-control
                                                    ${errors.MoreInformation && touched.MoreInformation ? ' is-invalid' : ''}`
                                            }
                                        />
                                        <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="MoreInformation" /></p>
                                    </div>

                                    <div className=" mt-4">
                                        <Field component='select' name='Service' className={
                                            `form-control
                                                  ${errors.Service && touched.Service ? ' is-invalid' : ''}`
                                        }>
                                            <option >What Service Are You Interested In?</option>
                                            <option value={71}>Yes</option>
                                            <option value={82}>No</option>
                                        </Field>

                                        <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Service" /></p>
                                    </div>


                                    <div className="col-12">
                                        <button type="submit" className="btn bg-primary-subtle w-100" style={{ margin: "20px 0" }}>
                                            Send
                                        </button>

                                    </div>
                                </div>

                            </Form>
                        }}
                    </Formik>
                </div>

            </Box>

            <div className="col-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4316905546707!2d77.25713517374892!3d28.55679698751912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce38abca3560d%3A0xbd54bc439e45d16a!2sAbaris%20Softech%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1719229741175!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </div>



            <MAQFooter />

        </>
    )
}

export default MAQMarineLogisticsHome