import light1 from '../assest/importExport/lights/smartstrip_light.jpg'
import light2 from '../assest/importExport/lights/solargardenlight.jpg'
import light3 from '../assest/importExport/lights/ledbulb.jpg'
import light4 from '../assest/importExport/lights/ledlightstring.jpg'
import light5 from '../assest/importExport/lights/GardenLights.jpg'
import light6 from '../assest/importExport/lights/Smart HomeLights.jpg'
import light7 from '../assest/importExport/lights/NeonLights.jpg'
import light8 from '../assest/importExport/lights/Panel Lights.jpg'
import light9 from '../assest/importExport/lights/Solar Street Lights.jpg'
import light10 from '../assest/importExport/lights/Decorative lighting.jpg'
import light11 from '../assest/importExport/lights/LED Modules.jpg'
import light12 from '../assest/importExport/lights/Wall Lamps.jpg'
import light13 from '../assest/importExport/lights/Night Lights.jpg'




const LightingData = [
    {
        id: 1,
        heading:"Smart Strip Lights",
        imgSrc: light1,
    },
    {
        id: 2,
        heading:"Solar Garden Lights",
        imgSrc: light2,
    },
    {
        id: 3,
        heading:"LED Bulbs",
        imgSrc: light3,
    } ,
     {
        id: 4,
        heading:"LED Light Strings",
        imgSrc: light4,
    }
    ,
    {
       id: 5,
       heading:"Garden Lights",
       imgSrc: light5,
   }
   ,
   {
      id: 6,
      heading:"Smart Home Lights",
      imgSrc: light6,
  }
  ,
  {
     id: 7,
     heading:"Neon Lights",
     imgSrc: light7,
 }
 ,
 {
    id: 8,
    heading:"Panel Lights",
    imgSrc: light8,
}
,
 {
    id: 9,
    heading:"Solar Street Lights",
    imgSrc: light9,
}
,
 {
    id: 10,
    heading:"Decorative lighting",
    imgSrc: light10,
}
,
 {
    id: 11,
    heading:"LED Modules",
    imgSrc: light11,
}
,
 {
    id: 12,
    heading:"Wall Lamps",
    imgSrc: light12,
}
,
 {
    id: 13,
    heading:"Night Lights",
    imgSrc: light13,
}
];
export default LightingData