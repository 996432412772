
import polygon from '../../assest/bgbuilding.webp'
import { Box } from "@mui/material"
import '../../styles/ContactUsStyle.css'
import { ErrorMessage, Field, Form, Formik } from "formik"
import * as yup from 'yup'
import { MdEmail, MdOutlinePunchClock, MdLocalPhone } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import MAQFooter from "../../common/MAQ/footer/MAQFooter"
import MAQHeader from "../../common/MAQ/header/MAQHeader"


const MAQContactUs = () => {
    const defalutValue = {
        FullName: '',
        EmailID: '',
        Phone: '',
        YourMessage: '',

    }

    const validationSceema = yup.object().shape({
        // fieldLbale: yup.string().required('SS').min(10).max(10),
        FullName: yup.string().required('Name is Requird!'),
        EmailID: yup.string().required('Email is Requird!'),
        Phone: yup.string().required('Phone is Requird!'),
        YourMessage: yup.string().required('Your Message is Requird!'),

    })
    const handleSubmit = (value) => {
        console.log(value);

    }
    return (
        <>
            <MAQHeader />

            <Box className='maqcontact_topdiv'>
                <img src={polygon} alt="img" />
                <div className="contact_maqhead">
                    <h1>Contact Us</h1>
                </div>
            </Box>

            <div className="maqcontactTouch">
                <div className="contactus_divmaq">
                    <h1 className=" fw-bold">Get In Touch</h1>
                </div>

                <div className="row justify-content-between">

                    <div className="col-lg-6 col-12 card-body row  justify-content-center">
                        <Formik id="frmrecahrge" initialValues={defalutValue} validationSchema={validationSceema} onSubmit={handleSubmit} className="CustomForm fl-form ng-pristine ng-valid row">
                            {({ errors, touched, resetForm }) => {
                                return <Form className="row justify-content-center maqformdiv">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='FullName' placeholder='Name'
                                                className={
                                                    `form-control
                                                    ${errors.FullName && touched.FullName ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="FullName" /></p>
                                        </div>



                                        <div className="col-lg-6 col-12 mt-2">

                                            <Field type='text' name='Phone' placeholder='Mobile Number'
                                                className={
                                                    `form-control
                                                    ${errors.Phone && touched.Phone ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="Phone" /></p>
                                        </div>


                                        <div className=" mt-2">

                                            <Field type='text' name='EmailID' placeholder='Email'
                                                className={
                                                    `form-control
                                                ${errors.EmailID && touched.EmailID ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="EmailID" /></p>
                                        </div>

                                        <div className=" mt-2">

                                            <Field as={'textarea'} rows={6} name='YourMessage' placeholder='Your Message'
                                                className={
                                                    `form-control
                                                     ${errors.YourMessage && touched.YourMessage ? ' is-invalid' : ''}`
                                                }
                                            />
                                            <p style={{ color: 'red', margin: 0, textAlign: 'start', paddingLeft: '5px', fontSize: '12px' }}> <ErrorMessage name="YourMessage" /></p>
                                        </div>


                                        <div className="col-12 d-flex justify-content-center mt-5">
                                            <button type="submit" className="Submitbtnbtn">
                                                Submit Form
                                            </button>

                                        </div>
                                    </div>

                                </Form>
                            }}
                        </Formik>
                    </div>

                    <div className=" col-lg-5 col-12 mainmaq_TouchContact ">
                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <IoLocationSharp className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p>
                                    <strong>Address : </strong> 98B, 2nd Floor, Namberdar Estate, Taimoor Nagar, New Friends Colony, New Delhi-110025
                                </p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdLocalPhone className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Phone : </strong>+91 8851746286</p>
                            </div>

                        </div>

                        <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdEmail className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Email ID : </strong> abaris@softech.com</p>
                            </div>

                        </div>

                        {/* <div className="contactdiv_maqmain">
                            <div className="ngo_icondiv">
                                <p> <MdOutlinePunchClock className=" fs-1 p-2" /></p>
                            </div>
                            <div className="ngocontact">
                                <p> <strong>Office Hours : </strong> 10 AM To 6 PM Mon-Sat</p>
                            </div>

                        </div> */}
                    </div>

                </div>
            </div>


            <MAQFooter />

        </>
    )
}

export default MAQContactUs
