import { Box } from "@mui/material"
import NGOTrustFooter from "../../common/NGOTrust/NGOTrustFooter/NGOTrustFooter"
import NGOTrustHeader from "../../common/NGOTrust/NGOTrustHeader/NGOTrustHeader"
import plantingTrees from '../../assest/planting-trees.webp'
import '../../styles/SustainableVillageAndFarmersStyle.css'
import Waterconservation from '../../assest/Waterconservation.webp'
import Agroforestry from '../../assest/Agroforestry.webp'
import RenewableEnergySources from '../../assest/RenewableEnergySources.webp'
import greeneconomy from '../../assest/greeneconomy.webp'


const SustainableVillageAndFarmers = () => {
    return (
        <>
            <NGOTrustHeader />

            <Box className="topbgImgDiv">
                <img src={plantingTrees} alt="img" />
                <h1 className="h1donate text-center">Sustainable Village and Farmers</h1>
            </Box>
            <div className="DonateNow">
                <div className="welcomeDiv">
                    <h1 style={{ borderBottom: '3px solid #3aa348' }} className="ps-2 mx-4 pb-4">Sustainable Village <span>and Farmers</span> </h1>
                </div>
                <div className="activitiesDiv">
                    <p className=" fs-4">The objective of sustainable village is to create an example amongst all the villages to follow - a village that generates cash crops and creates a green economy by earning from its green backyard orchards and existing farms through innovative farming techniques and agroforestry. A village that’s clean and green, a village that is conserving rain water to increase ground water and creating their own water resources and a village that uses renewable sources of energy (solar lights).</p>
                </div>

            </div>
            <div className="focusgol">
                <h4>To achieve this goal we focus on:</h4>
                <div className=" row">
                    <div className="col-lg-12 col-12 vill_farm">
                        <div>
                            <img src={Waterconservation} alt="img" />

                        </div>
                        <div>
                            <p className=""><strong>Water conservation -</strong> We rejuvenate existing water bodies and create new ones, we setup rainwater harvesting units to tap rain and flowing stream/river water to increase water table and use this water for irrigation and natural safe drinking water.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-12 vill_farm">
                        <div>
                            <img src={Agroforestry} alt="img" />

                        </div>
                        <div>
                            <p className=""><strong>Agroforestry And Cash Crops -</strong> We create sustainable income source by livelihood fruits tree plantation and provide technical and innovative agriculture/horticulture methods training from across the globe to increase per capita income in just a few years.</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-12 vill_farm">
                        <div>
                            <img src={RenewableEnergySources} alt="img" />

                        </div>
                        <div>
                            <p className=""><strong>Renewable Energy Sources -</strong> Lighting streets and roads after sunset by installing Solar powered lamps</p>
                        </div>
                    </div>
                    <div className="col-lg-12 col-12 vill_farm">
                        <div>
                            <img src={greeneconomy} alt="img" />

                        </div>
                        <div>
                            <p className=""><strong>Green Economy -</strong> by training to community members for cash crops agroforestry, local handicraft etc.</p>
                        </div>
                    </div>

                </div>
                <p>Apart from these areas we support and provide guidance in financial literacy, for the village to avail government schemes, create SHG etc.</p>
                <p>MAQ THE WORLD NGO TRUST is working in many villages to create self-sufficient communities outside Mumbai through projects which involve installing solar lamps, training communities in local handicrafts, rejuvenating existing water bodies and installing water pumps, and conducting livelihood tree plantations. In doing so, we create a self-sufficient and sustainable village for farmers and rural communities.</p>
            </div>
            <NGOTrustFooter />

        </>
    )
}

export default SustainableVillageAndFarmers