import people1 from '../assest/BuildersDeveloper/Gurugram.jpg'
import people2 from '../assest/BuildersDeveloper/Faridabad.jpg'
import people3 from '../assest/BuildersDeveloper/karnal.jpg'
import people4 from '../assest/BuildersDeveloper/Panipat.jpg'


const HaryanaCityData = [
    {
        id: 1,
        heading: 'Gurugram',
        imgSrc: people1,
    },
    {
        id: 2,
        heading: 'Faridabad',
        imgSrc: people2,
    },
    {
        id: 3,
        heading: 'Karnal',
        imgSrc: people3,
    },
    {
        id: 4,
        heading: 'Panipat',
        imgSrc: people4,
    },
    

];
export default HaryanaCityData